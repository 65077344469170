import { Component, OnInit } from '@angular/core';

import { CustomerService } from '../../services/customer.service';

import { FilterModel } from '../../../../core/kendo/custom-search/custom-search-all.component';
import { CustomerModel } from '../../models/customer-model';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})

export class CustomerSearchComponent implements OnInit {

  public pageSize: number = 25;
  public skip: number = 0;
  public filterText: string;
  public gridData: any[];
  public gridView: any[];
  public spinner: boolean = false;
  public selectedRows: CustomerModel[] = [];

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void {
    this.refreshGrid();
  }

  private refreshGrid(): void {
    // this.spinner = false;
    // this.customerService.GetCustomersByCountry().subscribe(x => {
    //   this.gridData = x;
    //   this.gridView = x;
    //   this.spinner = false;
    // });
  }
  public onPageChange(state: any): void {
    this.skip = state.skip;
    this.pageSize = state.take;
  }
  public onSearch(data: FilterModel) {
    this.gridView = data.gridData;
    this.skip = data.skip;
  }
}
