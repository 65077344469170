import { NotificationService } from './../../../../core/services/notification-service';
import { getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { TranslateService } from '@ngx-translate/core';
import { FollowUpService } from './../../services/follow-up.service';

import { FollowUpModel } from './../../models/follow-up.model';
import { ABPApprovalModel } from '../../models/abp-approval-model';
import { ABPApprovalService } from '../../services/abp-approval.service';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {

  public spinner = false;
  public ctsId: number;
  public year: number;

  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();

  public follow_up: FollowUpModel[];
  public follow_upColumnsS = ["brandName", "segmentName", "total", "excelValue", "archivePercentage"];
  public follow_upColumnsH = ["brandName", "segmentName", "total", "excelValue", "archivePercentage"];
  // public follow_upColumnsC = ["total", "excelValue", "archivePercentage"];
  public follow_upColumnsV = ["brandName", "segmentName", "total", "clearcoatTarget", "primerTarget", "excelValue", "archivePercentage"];

  public brands: string[] = [];
  public segments: string[] = [];
  public approvalModel: ABPApprovalModel;
  public visible: boolean;

  constructor(private followUpService: FollowUpService, private translateService: TranslateService, private approvalService: ABPApprovalService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.visible = this.isVisible();
    this.LoadFollowUp();
  }

  ngOnInit(): void {
  }

  public LoadFollowUp(): void {
    this.spinner = true;
    let request = new FollowUpModel();
    request.year = this.year;
    request.ctsId = this.ctsId;
    this.followUpService.GetCustomerFollowUp(request).subscribe(response => {
      this.follow_up = response.result;
      if (this.follow_up != null) {
        this.Brands();
        this.Segments();
      }
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Brands() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.brandName === y.brandName) === i
    );
    filter.sort(function (a, b) {
      if (a.brandName < b.brandName) { return -1; }
      if (a.brandName > b.brandName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.brands.push(item.brandName)
    });
  }

  public Segments() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.segmentName === y.segmentName) === i
    );
    filter.sort(function (a, b) {
      if (a.segmentName < b.segmentName) { return -1; }
      if (a.segmentName > b.segmentName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.segments.push(item.segmentName)
    });
  }

  public GetBrandData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.brandName == brand);
      if (array != null) {
        let total: number = 0;
        let clearcoatTarget: number = 0;
        let primerTarget: number = 0;
        let excelValue: number = 0;
        let segmentName: string;
        array.forEach(item => {
          if (item.type == 'Sales' && item.segmentName.includes('CAR')) {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB') {
              total = total + item.total;
              excelValue = excelValue + item.excelValue;
              segmentName = 'CAR + CAR GB';
            } else {
              response.push(item);
            }
          }
          else if (item.type == 'Volume' && (item.segmentName.includes('CAR') ||item.segmentName == 'HVLS')
            || (item.type == 'Volume' && brand == "PPG" && item.segmentName == 'HVLS'))
            //||(item.type == 'Volume' && item.segmentName == 'HVLS')) 
            {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB' || item.segmentName == 'HVLS') {
              clearcoatTarget = clearcoatTarget + item.clearcoatTarget;
              primerTarget = primerTarget + item.primerTarget;
              total = clearcoatTarget + primerTarget;
              excelValue = excelValue + item.excelValue
              segmentName = 'CAR + CAR GB + HVLS';
            } else {
              response.push(item);
            }
          }
        });
        if (total > 0 || excelValue > 0) {
          let data = new FollowUpModel();
          data.type = type;
          data.year = this.year;
          data.ctsId = this.ctsId;
          data.brandName = brand;
          data.segmentName = segmentName;
          data.total = total;
          data.clearcoatTarget = clearcoatTarget;
          data.primerTarget = primerTarget;
          data.excelValue = excelValue;
          data.archivePercentage = excelValue / total;
          response.push(data);
        }
      }
    }
    return response;
  }

  public GetSegmentHVLSData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (brand == 'AutoLux' || brand == 'QuickLine' || brand == 'Valuepro') {
      if (this.follow_up != null) {
        let array = this.follow_up.filter(x => x.type == 'Sales' && x.segmentName.includes('HVLS') && x.brandName == brand);
        if (array != null) {
          let totalHVLS: number = 0;
          let excelValueHVLS: number = 0;
          let totalHVLSCOLOR: number = 0;
          let excelValueHVLSCOLOR: number = 0;
          array.forEach(item => {
            if (item.type == 'Sales') {
              if (item.segmentName == 'HVLS') {
                totalHVLS = totalHVLS + item.total;
                excelValueHVLS = excelValueHVLS + item.excelValue;
              }
              if (item.segmentName.toUpperCase() == 'HVLS COLOR') {
                totalHVLSCOLOR = totalHVLSCOLOR + item.total;
                excelValueHVLSCOLOR = excelValueHVLSCOLOR + item.excelValue;
              }
            }
          });
          if (totalHVLS > 0 || excelValueHVLS > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS';
            data.total = totalHVLS;
            data.excelValue = excelValueHVLS;
            data.archivePercentage = excelValueHVLS / totalHVLS;
            response.push(data);
          }
          if (totalHVLSCOLOR > 0 || excelValueHVLSCOLOR > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS COLOR';
            data.total = totalHVLSCOLOR;
            data.excelValue = excelValueHVLSCOLOR;
            data.archivePercentage = excelValueHVLSCOLOR / totalHVLSCOLOR;
            response.push(data);
          }
        }
      }
    }
    return response;
  }

  public GetSegmentCTData(type: string, brand: string) {
    if (brand == 'Delfleet One' || brand == 'Turbo Vision') {
      console.log(this.follow_up.filter(x => x.type == 'Sales' && x.segmentName == 'CT' && x.brandName == brand));
      return this.follow_up.filter(x => x.type == 'Sales' && x.segmentName == 'CT' && x.brandName == brand);
    } else {
      return [];
    }
  }

  public GetSegmentData(type: string, segment: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.segmentName == segment && !x.segmentName.includes('HVLS'));
      if (array != null) {
        array.forEach(item => {
          if (item.segmentName == 'LIC') {
            response.push(item);
          }
        });
      }
    }
    return response;
  }

  public getTotal(data: FollowUpModel[]) {
    return data.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

  public getTotalExcelValue(data: FollowUpModel[]) {
    return data.map(t => t.excelValue).reduce((acc, value) => acc + value, 0);
  }

  public getTotalArchivePercentage(data: FollowUpModel[]) {
    return this.getTotalExcelValue(data) / this.getTotal(data);
  }

  public GetCurrencyCode() {
    return getLocaleCurrencyCode(this.languageCultureCode);
  }

  public GetCurrencySymbol() {
    return getCurrencySymbol(this.GetCurrencyCode(), "narrow", this.languageCultureCode);
  }

  isVisible(): boolean {

    if (this.ctsId !== 0) {

      let model = new ABPApprovalModel();
      model.ctsId = this.ctsId;
      model.year = this.year;

      this.approvalService.GetCurrentStatus(model).subscribe(x => {
        this.approvalModel = x.result;
      });
      let userTypeId = sessionStorage.getItem('UserTypeId');
      console.log(this.approvalModel);
      console.log(userTypeId);
      if (this.approvalModel == null && (userTypeId === '1' || userTypeId === '7')) {
        return false;
      }

      if (this.approvalModel != null && (this.approvalModel.status !== 'Signed' && (userTypeId === '1' || userTypeId === '7'))) {
        return false;
      }
    }

    return true;
  }
}