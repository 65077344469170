import { Component, OnInit } from '@angular/core';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { ABPApprovalService } from './../../services/abp-approval.service';
import { CalculatorService } from './../../services/calculator.service';

import { ABPApprovalModel } from './../../models/abp-approval-model';
import { CalculatorModel } from './../../models/calculator.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-annual-business-plan-data-entry',
  templateUrl: './annual-business-plan-data-entry.component.html',
  styleUrls: ['./annual-business-plan-data-entry.component.scss']
})
export class AnnualBusinessPlanDataEntryComponent implements OnInit {

  public spinner = false;
  public IsPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();
  public IsPPGAdmin = GlobalVariablesService.IsPPGAdmin();
  public IsPPGGuest = GlobalVariablesService.IsPPGGuest();
  public IsDistAdmin = GlobalVariablesService.IsDistributorAdmin();
  public IsDistUserAdmin = GlobalVariablesService.IsDistributorUser();
  public IsTM = GlobalVariablesService.IsTM();
  public IsRM = GlobalVariablesService.IsRM();
  public IsZD = GlobalVariablesService.IsZD();
  public IsReadOnly: Boolean = false;

  public abpApprovals: ABPApprovalModel[];
  public eoyCalculatorSettings: CalculatorModel = new CalculatorModel();

  public abpApprovalsColumns = ["status", "firstName", "lastName", "userTypeName", "date"];

  public year: number = 0;
  public ctsId: number = 0;

  public currentStatus: string = "Active";
  public currentStep: number = 1;
  public approvalButtonText: string = "Submit";

  constructor(private abpApprovalService: ABPApprovalService,
    private calculatorService: CalculatorService,
    private translateService: TranslateService) {
    this.year = GlobalVariablesService.getYear();
    this.ctsId = GlobalVariablesService.getCtsId();
    this.eoyCalculatorSettings.year = this.year;
    this.eoyCalculatorSettings.ctsId = this.ctsId;
    this.LoadData();
  }

  ngOnInit(): void {
  }

  public LoadData(): void {

    let abpApproval = new ABPApprovalModel();
    abpApproval.year = this.year;
    abpApproval.ctsId = this.ctsId;
    this.spinner = true;
    this.abpApprovalService.GetAllApproval(abpApproval).subscribe(response => {
      this.abpApprovals = response.result;
      this.abpApprovalService.GetCurrentStatus(abpApproval).subscribe(response => {
        if (response.success) {
          if (response.result == null) {
            this.currentStatus = "Active";
            this.currentStep = 1;
          } else {
            this.currentStatus = response.result.status;
            this.currentStep = response.result.stepId;
          }
          this.IsReadOnly = this.IsPPGGuest || !(this.currentStatus == 'Active' || this.currentStatus == 'Rejected' || this.currentStep == 8);
        } else {
          this.currentStatus = "Active";
          this.currentStep = 1;
        }
        this.calculatorService.GetEOYCalculatorSettings(this.eoyCalculatorSettings).subscribe(response => {
          if (response.result != null) {
            this.eoyCalculatorSettings = response.result;
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public CreateUpdate(status?: string) {
    if (this.year <= 0 || this.ctsId <= 0) {
      if (this.year <= 0) {
        NotificationService.warning("", this.translateService.instant("Please pick a year"));
        return;
      };
      if (this.ctsId <= 0) {
        NotificationService.warning("", this.translateService.instant("Please select a CTSID"));
        return;
      }
    }
    this.spinner = true;
    if (status == null) {
      if (this.currentStep == 1 || this.currentStatus == 'Rejected' || (this.currentStep == 0 || this.currentStep == null)) { 
        this.currentStep = 2;
        status = 'Submitted';
      }
      else if (this.currentStep == 2) {
        status = 'Approved_By_PPG_Admin';
        this.currentStep = 3;
      } else if (this.currentStep == 3) {
        status = 'Signed_By_TM_RM';
        this.currentStep = 4;
      } else if (this.currentStep == 4) {
        status = 'ZD_Approval_Not_Required';
        this.currentStep = 7;
      } else if(this.currentStep == 5) {
        status = 'Approved_By_ZD';
        this.currentStep = 6;
      } else if (this.currentStatus == 'ZD_Approval_Not_Required' || this.currentStatus == 'Approved_By_ZD' || this.currentStep == 6) {
         status = 'Signed_By_PPG_Admin';
         this.currentStep = 7
      } else if(this.currentStep === 8) {
        this.currentStep = 2;
        status = "Submitted";
      }

      if ((this.currentStep == 7 
          || this.currentStatus == 'Signed_By_TM_RM') 
          && this.IsPlatinumProgress) {
            status = 'Signed_By_Platinum_Progress';
          }
      if ((this.currentStatus == 'Signed_By_PPG_Admin' 
          || this.currentStatus == 'Signed_By_Platinum_Progress') 
          && (this.IsTM || this.IsRM)) {
            status = 'Signed_By_TM_RM';
          }

    }
    else if(status === 'Needs_ZD_Approval') {
      this.currentStep = 5;
      status = 'ZD_Approval_Required';
    }
    else if (status ==='Rejected'){
      this.currentStep = 8;
      status = 'Rejected';
    }

    let abpApproval = new ABPApprovalModel();
    abpApproval.year = this.year;
    abpApproval.ctsId = this.ctsId;
    abpApproval.userId = GlobalVariablesService.getUserId();
    abpApproval.status = status;
    abpApproval.stepId = this.currentStep;
    this.abpApprovalService.CreateUpdate(abpApproval).subscribe(response => {
      if (!response.success) {
        NotificationService.error(null, response.message);
      } else {
        NotificationService.success(null, this.translateService.instant("Saved"));
        this.LoadData();
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public getApprovalButtonText() {
    let response: string = '';
    if (this.currentStatus == 'Active' || this.currentStep === 8) response = 'Submit';
    if (this.currentStep ==2) response = 'Approve';
    if (this.currentStep == 3) response = 'Approve';
    if (this.currentStep == 4) response = 'Approve';
    if (this.currentStep == 5) response = 'Approve';
    if (this.currentStatus == 'ZD_Approval_Not_Required' || this.currentStep == 6) response = 'Sign';
    if (this.currentStep == 7 || this.currentStatus == 'Signed_By_TM_RM' ||
      this.currentStatus == 'Signed_By_Platinum_Progress') response = 'Sign';
    return response;
  }

  public showApproveButton() {
    let response: boolean = false;
    if ((this.currentStatus == 'Active' || this.currentStep == 8) && (this.IsTM || this.IsRM || this.IsPPGAdmin)) response = true;
    if (this.currentStep == 2 && this.IsPPGAdmin) response = true;
    if (this.currentStep == 3 && this.IsRM) response = true;
    if (this.currentStep == 4 && this.IsPPGAdmin) response = true;
    if (this.currentStep == 5 && this.IsZD) response = true;
    if ((this.currentStatus == 'ZD_Approval_Not_Required' || this.currentStep == 6) && this.IsPPGAdmin) response = true;
    return response || (this.IsPPGAdmin && [2,3,4,5].indexOf(this.currentStep) != -1);
  }

  public showZDApprovalButton() {
    return this.currentStep == 4 && this.IsPPGAdmin;
  }

  public showRejectButton() {
    let response: boolean = false;
    if (this.currentStep == 2 && this.IsPPGAdmin) response = true;
    if (this.currentStep == 3 && this.IsRM) response = true;
    if (this.currentStep == 5 && this.IsZD) response = true;
    if ((this.currentStatus == 'ZD_Approval_Not_Required' || this.currentStatus == 'Approved_By_ZD') && this.IsPPGAdmin) response = true;
    if ((this.currentStep == 7 || this.currentStatus == 'Signed_By_TM_RM' ||
      this.currentStatus == 'Signed_By_Platinum_Progress') &&
      (this.IsPlatinumProgress || this.IsRM || this.IsTM || this.IsPPGAdmin)) response = true;
    if (this.currentStatus == 'Signed' && this.IsPPGAdmin) response = true;
    return response || (this.IsPPGAdmin && [2,3,5].indexOf(this.currentStep) != -1);
  }

  public eoyCalculatorCheckBox() {
    this.spinner = true;
    this.calculatorService.CreateUpdateEOYCalculatorSettings(this.eoyCalculatorSettings).subscribe(response => {
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }
}