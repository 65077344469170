import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ContractsService } from '../../../services/contracts.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contracts-modal',
  templateUrl: './contracts-modal.component.html',
  styleUrls: ['./contracts-modal.component.scss']
})
export class ContractsModalComponent implements OnInit {
  contractDataId:number;
  customerContractDataID: number;
  fileId: number;
  ctsId: number;
  spinner:boolean =false;
  fileUploadForm: FormGroup;
  msg: string = "";

  constructor(public dialogRef: MatDialogRef<ContractsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translateService:TranslateService, private router:Router,
    private contractsService: ContractsService, private formBuilder: FormBuilder, private http: HttpClient) {
    this.contractDataId = data.contractId;
    this.customerContractDataID=data.customerContractDataID;
    this.fileId = data.fileId;
    this.ctsId = data.ctsId;
  }

  ngOnInit(): void {
    this.fileUploadForm = this.formBuilder.group({
      myfile: ['']
    });
  }

  @Input()
  requiredFileType: string;

  fileName = '';
  uploadSub: Subscription;

  onFileSelected(event) {
    const file: File = event.target.files[0];
    let fileFormat = ['application/pdf']
    if (!_.includes(fileFormat, file.type)) {
      alert(this.translateService.instant('Upload only PDF Docs'));
    } else {
      this.fileName = file.name;
      this.fileUploadForm.get('myfile').setValue(file);
    }
  }

  onFormSubmit() {
    if (!this.fileUploadForm.get('myfile').value) {
     this.msg= this.translateService.instant('Please fill valid details!');
      return false;
    }
    this.spinner = true;
      this.contractsService.uploadFile(this.fileUploadForm.get('myfile').value, this.contractDataId,this.customerContractDataID, this.fileId, this.ctsId).subscribe(response => {
        console.log(response);
        if (response.success) {
          this.msg = this.translateService.instant("File Uploaded")+" !!";      
        }
        else {
          this.msg = this.translateService.instant("File Not Uploaded");
        }
        this.spinner = false;
      });   

  }

  close() {
    this.dialogRef.close();
    this.router.navigate([this.router.url]) 
  }

}