import { Component, OnInit } from '@angular/core';

import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { ABPApprovalService } from '../../services/abp-approval.service';
import { CalculatorService } from './../../services/calculator.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './../../../../core/services/notification-service';

import { ABPApprovalModel } from '../../models/abp-approval-model';
import { CalculatorModel } from './../../models/calculator.model';
import { of } from 'rxjs';

@Component({
  selector: 'app-calculator-menu',
  templateUrl: './calculator-menu.component.html',
  styleUrls: ['./calculator-menu.component.scss']
})
export class CalculatorMenuComponent implements OnInit {
  public spinner = false;
  public approvalModel: ABPApprovalModel;
  public visible: boolean;
  public ctsId: number;
  public year: number;
  public eoyCalculatorSettings: CalculatorModel = new CalculatorModel();
  public IsPPGAdmin = GlobalVariablesService.IsPPGAdmin();

  constructor(private approvalService: ABPApprovalService,
    private calculatorService: CalculatorService,
    private translateService: TranslateService) {

    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.eoyCalculatorSettings.year = this.year;
    this.eoyCalculatorSettings.ctsId = this.ctsId;
    this.isVisible().then(x =>{
      this.visible = x;
    });

    this.LoadData();
  }

  ngOnInit(): void { }

  public LoadData() {
    this.spinner = true;
    this.calculatorService.GetEOYCalculatorSettings(this.eoyCalculatorSettings).subscribe(response => {
      if (response.result != null) {
        this.eoyCalculatorSettings = response.result;
      }
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  async isVisible(): Promise<boolean> {

    if (this.ctsId !== 0) {

      let model = new ABPApprovalModel();
      model.ctsId = this.ctsId;
      model.year = this.year;

      this.approvalModel = await (await this.approvalService.GetCurrentStatusPromise(model)).toPromise();
      let userTypeId = sessionStorage.getItem('UserTypeId');
      console.log(this.approvalModel);
      if (this.approvalModel == null && (userTypeId === '1' || userTypeId === '7')) {
        return false;
      }
      
      //console.log(this.approvalModel.stepId);
      console.log(userTypeId)
      if (this.approvalModel != null && (this.approvalModel.stepId !== 7 && (userTypeId === '1' || userTypeId === '7' ))) {
        return false;
      }
    }

    return true;
  }

  async getStatus(model: ABPApprovalModel): Promise<ABPApprovalModel> {
    
    console.log(this.approvalModel);
    return this.approvalModel;
  }
}

