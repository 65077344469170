import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RebateService } from '../../services/rebate.service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { CustomerRebateModel } from '../../models/customer-rebate.model';
import { RebateModel } from '../../models/rebate.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-customer-rebate-display',
    templateUrl: './customer-rebate-display.component.html',
    styleUrls: ['./customer-rebate-display.component.scss']
})

export class CustomerRebateDisplayComponent implements OnInit {
    @Input() rebateTypeId: number;
    public spinner = false;
    public title: string;
    public year: number;
    public ctsId: number;
    public customerBaseValue: number = 0;
    public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();

    public rebate = new RebateModel();
    public customerRebate = new CustomerRebateModel();

    public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();

    constructor(private route: ActivatedRoute, private rebateService: RebateService, private translateService: TranslateService) {

        this.ctsId = GlobalVariablesService.getCtsId();
        this.year = GlobalVariablesService.getYear();

        this.customerRebate.ctsId = this.ctsId;
        this.customerRebate.year = this.year;
        if (this.year > 0 && this.ctsId > 0) {
            this.rebateService.GetCustomerBaseValue(this.customerRebate).subscribe(response => {
                if (response.success) {
                    this.customerBaseValue = response.result;
                }
                this.loadData();
            });
        }
    }

    ngOnInit() {    
    }

    private loadData() {
        this.spinner = true;
        this.rebate.rebateTypeId = this.rebateTypeId;

        this.rebate.year = this.year;
        this.rebateService.GetRebateByTypeId(this.rebate).subscribe(responseRebate => {
            if (responseRebate.success) {
                this.rebate = responseRebate.result;
                this.customerRebate.rebateTypeId = this.rebateTypeId;
                this.customerRebate.year = this.year;
                this.customerRebate.ctsId = this.ctsId;
                this.rebateService.GetCustomerRebate(this.customerRebate).subscribe(responseCustomerRebate => {
                    if (responseCustomerRebate.success) {
                        if (responseCustomerRebate.result != null) {
                            this.customerRebate = responseCustomerRebate.result;
                        }
                        if (this.rebate.rebateTypeId == 3) {
                            this.customerRebate.baseValue = (this.customerBaseValue * this.rebate.minConsecutionAbp) / 100;
                        }
                        else {
                            this.customerRebate.baseValue = this.customerBaseValue;
                        }
                    }
                    this.spinner = false;
                });
            }
        });
    }

}