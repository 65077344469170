import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { CustomerService } from './../../../customer/services/customer.service';
import { CustomerModel } from './../../../customer/models/customer-model';
import { MatTableDataSource } from '@angular/material/table';
import { UsersListModel } from '../../models/users-list-model';
import { UserService } from '../../services/user.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public ctsId: number;
  public spinner: boolean = false;
  public title: string = "Users";
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public dataSource = new MatTableDataSource<UsersListModel>();
  public enableEdit: boolean = false;
  public isDistAdmin: boolean = false;
  public userType: string;
  public displayedColumns = ["firstName", "lastName", "emailAddress", "admin", "access"];
  constructor(private customerService: CustomerService, private dialog: MatDialog, private translateService: TranslateService, private userService: UserService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.userType = GlobalVariablesService.getUserTypeName();

    if (this.userType == "PPG_PLATINUM_PROGRESS_ADMIN" || this.userType == "DISTRIBUTOR_ADMIN") {
      this.enableEdit = true;
    }

    if (this.userType == "DISTRIBUTOR_ADMIN") {
      this.isDistAdmin = true;
    }

    if (this.ctsId != 0) {
      this.GetCustomerById();
    }
    else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName = (this.customer.companyName == null ? '' : this.customer.companyName);
            this.GetUsers();
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }

  public GetUsers() {
    this.spinner = true;
    this.userService.GetUsersListByCTSId(this.ctsId).subscribe(response => {
      console.log(response);
      if (response != undefined) {
        this.dataSource.data = response.result;
      } else {
        NotificationService.error(null, this.translateService.instant("Internal Error"));
      }
      this.spinner = false;
    });

  }
  public SearchGrid(event: Event) {
    const searchkey = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchkey.trim().toLowerCase();
  }

  public SaveDistributorUser(user: UsersListModel, $event: MatCheckbox) {
    user.ctsId = this.ctsId;
    user.platinumAccess = $event.checked;
    if(user.platinumAccess)
    {
      user.isDistributorAdmin=false;
    }
    this.spinner = true;
    this.userService.CreateOrEditDistributorUsers(user).subscribe(response => {
      console.log(response);
      if (response.success) {
        console.log("User Saved");
      } else {
        NotificationService.error(null, this.translateService.instant("Internal Error"));
      }
      this.GetUsers();
    });
  }

  public SaveAdminUser(user: UsersListModel, $event: MatCheckbox) {
    user.ctsId = this.ctsId;
    user.isDistributorAdmin = $event.checked;
    if(user.isDistributorAdmin)
    {
      user.platinumAccess=false;
    } 
    this.userService.CreateOrEditDistributorUsers(user).subscribe(response => {
      console.log(response);
      if (response.success === true) {
        console.log("User Saved");
      } else {
        NotificationService.error(null, this.translateService.instant("Internal Error"));
      }
      this.GetUsers();
    });
  }
}
