import { Component, OnInit, ViewChild } from '@angular/core';
import { DevPlanService } from './../../services/dev-plan.service';
import { DevPlanModel } from '../../models/dev-plan-request.model';
import { DevPlanHeaderModel } from '../../models/dev-plan-header.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from './../../../../core/services/notification-service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dev-plan',
  templateUrl: './dev-plan.component.html',
  styleUrls: ['./dev-plan.component.scss']
})

export class DevPlanComponent implements OnInit {
  public title: string = "Development Plan Maintenance";
  public displayedColumns = ["description", "actions"];
  public addCols = ["description", "actions"];
  public devplans: DevPlanModel[];
  public dataSource = new MatTableDataSource<DevPlanModel>();
  public devPlan = new DevPlanModel();
  public devPlanHeader = new DevPlanHeaderModel();
  public spinner = false;
  public addDevPlan = false;
  public addRow: DevPlanModel[];
  public years: number[] = [];
  public currentYear: number = 0;
  public year: number = 0;


  selection = new SelectionModel<DevPlanModel>(true, []);
  public description = new FormControl('', [Validators.required]);
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private devPlanService: DevPlanService, public translateService: TranslateService) {
    GlobalVariablesService.setCtsIdVisibility(false);
  }

  ngOnInit(): void {
    this.years = GlobalVariablesService.getYears();
    this.year = GlobalVariablesService.getYear();
    this.addRow = [new DevPlanModel()];
    this.currentYear = GlobalVariablesService.getYear(); // on initial load by default load current year 
    this.GetDevPlans();
  }

  public GetDevPlans(): void {

    let planHeader = new DevPlanHeaderModel();

    this.spinner = true;
    this.addDevPlan = false;

    if (this.year == 0) {
      this.devPlan.year = this.currentYear;
      planHeader.year = this.currentYear;
    }
    else {
      this.devPlan.year = this.year;
      planHeader.year = this.year;
    }

    this.devPlanService.GetPlanHeader(planHeader).subscribe(responseHeader => {
      if (responseHeader.result != null) {
        this.devPlanHeader = responseHeader.result;
      } else {
        this.devPlanHeader.year = planHeader.year;
      }
      this.devPlanService.GetPlansByYear(this.devPlan).subscribe(response => {
        this.devplans = response.result;
        this.dataSource.data = this.devplans;
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }


  public getErrorMessage(element: string) {
    return this.translateService.instant('Missing') + ' ' + this.translateService.instant(element);
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public AddDevPlan() {
    this.addRow = [new DevPlanModel()];
    this.addDevPlan = !this.addDevPlan;
  }

  public CreateUpdate(plan: DevPlanModel) {

    if (!this.IsLocked()) {
      this.spinner = true;
      plan.year = this.year;
      this.devPlanService.CreateUpdate(plan).subscribe(response => {
        if (!response) {
          NotificationService.error(null, response.message);
        }
        else {
          NotificationService.success(null, this.translateService.instant("Saved"));
          this.GetDevPlans();
        }
        this.AddDevPlan();
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }
  public CreateUpdateHeader() {
    this.spinner = true;
    this.devPlanService.CreateUpdateHeader(this.devPlanHeader).subscribe(response => {
      if (response.success) {
        this.devPlanService.GetPlanHeader(this.devPlanHeader).subscribe(responseHeader => {
          this.devPlanHeader = responseHeader.result;
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        NotificationService.error(null, response.message);
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Delete(plan: DevPlanModel) {
    if (!this.IsLocked()) {
      this.spinner = true;
      plan.year = this.year;
      this.devPlanService.Delete(plan).subscribe(response => {
        if (response) {
          NotificationService.success(null, this.translateService.instant("Deleted"));
          this.GetDevPlans();
        }
        else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public Lock() {

    if (this.devPlanHeader != undefined || this.devplans.length > 0) {
      Swal.fire({
        title: this.translateService.instant("Lock the plan list?"),
        text: this.translateService.instant("You won't be able to revert this!"),
        icon: 'warning',

        confirmButtonColor: '#01304A',
        cancelButtonColor: 'red',
        confirmButtonText: this.translateService.instant('Confirm'),
        cancelButtonText: this.translateService.instant('Cancel'),
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          if (!this.IsLocked()) {
            this.devPlanHeader.lock = true;
            this.CreateUpdateHeader();
          }
        }
      });
    } else {
      NotificationService.error(null, this.translateService.instant("Can't lock due to missing information"));
      this.spinner = false;
      return;
    }
  }
  public IsLocked() {
    return this.devPlanHeader.lock;
  }
  public ShowLockButton() {
    if (GlobalVariablesService.IsPPGAdmin()) {
      return true;
    } else {
      return false;
    }
  }

}
