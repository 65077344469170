import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalPipe, formatNumber } from '@angular/common';
import { GlobalVariablesService } from '../services/global-variables.service';

@Directive({
  selector: '[matInputSeparatorsDirective]'
})
export class MatInputSeparatorsDirectiveDirective implements OnInit {
  private _value: string| null;
  constructor(private elementRef: ElementRef<HTMLInputElement>, private renderer: Renderer2) {
    //this.renderer.setValue(this.elementRef.nativeElement, this.numberWithCommas(this.elementRef.nativeElement.value));
   }
  ngOnInit(): void {
    //console.log(this._elementRef.value);
  }
  public formatValue(value) {
    if (value != null) {
      console.log(value);
      console.log(this.numberWithCommas(value));
      console.log(this.elementRef.nativeElement);
      //this.renderer.setValue(this.elementRef.nativeElement, this.numberWithCommas(value));
      this.elementRef.nativeElement.value = this.numberWithCommas(value);
      this.renderer.setValue(this.elementRef.nativeElement, this.numberWithCommas(value));
      console.log(this.elementRef.nativeElement.value);

    } else {
      this.elementRef.nativeElement.value = '';
    }
  }
  private unFormatValue(value) {
    value = value.replace(/[^\d-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('blur', ["$event.target.value"])
  _onBlur(value) {
    this.formatValue(value);
  }
  @HostListener('focus', ["$event.target.value"])
  onFocus(value) {
    console.log(value);
    this.unFormatValue(value);
  }
  
  public removeSeparators(value): string {
    return value.replace(/[^\d-]/g, '');
  }

  public numberWithCommas(value: string): string {
    return formatNumber(parseInt(value ), GlobalVariablesService.getLanguageCultureCode(),'1.0-0');
  }
}
