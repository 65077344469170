<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <h3 class="center">{{title|translate}}</h3>
  <br>
  <h4><u>{{this.distributorName}}</u></h4>
  <br>
  <div class="row">
    <mat-form-field style="margin-left:1.5%;" >
      <mat-label>{{"Search"|translate}}..</mat-label>
      <input matInput (keyup)="SearchGrid($event)">
    </mat-form-field>

    <div style="margin-left:65%;">
      <!-- <button mat-button (click)="EditUser()" [disabled]="!enableEdit"> <span class="material-icons" matTooltip="{{'Edit'|translate}}" >mode_edit</span></button> -->
      <!-- <button mat-button matTooltip="{{'Save'|translate}}"  (click)="SaveUser()" [disabled]="!enableEdit"> <span class="material-icons">save</span></button>
    </div> -->
  </div>

<table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef style="text-align: left!important;" class="c-table__header-cell">{{"First Name"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.firstName" class="c-table__data-cell" ngDefaultControl>
      {{element.firstName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef style="text-align: left!important;" class="c-table__header-cell"> {{"Last Name"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.lastName" class="c-table__data-cell" ngDefaultControl>
      {{element.lastName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="emailAddress">
    <th mat-header-cell *matHeaderCellDef style="text-align: left!important;" class="c-table__header-cell"> {{"Email"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.emailAddress" class="c-table__data-cell" ngDefaultControl>
      {{element.emailAddress}}
    </td>
  </ng-container>

  <ng-container matColumnDef="admin">
    <th mat-header-cell *matHeaderCellDef class="c-table__header-cell"> {{"Distributor Admin"|translate}}</th>
    <td mat-cell style="text-align: center!important;" *matCellDef="let element" [(ngModel)]="element.isDistributorAdmin" class="c-table__data-cell" ngDefaultControl>
      <mat-checkbox #checkbox [disabled]="(!enableEdit || isDistAdmin||element.platinumAccess || element.isDuplicateEmail)"[checked]="element.isDistributorAdmin"  (change)="SaveAdminUser(element,$event)"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="access">
    <th mat-header-cell *matHeaderCellDef class="c-table__header-cell"> {{"Platinum Access"|translate}}</th>
    <td mat-cell style="text-align: center!important;" *matCellDef="let element" [(ngModel)]="element.platinumAccess" class="c-table__data-cell" ngDefaultControl>
      <mat-checkbox #checkbox [disabled]="(!enableEdit||element.isDistributorAdmin || element.isDuplicateEmail)" [checked]="element.platinumAccess"  (change)="SaveDistributorUser(element,$event)"></mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngStyle]="row.isDuplicateEmail && {'background-color': 'lightgrey'}"></tr>
</table>
</div>
<mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons>
</mat-paginator>
