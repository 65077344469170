<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <!-- <h3 class="center">{{title}}</h3>
  <br> -->
  <!-- <h4><u>{{this.distributorName}}</u></h4>
  <br> -->
  <mat-form-field>
    <mat-label>Search..</mat-label>
    <input matInput (keyup)="SearchGrid($event)">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">

    <ng-container matColumnDef="shopId">
      <th mat-header-cell *matHeaderCellDef>Shop/Dealership Location</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.shopCtsId" ngDefaultControl>
        {{element.shopCtsId}} - {{element.shopName}}
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Plan Description</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.planDescription" ngDefaultControl>
        {{element.planDescription}}
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef>Comments</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.comments" ngDefaultControl>{{element.comments}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>