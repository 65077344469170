import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';

import { NotificationService } from './../../../../core/services/notification-service';
import { UserService } from '../../services/user.service';

import { UserModel } from '../../models/user-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})

export class UserSearchComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public spinner: boolean = false;

  public users: UserModel[];
  public usersFilter = new MatTableDataSource<UserModel>();

  public usersColumns = ["firstName", "lastName", "emailAddress", "userTypeName", "active", "createDate", "actions"];

  constructor(private userService: UserService, private router: Router, private translateService:TranslateService) { }

  ngOnInit() {
    this.LoadUsers();
  }

  ngAfterViewInit() {
    this.usersFilter.paginator = this.paginator;
  }

  public LoadUsers(): void {
    this.spinner = true;
    this.userService.GetUsers().subscribe(response => {
      this.users = response.result;
      this.usersFilter.data = this.users;
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usersFilter.filter = filterValue.trim().toLowerCase();
  }

  public ResetPassword(user: UserModel): void {
    Swal.fire({
      title: this.translateService.instant('Reset Password?'),
      text: this.translateService.instant("You won't be able to revert this!"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#01304A',
      cancelButtonColor: 'red',
      confirmButtonText: this.translateService.instant('Confirm')
    }).then((result) => {
      this.spinner = true;
      if (result.value) {
        this.userService.ResetPassword(user).subscribe(response => {
          if (response.success) {
            NotificationService.success(null, response.result);
          } else {
            NotificationService.error(null, response.message);
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        this.spinner = false;
      }
    });
  }

  public EditUser(user: UserModel) {
    this.router.navigate(['/user-add-edit/' + user.userId]);
  }
}