import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RebateModel } from '../models/rebate.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { ServiceCallResult } from './../../../core/models/service-call-result';
import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { CustomerRebateModel } from '../models/customer-rebate.model';
import { EoyRebateExceptionModel } from './../models/eoyRebateException-model';

@Injectable({
  providedIn: 'root'
})
export class RebateService {

  constructor(private http: HttpClient) { }

  public GetRebateByTypeId(request: RebateModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/GetRebateByTypeId", request).pipe(map(response => response));
  }

  public GetCustomerRebate(request: CustomerRebateModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/GetCustomerRebate", request).pipe(map(response => response));
  }

  public GetAllCustomerRebate(request: CustomerRebateModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/GetAllCustomerRebate", request).pipe(map(response => response));
  }

  public GetAllEOFRebateException(request: EoyRebateExceptionModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/GetAllEOFRebateException", request).pipe(map(response => response));
  }

  public GetCustomerBaseValue(request: CustomerRebateModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/GetCustomerBaseValue", request).pipe(map(response => response));
  }

  public CreateUpdate(request: RebateModel[]): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/CreateUpdate", request).pipe(map(response => response.result));
  }

  public CreateUpdateCustomerRebate(request: CustomerRebateModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/CreateUpdateCustomerRebate", request).pipe(map(response => response.result));
  }

  public CreateUpdateEOYRebateException(request: EoyRebateExceptionModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Rebate/CreateUpdateEOYRebateException", request).pipe(map(response => response.result));
  }
}