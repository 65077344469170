import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { ServiceCallResult } from './../../../core/models/service-call-result';

import { ABPApprovalModel } from './../models/abp-approval-model';
import { environment } from './../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ABPApprovalService {

    constructor(private http: HttpClient) { }

    public GetAllApproval(data: ABPApprovalModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "ABPApproval/GetAllApproval", data).pipe(map(response => response));
    }

    public GetCurrentStatus(data: ABPApprovalModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "ABPApproval/GetCurrentStatus", data).pipe(map(response => response));
    }

    public async GetCurrentStatusPromise(data: ABPApprovalModel): Promise<Observable<ABPApprovalModel>> {
        return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}ABPApproval/GetCurrentStatus`, data).pipe(map(response => response.result));
    }

    public CreateUpdate(data: ABPApprovalModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "ABPApproval/CreateUpdate", data).pipe(map(response => response));
    }
}