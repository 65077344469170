<mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">

    <ng-container matColumnDef="shopId">
      <mat-header-cell *matHeaderCellDef>{{"Shop/Dealership Location"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" [(ngModel)]="element.shopCtsId" ngDefaultControl>
        {{element.shopCtsId}} - {{element.shopName}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>{{"Plan Description"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" [(ngModel)]="element.planDescription" ngDefaultControl>
        {{element.planDescription}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comments">
      <mat-header-cell *matHeaderCellDef>{{"Comments"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" [(ngModel)]="element.comments" ngDefaultControl>{{element.comments}}
      </mat-cell>
    </ng-container>

   
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>