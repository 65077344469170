import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@progress/kendo-angular-grid';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AngularMaterialModule } from './core/angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { RebateModule } from './modules/rebate/rebate.module';

import { AuthenticationGuard } from './core/security/authentication.guard';
import { TokenInterceptorProvider } from './core/security/token-interceptor';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './modules/home/components/welcome/welcome.component';
import { UserSearchComponent } from './modules/users/pages/user-search/user-search.component';
import { UserAddEditComponent } from './modules/users/pages/user-add-edit/user-add-edit.component';
import { CustomerHeaderComponent } from './modules/customer/components/customer-header/customer-header.component';
import { CustomerSearchComponent } from './modules/customer/pages/customer-search/customer-search.component';
import { CustomerLinesComponent } from './modules/customer/components/customer-lines/customer-lines.component';
import { AdminModule } from './modules/admin/admin.module';
import { ContractsModalComponent } from './modules/rebate/components/modals/contracts-modal/contracts-modal.component';
import { UsersListComponent } from './modules/users/pages/users-list/users-list.component';
import { LocationsComponent } from './modules/locations/locations/locations.component';

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    UserSearchComponent,
    UserAddEditComponent,
    CustomerSearchComponent,
    CustomerHeaderComponent,
    CustomerLinesComponent,
    UsersListComponent,
    LocationsComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularMaterialModule,
    MatNativeDateModule,
    GridModule,
    BrowserModule,
    CoreModule,
    RebateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    AdminModule
  ],
  providers: [
    AuthenticationGuard,
    TokenInterceptorProvider,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }],
  bootstrap: [AppComponent],
  entryComponents: [ContractsModalComponent]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
