import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { environment } from '../../../../../environments/environment';
import { RebateLevelService } from '../../services/rebate-level.service';

@Component({
  selector: 'app-rebate-level',
  templateUrl: './rebate-level.component.html',
  styleUrls: ['./rebate-level.component.css']
})
export class RebateLevelComponent implements OnChanges {
  @Input() ctsId: number;
  public year:number;
  public skip: number = 0;
  public pageSize: number = environment.gridPageSize;
  public gridData: any[];
  public gridView: any[];

  public segmentId: number;
  displayedColumns: string[] = ['brand', 'consecutionLevel', 'hvlColor', 'ct'];

  ngOnChanges(changes: SimpleChanges): void {
    this.year=GlobalVariablesService.getYear();
    if (changes.ctsId) {
      this.loadData();
    }
  }

  constructor(private route: ActivatedRoute,
    private rebateLevelService: RebateLevelService) { }

  public loadData() {
    this.rebateLevelService.getRebateLevel(this.ctsId, this.year).subscribe(response => {
      this.gridData = response;
      this.gridView = response;
    });
  }
}
