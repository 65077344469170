<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <br>
    <h3 class="center">{{title|translate}}</h3>
    <!-- <div class="row">
        <mat-form-field class="yearInput">
            <mat-label>Year</mat-label>
            <mat-select [(value)]="year" (selectionChange)="LoadTrainings()">
                <mat-option *ngFor="let year of years" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
    <div>
        <button *ngIf="!showAddCancel" mat-button  (click)="ShowAddCancel()"
            [disabled]="(IsLocked() || !(year > 0))">
            <span class="material-icons">add_box</span>
        </button>
        <!-- Add Table-->
        <div *ngIf="showAddCancel">
            <table mat-table [dataSource]="addrow" class="add_grid mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{"Course"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field style="width:100%">
                            <input [value]="item.name" [(ngModel)]="item.name" matInput
                                placeholder="{{'Enter Name'|translate}}" [formControl]="name" type="text" required>
                            <mat-error *ngIf="name.invalid">{{getErrorMessage("Course")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)">
                            <span class="material-icons">save</span>
                        </button>
                        <button *ngIf="showAddCancel" mat-button (click)="ShowAddCancel()"><span
                                class="material-icons">cancel</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
            </table>
        </div>
        <br>
        <!-- Data Table-->
        <div>
            <mat-form-field>
                <mat-label>{{"Filter"|translate}}</mat-label>
                <input matInput (keyup)="Filter($event)" [disabled]="!(year > 0)">
            </mat-form-field>
            <table mat-table [dataSource]="trainingsFilter" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{"Course"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field class="form_field">
                            <input matInput placeholder="{{'Name'|translate}}" [value]="item.name"
                                [(ngModel)]="item.name" [disabled]="IsLocked()">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)" [disabled]="IsLocked()">
                            <span class="material-icons">save</span>
                        </button>
                        <button mat-button  (click)="Delete(row)" [disabled]="IsLocked()">
                            <span class="material-icons">remove_circle</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="trainingsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: trainingsColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [disabled]="!(year > 0)">
            </mat-paginator>
        </div>
        <div>
            <button mat-button  class="btnPPG1" (click)="Lock()" *ngIf="ShowLockButton()"
                [disabled]="(IsLocked() || !(year > 0))">{{"Lock"|translate}}</button>
        </div>
    </div>
</div>