import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error/services/error.service';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { CustomerLineSegmentModel } from '../models/customer-line-segment.model';
import { CustomerValueLinkModel } from '../models/customer-value-link.model';
import { LineSegmentModel } from '../models/line-segment.model';

@Injectable({
  providedIn: 'root'
})
export class LineSegmentService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  // public GetAll(): Observable<LineSegmentModel[]> {
  //   return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/GetAll`)
  //     .pipe(
  //       map(response => response.result)
  //     );
  // }

  public GetByLineId(lineId: number, ctsId: number, year: number): Observable<LineSegmentModel[]> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/GetByLineId/${lineId}/${ctsId}/${year}`)
      .pipe(
        map(response => response.result)
      );
  }

  // public GetBySegmentId(segmentId: number): Observable<LineSegmentModel[]> {
  //   return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/GetBySegmentId/${segmentId}`)
  //     .pipe(
  //       map(response => response.result)
  //     );
  // }

  // public GetByCtsId(ctsId: number): Observable<LineSegmentModel[]> {
  //   return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/GetByCtsId/${ctsId}`)
  //     .pipe(
  //       map(response => response.result)
  //     );
  // }
  public getLinesByCtsId(ctsId:number, year: number): Observable<LineSegmentModel[]> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/get-lines/${ctsId}/${year}`)
    .pipe(
      map(response => response.result)
    );
  }

  public getSegmentsByLineCtsId(lineId: number, ctsId: number): Observable<LineSegmentModel[]> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/get-segments/${lineId}/${ctsId}`)
    .pipe(
      map(response => response.result)
    );
  }

  public saveLineSegment(customerLineSegment: CustomerLineSegmentModel[]): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/SaveLineSegment`, customerLineSegment, httpOptions)
      .pipe(
        map(response => response.result)
      );
  }

  public getCustomerValueLineLink(ctsId: number, year: number): Observable<CustomerValueLinkModel> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/GetCustomerValueLineLink/${ctsId}/${year}`)
      .pipe(
        map(response => response.result)
      );
  }

  public saveValueLineLink(distributorValueLink: CustomerValueLinkModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}LineSegment/SaveValueLineLink`, distributorValueLink, httpOptions)
      .pipe(
        map(response => response.result)
      );
  }
}
