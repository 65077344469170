export class FollowUpModel {
    public type: string;
    public year: number;
    public ctsId: number;
    public brandName: string;
    public segmentName: string;
    public total: number = 0;
    public clearcoatTarget: number = 0;
    public primerTarget: number = 0;
    public excelValue: number = 0;
    public oem: number = 0;
    public archivePercentage: number = 0;

    public totalVolume: number = 0;
    public excelValueVolume: number = 0;
    public achievedPercentageVolume: number = 0;

    public rebatePercentage: number = 0;
    public rebate: number = 0;
    public pendingPurchase: number = 0;

    public hvlsBaseValue: number = 0;
}