<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <h3 class="center">{{"Rebate Calculator"|translate}}</h3>
    <br>
    <!-- Rebate Resume -->
    <div>
        <table mat-table [dataSource]="GetCustomerRebates()" class="rebate_grid mat-elevation-z8">
            <td>test</td>
            <ng-container matColumnDef="rebateTypeName">
                <th mat-header-cell *matHeaderCellDef>{{"Rebate"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{item.rebateTypeName}}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <br>
                    {{"Total Rebates"|translate}}
                </td>
            </ng-container>
            <ng-container matColumnDef="rebate">
                <th mat-header-cell *matHeaderCellDef>{{"Ammount"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    <label
                        *ngIf="item.rebateTypeName != 'Annual Rebate'">{{GetCurrencySymbol()}}{{getRebate(getBaseValue(),item.percentage,item)|number:'1.2-2':languageCultureCode}}</label>
                    <label
                        *ngIf="item.rebateTypeName == 'Annual Rebate'">{{GetCurrencySymbol()}}{{getTotalAnnualRebate()|number:'1.2-2'
                        :languageCultureCode}}</label>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <br>
                    {{GetCurrencySymbol()}}{{getRebateTotal(customerRebate)|number:'1.2-2'
                    :languageCultureCode}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="rebatesResumeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: rebatesResumeColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="rebatesResumeColumns"></tr>
        </table>
    </div>
    <br>
    <h3 class="center">{{"Annual Rebate"|translate}}</h3>
    <br>
    <!-- CAR -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetBrandFilterData(brand).length > 0">
            <h6>CAR - VALUE - {{brand}} ({{GetCurrencyCode()}})</h6><br>
            <div class="d-flex justify-content-end">
                <mat-form-field appearance="fill" *ngIf="isAdmin">
                    <mat-label>Rebate Exception {{GetCurrencySymbol()}}</mat-label>
                    <input id="{{'CAR' + brand}}" matInput type="number" (change)="SaveRebateException('CAR' + brand)"
                        [value]="GetRebateException('CAR' + brand)" >
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="GetBrandFilterData(brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">{{getTotal(GetBrandData('Sales',brand)) |
                            number:'1.2-2' :languageCultureCode}}</label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalExcelValue(GetBrandData('Sales',brand))|number:'1.2-2'
                            :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"% Value Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="achievedPercentageVolume"
                    *ngIf="IsValidColumn('CAR',brand,'achievedPercentageVolume')">
                    <th mat-header-cell *matHeaderCellDef>{{"% Volume Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.achievedPercentageVolume|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="oem">
                    <th mat-header-cell *matHeaderCellDef>{{brand == 'Max Meyer' ? 'Anexos MXM' : 'OEM'}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.oem |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="baseValue">
                    <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{(item.excelValue + item.oem) |number:'1.2-2' :languageCultureCode}}<br>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Value Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage(item,'CarS',brand, item.segmentName)*.65|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentageVolume"
                    *ngIf="IsValidColumn('CAR',brand,'rebatePercentageVolume')">
                    <th mat-header-cell *matHeaderCellDef>{{"%Volume Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentageVolume(item,'CarV',brand, item.segmentName)*.35|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="annualRebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getAnnualRebate(item,'CarS',brand,brand,
                        item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getSumAnnualRebate('CarS',brand)|number:'1.2-2'
                            :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rebateException" *ngIf="IsValidColumn('CAR',brand,'rebateException')">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate Exception"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetRebateException('CAR' + brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="GetColumns('CAR',brand)"></tr>
                <tr mat-row *matRowDef="let row; columns: GetColumns('CAR',brand);"></tr>
                <tr mat-footer-row *matFooterRowDef="GetColumns('CAR',brand)"></tr>
            </table>
            <br>
        </div>
        <!-- <div *ngIf="GetBrandData('Volume',brand).length > 0">
            <h6>CAR - VOLUME - {{brand}} (LITERS)</h6><br>
            <table mat-table [dataSource]="GetBrandData('Volume',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="clearcoatTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Clear Clearcoat"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.clearcoatTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="primerTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Primer Target"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.primerTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="oem">
                    <th mat-header-cell *matHeaderCellDef>{{"OEM"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.oem |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage |percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetBrandData('Volume',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate."|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage(item,'CarV',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="annualRebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetCurrencySymbol()}}{{getAnnualRebate(item,'CarV',brand,brand,
                        item.segmentName)|number:'1.2-2':languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{GetCurrencySymbol()}}{{getSumAnnualRebate('CarV',brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsV"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsV;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsV"></tr>
            </table>
            <br>
        </div> -->
    </div>
    <!-- HVLS -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
            <h6>VALUE LINES - {{brand}} ({{GetCurrencyCode()}})</h6><br>
            <div class="d-flex justify-content-end">
                <mat-form-field appearance="fill" *ngIf="isAdmin">
                    <mat-label>Rebate Exception</mat-label>
                    <input id="{{'HVLS' + brand}}" matInput type="number" (change)="SaveRebateException('HVLS' + brand)"
                        [value]="GetRebateException('HVLS' + brand)">
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="GetSegmentHVLSData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotal(GetSegmentHVLSData('Sales',brand)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalExcelValue(GetSegmentHVLSData('Sales',brand)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalArchivePercentage(GetSegmentHVLSData('Sales',brand)) |
                            percent:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="baseValue">
                    <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.hvlsBaseValue |number:'1.2-2' :languageCultureCode}}<br>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate."|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage(item,'HVLS',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="annualRebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getAnnualRebate(item,'HVLS',brand,brand,
                        item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getSumAnnualRebate('HVLS',brand)|number:'1.2-2'
                            :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rebateException" *ngIf="IsValidColumn('HVLS',brand,'rebateException')">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate Exception"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetRebateException('HVLS' + brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="GetColumns('HVLS',brand)"></tr>
                <tr mat-row *matRowDef="let row; columns: GetColumns('HVLS',brand);"></tr>
                <tr mat-footer-row *matFooterRowDef="GetColumns('HVLS',brand)"></tr>
            </table>
            <br *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- CT -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentCTData('Sales',brand).length > 0">
            <h6>CT - VALUE ({{GetCurrencyCode()}})</h6><br>
            <div class="d-flex justify-content-end">
                <mat-form-field appearance="fill" *ngIf="isAdmin">
                    <mat-label>Rebate Exception</mat-label>
                    <input id="{{'CT' + brand}}" matInput type="number" (change)="SaveRebateException('CT' + brand)"
                        [value]="GetRebateException('CT' + brand)" >
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="GetSegmentCTData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotal(GetSegmentCTData('Sales',brand)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalExcelValue(GetSegmentCTData('Sales',brand)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalArchivePercentage(GetSegmentCTData('Sales',brand)) |
                            percent:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="oem">
                    <th mat-header-cell *matHeaderCellDef>{{"OEM"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{(item.oem) |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="baseValue">
                    <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{(item.excelValue+item.oem) |number:'1.2-2' :languageCultureCode}}<br>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate."|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage(item,'CT',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="annualRebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getAnnualRebate(item,'CT',brand,brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getSumAnnualRebate('CT',brand)|number:'1.2-2'
                            :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rebateException" *ngIf="IsValidColumn('CT',brand,'rebateException')">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate Exception"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetRebateException('CT' + brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="GetColumns('CT',brand)"></tr>
                <tr mat-row *matRowDef="let row; columns: GetColumns('CT',brand);"></tr>
                <tr mat-footer-row *matFooterRowDef="GetColumns('CT',brand)"></tr>
            </table>
            <br *ngIf="GetSegmentCTData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- LIC -->
    <div *ngFor="let segment of segments">
        <div *ngIf="GetSegmentData('Sales',segment).length > 0">
            <h6>{{segment}} - VALUE ({{GetCurrencyCode()}})</h6><br>
            <div class="d-flex justify-content-end">
                <mat-form-field appearance="fill"  *ngIf="isAdmin">
                    <mat-label>Rebate Exception</mat-label>
                    <input id="{{'LIC' + segment}}" matInput type="number"
                        (change)="SaveRebateException('LIC' + segment)"
                        [value]="GetRebateException('LIC' + segment)">
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="GetSegmentData('Sales',segment)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotal(GetSegmentData('Sales',segment)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalExcelValue(GetSegmentData('Sales',segment)) |
                            number:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getTotalArchivePercentage(GetSegmentData('Sales',segment))
                            | percent:'1.2-2' :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="baseValue">
                    <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}<br>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate."|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage(item,'LIC',item.brandName, segment)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="annualRebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getAnnualRebate(item,'LIC',segment,item.brandName,
                        segment)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <label style="display: none;">
                            {{getSumAnnualRebate('LIC',segment)|number:'1.2-2'
                            :languageCultureCode}}
                        </label>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rebateException" *ngIf="IsValidColumn('LIC',segment,'rebateException')">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate Exception"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetRebateException('LIC' + segment)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="GetColumns('LIC',segment)"></tr>
                <tr mat-row *matRowDef="let row; columns: GetColumns('LIC',segment);"></tr>
                <tr mat-footer-row *matFooterRowDef="GetColumns('LIC',segment)"></tr>
            </table>
            <br>
        </div>
    </div>
    <br>
    <h3 class="center">{{"Platinum Rebates"|translate}}</h3>
    <div *ngFor="let rebate of customerRebate">
        <div *ngIf="FilterCustomerRebates(rebate).length > 0">
            <table mat-table [dataSource]="FilterCustomerRebates(rebate)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="rebateTypeName">
                    <th mat-header-cell *matHeaderCellDef>{{"Name"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.rebateTypeName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="exclusiveDistributor"
                    *ngIf="IsValidColumnRebates(rebate,'exclusiveDistributor')">
                    <th mat-header-cell *matHeaderCellDef>{{"Exclusive Distributor"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-checkbox [disabled]="true" [checked]="metEligibilityForm">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="qualitativeActions"
                    *ngIf="IsValidColumnRebates(rebate,'qualitativeActions')">
                    <th mat-header-cell *matHeaderCellDef>{{"Qualitative Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-checkbox [disabled]="true" [checked]="metQualitativeActions">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="minConsecutionAbp">
                    <th mat-header-cell *matHeaderCellDef>{{"Min. Consecution ABP"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.minConsecutionAbp|number:'1.2-2':languageCultureCode}}%
                    </td>
                </ng-container>
                <ng-container matColumnDef="targetPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Target Purchase on ABP"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetCurrencySymbol()}}{{getTargetPurchase(item)|number:'1.2-2':languageCultureCode}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="consecutionABP">
                    <th mat-header-cell *matHeaderCellDef>{{"Consecution on ABP"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getConsecution()|number:'1.2-2':languageCultureCode}}%
                    </td>
                </ng-container>
                <ng-container matColumnDef="ammountConsecutionABP">
                    <th mat-header-cell *matHeaderCellDef>{{"Consecution on ABP Ammount"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetCurrencySymbol()}}{{getConsecutionAmmount()|number:'1.2-2':languageCultureCode}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="baseValue">
                    <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetCurrencySymbol()}}{{getBaseValue()|number:'1.2-2':languageCultureCode}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate %"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.percentage|number:'1.2-2':languageCultureCode}}%
                    </td>
                </ng-container>
                <ng-container matColumnDef="rebate">
                    <th mat-header-cell *matHeaderCellDef>{{"Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{GetCurrencySymbol()}}{{getRebate(getBaseValue(),item.percentage,item)|number:'1.2-2':languageCultureCode}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="GetRebateColumns(rebate)"></tr>
                <tr mat-row *matRowDef="let row; columns: GetRebateColumns(rebate);"></tr>
            </table>
            <br>
            <app-requirement-form *ngIf="showRequirements(rebate)" [requirementTypeId]="GetRequirementTypeId(rebate)"
                [IsCalculator]="true" (metAllRequirements)="GetMetAllRequirements($event,rebate)">
            </app-requirement-form>
        </div>
    </div>
</div>