import { NotificationService } from '../services/notification-service';
import { GlobalVariablesService } from '../services/global-variables.service';
import { ApiAuthResult } from './api-auth-result';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import JWT from 'jwt-decode';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor() {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    GlobalVariablesService.setCtsIdVisibility(true);
    let permission = "/" + next.routeConfig.path;
    console.log('Permission: ' + permission);
    const token = sessionStorage.getItem('ApiToken');
    const userTypeName: string = GlobalVariablesService.getUserTypeName();
    if (this.IsValidToken(token)) {
      if (this.HasPermission(permission)) {
        return true;
      } else {
        NotificationService.error(null, "Not Authorized");
      }
    } else {
      NotificationService.error(null, "Session Expired");
      sessionStorage.clear();
      window.location.href = GlobalVariablesService.getEnvironment().sso;
    }
  }

  private ValidateExpectedRoles(expectedRoles: any[], userTypeName: string) {
    let result: boolean = false;
    expectedRoles.forEach(x => {
      if (x == userTypeName) {
        return true;
      }
    });
    return result;
  }

  private IsValidToken(tokenstr: string): boolean {
    let result: boolean = false;
    if (tokenstr === null || tokenstr.length === 0) {
      return false;
    }
    try {
      const token: ApiAuthResult = JWT(tokenstr);
      result = token.exp && new Date() < new Date(token.exp * 1000);
    } catch (e) {
    }
    return result;
  }

  public HasPermission(name: string) {
    let hasPermission = false;
    var permissions = GlobalVariablesService.getUserTypePermissions();
    if (permissions != null) {
      permissions.forEach(x => {
        if (name == x.Name) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  }
}
