import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';

import { ServiceCallResult } from './../../../core/models/service-call-result';
import { UserModel } from '../models/user-model';
import { LanguagesModel } from '../models/languages-model';
import { MLlabelsModel } from '../models/mlLabels-model';
import { NumberSymbol } from '@angular/common';
import { UsersListModel } from '../models/users-list-model';
import { UserVPRoleModel } from '../models/user-vprole.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public GetUsers(): Observable<ServiceCallResult> {
    return this.http.get<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}User/GetUsers`).pipe(map(response => response));
  }

  public GetUserTypes(): Observable<ServiceCallResult> {
    return this.http.get<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}User/GetUserTypes`).pipe(map(response => response));
  }

  public GetUsersListByCTSId(ctsId:number): Observable<ServiceCallResult> {
    return this.http.get<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}User/GetUsersListByCTSId/${ctsId}`).pipe(map(response => response));
  }

  public GetUserLanguages(user:UserModel): Observable<LanguagesModel[]> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint +"User/GetUserLanguages",user).pipe(map(response => response.result));
  }

  public GetLabelDescription(label:MLlabelsModel): Observable<MLlabelsModel[]> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint +"User/GetLabelDescription",label).pipe(map(response => response.result),shareReplay(1));
  }

  public GetUserById(user: UserModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "User/GetUserById", user).pipe(map(response => response));
  }

  public CreateUpdate(user: UserModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "User/CreateUpdate", user).pipe(map(response => response));
  }

  public CreateOrEditDistributorUsers(user: UsersListModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "User/CreateOrEditDistributorUsers", user).pipe(map(response => response));
  }  

  public ResetPassword(user: UserModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "User/ResetPassword", user).pipe(map(response => response));
  }
  public getVpRole(logonId: string): Observable<ServiceCallResult> {
    return this.http.get<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}User/get_vp_role/${logonId}`).pipe(map(response => response));
  }
}
