import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from '../../../../core/services/notification-service';
import { CustomerModel } from '../../../customer/models/customer-model';
import { CustomerService } from '../../../customer/services/customer.service';
import { TargetAccountModel } from '../../models/target-account.model';
import { TargetAccountService } from '../../services/target-account.service';

@Component({
  selector: 'app-target-account-display',
  templateUrl: './target-account-display.component.html',
  styleUrls: ['./target-account-display.component.scss']
})
export class TargetAccountDisplayComponent implements OnInit {

  public title: string = "Target Account";
  public year: number = 0;
  public ctsId: number = null;
  public showAddCancel = false;
  public customers: CustomerModel[] = [];
  public customersFilter: CustomerModel[] = [];
  public targetAccounts: TargetAccountModel[];
  public targetAccountFilter = new MatTableDataSource<TargetAccountModel>();
  public addrow: TargetAccountModel[];
  public targetAccountsColumns: string[] = ['targetCtsId', 'shopName', 'shopGrossSales', 'potentialPurchases', 'targetDate', 'competitor', 'numberPainters', 'planDetails'];
  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();
  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();

  constructor(private targetAccount_service: TargetAccountService, private customerService: CustomerService, private route: ActivatedRoute, private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadTargetAccounts();
  }

  ngOnInit(): void {
  }

  public LoadTargetAccounts(): void {
    if (this.ctsId > 0) {
      this.showAddCancel = false;
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetDistributorPaintShops(customer).subscribe(response => {
        if (response.success) {
          this.customers = response.result;
          this.customersFilter = this.customers;
        } else {
          NotificationService.error(null, this.translateService.instant("Error Loading Customers List"));
        }
        let targetAccount = new TargetAccountModel();
        targetAccount.year = this.year;
        targetAccount.ctsId = this.ctsId;
        this.targetAccount_service.GetAllTargetAccountsForDisplay(targetAccount).subscribe(response => {
          this.targetAccounts = response.result;
          this.targetAccountFilter.data = this.targetAccounts;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        });
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      });
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }
}