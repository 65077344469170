<div id="menu_topnav" class="topnav" style="font-family: Roboto">
  <a routerLink="/">
    <img src="../../../assets/img/ppg.png" alt="Logo" class="logo">
  </a>
  <a class="link" routerLink="/" class="system_title">{{"Platinum Distributor"|translate}}</a>
  <a class="link" routerLink="/benefits" *ngIf="!authenticated">
    <span class="material-icons">assignment</span>
    <span class="menutitle">{{"Benefits"|translate}}</span>
  </a>
  <a class="link" routerLink="/training"  *ngIf="!authenticated">
    <span class="material-icons">library_books</span>
    <span class="menutitle">{{"Training"|translate}}</span>
  </a>
  <a class="link" routerLink="/news"  *ngIf="!authenticated">
    <span class="material-icons">textsms</span>
    <span class="menutitle">{{"News"|translate}}</span>
  </a>
  <a class="link" routerLink="/contact"  *ngIf="!authenticated">
    <span class="material-icons">live_help</span>
    <span class="menutitle">{{"Contact"|translate}}</span>
  </a>
  <!-- Authenticated Options -->
  <a href="javascript:void(0);" class="no-print">
    <mat-form-field *ngIf="authenticated && showCtsIdVisibility()">
      <mat-label class="mat_combo_box">{{"Select CTS Id"|translate}}</mat-label>
      <mat-select #selectCtsId [(value)]="this.ctsId" (selectionChange)="SetCtsId($event.value)"
        (keydown)="$event.stopPropagation()" (blur)="ClearCustomerFilter()">
        <form>
          <mat-form-field class="w-100">
            <input type="text" (keyup)="FilterCustomer($event.target.value)" matInput>
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </form>
        <mat-option class="mat_combo_box" *ngFor="let customer of customersFilter" [value]="customer.ctsId">
          {{customer.ctsId + '-' + customer.companyName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </a>
  <!-- Authenticated Options -->
  <a href="javascript:void(0);" class="no-print">
    <mat-form-field *ngIf="authenticated">
      <mat-label class="mat_combo_box">{{"Select a Year"|translate}}</mat-label>
      <mat-select #selectCtsId [(value)]="this.year" (selectionChange)="SetYear($event.value)"
        (keydown)="$event.stopPropagation()">
        <mat-option class="mat_combo_box" *ngFor="let year of years" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </a>
  <a href="javascript:void(0);" class="no-print">
    <mat-form-field *ngIf="authenticated">
      <mat-label class="mat_combo_box">{{this.userLanguage}}</mat-label>
      <mat-select (selectionChange)="SwitchLang($event.value)">
        <mat-option class="mat_combo_box" *ngFor="let language of this.languages"
          [value]="language.languageCultureCode">
          {{language.languageName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </a>
  <!-- Options -->
  <a class="link a_right no-print" (click)="ShowOptions()" *ngIf="authenticated">
    <span class="material-icons">settings_applications</span>
  </a>
  <a class="link a_right no-print" href="javascript:void(0);" *ngIf="authenticated" style="cursor: unset;">
    {{getEmailAddress()}}
  </a>
  <!-- Log In -->
  <a class="link a_right" (click)="LogIn()" *ngIf="!authenticated">
    <span class="material-icons">vpn_key</span>
    <span class="menutitle">{{"Log In"|translate}}</span>
  </a>
  <!-- Responsive Icon -->
  <a class="a_left" href="javascript:void(0);" class="icon" (click)="TopNavMenu()">
    <span class="material-icons">menu</span>
  </a>
</div>
<div id="options_menu" class="sidenav" *ngIf="leftMenu">
  <div>
    <a (click)="HideOptions()" href=" javascript:void(0);" >
      <span class="material-icons">close</span>
      <span class="menutitle">{{"Close"|translate}}</span>
    </a>
  </div>
  <br>
  <!-- Profile -->
  <div>
    <a (click)="HideOptions()" routerLink="/profile">
      <span class="material-icons">face</span>
      <span class="menutitle">{{"Profile"|translate}}</span>
    </a>
  </div>
  <br>
  <div class="submenu" (click)="ShowHideSubMenus('maintenance')" *ngIf="HasPermission('/maintenance')">
    <span class="material-icons option_separator">expand_more</span>
    <label class="option_separator">{{"Maintenance"|translate}}</label>
  </div>
  <div *ngIf="showMaintenance">
    <!-- Eligibility Requirements Catalog -->
    <div>
      <a (click)="HideOptions()" routerLink="/elegibility-requirements-catalog"
        *ngIf="HasPermission('/elegibility-requirements-catalog')">
        <span class="material-icons">fact_check</span>
        <span class="menutitle">{{"Eligibility Requirements"|translate}}</span>

      </a>
    </div>
    <!-- Platinum Functional Rebate Requirements Catalog -->
    <div>
      <a (click)="HideOptions()" routerLink="/platinum-functional-rebate-requirements-catalog"
        *ngIf="HasPermission('/platinum-functional-rebate-requirements-catalog')">
        <span class="material-icons">fact_check</span>
        <span class="menutitle">{{"Rebate Requirements"|translate}}</span>
      </a>
    </div>
    <!-- Development Plan Maintenance -->
    <div>
      <a (click)="HideOptions()" routerLink="/dev-plan"
        *ngIf="HasPermission('/dev-plan')">
        <span class="material-icons">follow_the_signs</span>
        <span class="menutitle">{{"Development Plan"|translate}}</span>
      </a>
    </div>
    <!-- Training Maintenance -->
    <div>
      <a (click)="HideOptions()" routerLink="/training-catalog" 
        *ngIf="HasPermission('/training-catalog')">
        <span class="material-icons">model_training</span>
        <span class="menutitle">{{"Training"|translate}}</span>
      </a>
    </div>
    <!-- Platinum Rebate Exclusivity Maintenance -->
    <div>
      <a (click)="HideOptions()" routerLink="/platinum-rebate-maintenance"
        
        *ngIf="HasPermission('/platinum-rebate-maintenance')">
        <span class="material-icons">list_alt</span>
        <span class="menutitle">{{"% Rebate"|translate}}</span>
      </a>
    </div>
    <!-- Platinum Plus Rebate Maintenance -->
    <div>
      <a (click)="HideOptions()" routerLink="/platinum-plus-rebate-maintenance"
       
        *ngIf="HasPermission('/platinum-plus-rebate-maintenance')">
        <span class="material-icons">list_alt</span>
        <span class="menutitle">{{"% Plus Rebate"|translate}}</span>
      </a>
    </div>
    <!-- Platinum Functional Rebate Maintenance -->
    <div>
      <a (click)="HideOptions()" routerLink="/platinum-functional-rebate-maintenance"
       
        *ngIf="HasPermission('/platinum-functional-rebate-maintenance')">
        <span class="material-icons">list_alt</span>
        <span class="menutitle">{{"% Functional Rebate"|translate}}</span>
      </a>
    </div>
    <!--Clauses-->
    <div>
      <a (click)="HideOptions()" routerLink="/annual-business-plan"   *ngIf="HasPermission('/clauses')">
        <span class="material-icons">class</span>
        <span class="menutitle">{{ "Clauses" | translate }}</span>
      </a>
    </div>
    <!-- Customer Clauses
    <div>
      <a (click)="HideOptions()" routerLink="/customer-clause" matTooltip="{{'Customer Clauses'|translate}}">
        <span class="material-icons">class</span>
        <span class="menutitle">{{ "Customer Clauses" | translate }}</span>
      </a>
    </div> -->
    <div>
      <a (click)="HideOptions()" routerLink="/abp-data-entry" *ngIf="HasPermission('/abp-data-entry')">
        <span class="material-icons">input</span>
        <span class="menutitle">{{ "ABP Data Entry" | translate }}</span>
      </a>
    </div>
  </div>

  <div class="submenu" (click)="ShowHideSubMenus('other')" *ngIf="HasPermission('/other')">
    <span class="material-icons option_separator">expand_more</span>
    <label class="option_separator">{{"Other"|translate}}</label>
  </div>
  <div *ngIf="showOther">
    <!-- Users -->
    <div>
      <a (click)="HideOptions()" routerLink="/users"  *ngIf="HasPermission('/users')">
        <span class="material-icons">supervisor_account</span>
        <span class="menutitle">{{"Users"|translate}}</span>
      </a>
    </div>
    <!-- Upload Excel -->
    <div>
      <a (click)="HideOptions()" routerLink="/excel-upload" 
        *ngIf="HasPermission('/excel-upload')">
        <span class="material-icons">cloud_upload</span>
        <span class="menutitle">{{"Upload Excel"|translate}}</span>
      </a>
    </div>
    <!-- Calculator
    <div>
      <a (click)="HideOptions()" routerLink="/calculator" matTooltip="{{'Calculator'|translate}}"
        *ngIf="HasPermission('/calculator')">
        <span class="material-icons">table_view</span>
        <span class="menutitle">{{"Calculator"|translate}}</span>
      </a>
    </div>-->
  </div>
  <br>
  <!-- Log Out -->
  <div>
    <a class="link" (click)="LogOut()" *ngIf="authenticated">
      <span class="material-icons">exit_to_app</span>
      <span class="menutitle">{{"Log Out"|translate}}</span>
    </a>
  </div>
</div>
<div class="router" (click)="HideOptions()">
  <router-outlet></router-outlet>
</div>
<br>
<footer class="footer no-print" style="bottom: 0;">
  <p>{{"Platinum Distributor and its logo are registered trademarks of PPG Industries Ohio, Inc. © 2022 PPG
    Industries, Inc. All rights reserved."| translate}}</p>
</footer>
<app-spinner *ngIf="spinner"></app-spinner>