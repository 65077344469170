<table mat-table [dataSource]="customerTrainings" class="data_grid mat-elevation-z8">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"Name"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="trainingName">
        <th mat-header-cell *matHeaderCellDef>{{"Course"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.trainingName }}
        </td>
    </ng-container>
    <ng-container matColumnDef="targetDate">
        <th mat-header-cell *matHeaderCellDef>{{"Target Date"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.targetDate }}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="trainingsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: trainingsColumns;"></tr>
</table>