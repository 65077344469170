<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <h3 class="center">{{"Rebate Calculator"|translate}}</h3>
    <br>
    <!-- Rebate Resume -->
    <div>
        <table mat-table [dataSource]="customerRebate" class="rebate_grid mat-elevation-z8">
            <ng-container matColumnDef="rebateTypeName">
                <th mat-header-cell *matHeaderCellDef>{{"Rebate"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{item.rebateTypeName}}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <br>
                    {{"Total Estimated Platinum Rebates"|translate}}<br><br>
                    {{"Pending Purchases"|translate}}<br><br>
                    {{"Pending Purchases (LITERS)"|translate}}<br><br>
                </td>
            </ng-container>
            <ng-container matColumnDef="rebate">
                <th mat-header-cell *matHeaderCellDef>{{"Estimated Rebate"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{GetCurrencySymbol()}}{{getRebate(getBaseValue(),item.percentage)|number:'1.2-2':languageCultureCode}}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <br>
                    {{GetCurrencySymbol()}}{{getRebateTotal(customerRebate)|number:'1.2-2':languageCultureCode}}<br><br>
                    {{GetCurrencySymbol()}}{{pendingPurchase|number:'1.2-2':languageCultureCode}}<br><br>
                    {{pendingPurchaseVolume|number:'1.2-2':languageCultureCode}}<br><br>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="rebatesResumeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: rebatesResumeColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="rebatesResumeColumns"></tr>
        </table>
    </div>
    <br>
    <h3 class="center">{{"Annual Rebate"|translate}}</h3>
    <br>
    <!-- CAR -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetBrandData('Sales',brand).length > 0">
            <h6>CAR - VALUE - {{brand}} ({{GetCurrencyCode()}})</h6><br>
            <mat-form-field>
                <label>{{"Estimated Consecution Level %"|translate}}</label>
                <input id="{{'CarS' + brand}}" matInput type="number">
            </mat-form-field>
            <table mat-table [dataSource]="GetBrandData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetBrandData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetBrandData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetBrandData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage('CarS',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="pendingPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Pending Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getPendingPurchase(item,'CarS',brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalPendingPurchase(GetBrandData('Sales',brand),'Cars',brand) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr>
            </table>
            <br>
        </div>
        <div *ngIf="GetBrandData('Volume',brand).length > 0">
            <h6>CAR - VOLUME - {{brand}} (LITERS)</h6><br>
            <mat-form-field>
                <label>{{"Estimated Consecution Level %"|translate}}</label>
                <input id="{{'CarV' + brand}}" matInput type="number">
            </mat-form-field>
            <table mat-table [dataSource]="GetBrandData('Volume',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="clearcoatTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Clear Clearcoat"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.clearcoatTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="primerTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Primer Target"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.primerTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage |percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetBrandData('Volume',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage('CarV',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="pendingPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Pending Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getPendingPurchase(item,'CarV',brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalPendingPurchase(GetBrandData('Volume',brand),'CarV',brand) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsV"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsV;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsV"></tr>
            </table>
            <br>
        </div>
    </div>
    <!-- HVLS -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
            <h6>VALUE LINES - {{brand}} ({{GetCurrencyCode()}})</h6><br>
            <mat-form-field>
                <label>{{"Estimated Consecution Level %"|translate}}</label>
                <input id="{{'HVLS' + brand}}" matInput type="number">
            </mat-form-field>
            <table mat-table [dataSource]="GetSegmentHVLSData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentHVLSData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentHVLSData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalArchivePercentage(GetSegmentHVLSData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage('HVLS',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="pendingPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Pending Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getPendingPurchase(item,'HVLS',brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalPendingPurchase(GetSegmentHVLSData('Sales',brand),'HVLS',brand)
                        |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsH"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsH;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsH"></tr>
            </table>
            <br *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- CT -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentCTData('Sales',brand).length > 0">
            <h6>CT - VALUE ({{GetCurrencyCode()}})</h6><br>
            <mat-form-field>
                <label>{{"Estimated Consecution Level %"|translate}}</label>
                <input id="{{'CT' + brand}}" matInput type="number">
            </mat-form-field>
            <table mat-table [dataSource]="GetSegmentCTData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentCTData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentCTData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalArchivePercentage(GetSegmentCTData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage('CT',brand, item.segmentName)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="pendingPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Pending Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getPendingPurchase(item,'CT',brand)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalPendingPurchase(GetSegmentCTData('Sales',brand),'CT',brand) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr>
            </table>
            <br *ngIf="GetSegmentCTData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- LIC -->
    <div *ngFor="let segment of segments">
        <div *ngIf="GetSegmentData('Sales',segment).length > 0">
            <h6>{{segment}} - VALUE ({{GetCurrencyCode()}})</h6><br>
            <mat-form-field>
                <label>{{"Estimated Consecution Level %"|translate}}</label>
                <input id="{{'LIC' + segment}}" matInput type="number">
            </mat-form-field>
            <table mat-table [dataSource]="GetSegmentData('Sales',segment)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentData('Sales',segment)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentData('Sales',segment)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetSegmentData('Sales',segment))
                        | percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="rebatePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Rebate"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getRebatePercentage('LIC',item.brandName, segment)|number:'1.2-2'
                        :languageCultureCode}}%
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="pendingPurchase">
                    <th mat-header-cell *matHeaderCellDef>{{"Pending Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{getPendingPurchase(item,'LIC',segment)|number:'1.2-2'
                        :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{GetCurrencySymbol()}}{{getTotalPendingPurchase(GetSegmentData('Sales',segment),'LIC',segment)
                        |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr>
            </table>
            <br>
        </div>
    </div>
    <br>
    <div>
        <h3 class="center">{{"Platinum Rebates"|translate}}</h3>
        <table mat-table [dataSource]="customerRebate" class="data_grid mat-elevation-z8">
            <ng-container matColumnDef="rebateTypeName">
                <th mat-header-cell *matHeaderCellDef>{{"Name"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{item.rebateTypeName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="exclusiveDistributor">
                <th mat-header-cell *matHeaderCellDef>{{"Exclusive Distributor"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    <mat-checkbox [checked]="item.exclusiveDistributor">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="qualitativeActions">
                <th mat-header-cell *matHeaderCellDef>{{"Qualitative Actions"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    <mat-checkbox [checked]="item.qualitativeActions">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="minConsecutionAbp">
                <th mat-header-cell *matHeaderCellDef>{{"Min. Consecution ABP"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{item.minConsecutionAbp|number:'1.2-2':languageCultureCode}}%
                </td>
            </ng-container>
            <ng-container matColumnDef="targetPurchase">
                <th mat-header-cell *matHeaderCellDef>{{"Target Purchase on ABP"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{GetCurrencySymbol()}}{{getTargetPurchase(item)|number:'1.2-2':languageCultureCode}}
                </td>
            </ng-container>
            <ng-container matColumnDef="consecutionABP">
                <th mat-header-cell *matHeaderCellDef>{{"Consecution on ABP"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{getConsecution()|number:'1.2-2':languageCultureCode}}%
                </td>
            </ng-container>
            <ng-container matColumnDef="ammountConsecutionABP">
                <th mat-header-cell *matHeaderCellDef>{{"Consecution on ABP Ammount"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{GetCurrencySymbol()}}{{getConsecutionAmmount()|number:'1.2-2':languageCultureCode}}
                </td>
            </ng-container>
            <ng-container matColumnDef="baseValue">
                <th mat-header-cell *matHeaderCellDef>{{"Base Value"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{GetCurrencySymbol()}}{{getBaseValue()|number:'1.2-2':languageCultureCode}}
                </td>
            </ng-container>
            <ng-container matColumnDef="percentage">
                <th mat-header-cell *matHeaderCellDef>{{"Rebate %"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{item.percentage|number:'1.2-2':languageCultureCode}}%
                </td>
            </ng-container>
            <ng-container matColumnDef="rebate">
                <th mat-header-cell *matHeaderCellDef>{{"Rebate"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    {{GetCurrencySymbol()}}{{getRebate(getBaseValue(),item.percentage)|number:'1.2-2':languageCultureCode}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="rebatesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: rebatesColumns;"></tr>
        </table>
    </div>
</div>