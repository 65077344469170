import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from './../../../../core/services/notification-service';
import { CustomerDevPlanService } from './../../services/customer-dev-plan.service';
import { DevPlanService } from '../../services/dev-plan.service';
import { CustomerDevPlanModel } from '../../models/customer-dev-plan-request.model';
import { DevPlanModel } from '../../models/dev-plan-request.model';
import { CustomerModel } from './../../../customer/models/customer-model';
import { CustomerService } from './../../../customer/services/customer.service';
import { ShopDataModel } from '../../models/shop-data.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-customer-dev-plan',
  templateUrl: './customer-dev-plan.component.html',
  styleUrls: ['./customer-dev-plan.component.scss']
})
export class CustomerDevPlanComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public title: string = "Development Plans";
  public ctsId: number = 0;
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public dataSource = new MatTableDataSource<CustomerDevPlanModel>();
  public displayedColumns = ["shopId", "description", "comments"];
  public spinner = false;
  public devplans: DevPlanModel[];
  public custdevplans: DevPlanModel[];
  public year: number;
  public AddOrDeleteFlag = false;
  public shopData: ShopDataModel[];
  public shopSearch: ShopDataModel[];

  constructor(private route: ActivatedRoute, private devPlanService: DevPlanService, private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId != 0) {
      this.GetCustomerById();
      this.GetDevPlansForAllShops();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName =
              (this.customer.ctsId == null ? '' : this.customer.ctsId + ' - ') +
              (this.customer.companyName == null ? '' : this.customer.companyName);

          } else {
            NotificationService.error(null, "Internal Error");
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, "An Error Ocurred");
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, "Internal Error");
    }
  }

  public GetDevPlansForAllShops(): void {
    this.spinner = true;
    try {
      let planrequest = new CustomerDevPlanModel();
      planrequest.ctsId = this.ctsId;
      planrequest.year = this.year;
      this.devPlanService.GetCustomerPlans(planrequest).subscribe(planresponse => {
        if (planresponse.success) {
          this.dataSource.data = planresponse.result;
        } else {
          NotificationService.error(null, "Internal Error");
        }
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, "An Error Ocurred");
        this.spinner = false;
      });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, "Internal Error");
    }
  }

  public SearchGrid(event: Event) {
    const searchkey = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchkey.trim().toLowerCase();
  }
}


