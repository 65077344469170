import { LocationsComponent } from './modules/locations/locations/locations.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './core/security/authentication.guard';

import { WelcomeComponent } from './modules/home/components/welcome/welcome.component';
import { UserSearchComponent } from './modules/users/pages/user-search/user-search.component';
import { UserAddEditComponent } from './modules/users/pages/user-add-edit/user-add-edit.component';
import { UsersListComponent } from './modules/users/pages/users-list/users-list.component';
import { RequirementComponent } from './modules/rebate/pages/requirement/requirement.component';
import { TrainingComponent } from './modules/rebate/pages/training/training.component';
import { CustomerRebateComponent } from './modules/rebate/pages/customer-rebate/customer-rebate.component';
import { CustomerSearchComponent } from './modules/customer/pages/customer-search/customer-search.component';
import { CustomerHeaderComponent } from './modules/customer/components/customer-header/customer-header.component';
import { RebateMaintenanceComponent } from './modules/rebate/pages/rebate-maintenance/rebate-maintenance.component';
import { DevPlanComponent } from './modules/rebate/pages/dev-plan/dev-plan.component';
import { ExcelUploadComponent } from './modules/rebate/components/excel-upload/excel-upload.component';
import { RebateLevelComponent } from './modules/rebate/components/rebate-level/rebate-level.component';
import { AnnualBusinessPlanComponent } from './modules/rebate/pages/annual-business-plan/annual-business-plan.component';
import { SalesGoalEntryComponent } from './modules/rebate/components/sales-goal-entry/sales-goal-entry.component';
import { TargetEntryComponent } from './modules/rebate/pages/target-entry/target-entry.component';
import { AnnualBusinessPlanDisplayComponent } from './modules/rebate/pages/annual-business-plan-display/annual-business-plan-display.component';
import { ContractsComponent } from './modules/rebate/pages/contracts/contracts.component';
import { TargetDisplayComponent } from './modules/rebate/components/target-display/target-display.component';
import { AnnualBusinessPlanDataEntryComponent } from './modules/rebate/pages/annual-business-plan-data-entry/annual-business-plan-data-entry.component';
import { ClauseSelectionComponent } from './modules/rebate/components/clause-selection/clause-selection.component';
import { FollowUpComponent } from './modules/rebate/pages/follow-up/follow-up.component';
import { CalculatorComponent } from './modules/rebate/pages/calculator/calculator.component';
import { CalculatorMenuComponent } from './modules/rebate/pages/calculator-menu/calculator-menu.component';

const routes: Routes = [
  { pathMatch: 'full', path: '', component: WelcomeComponent },
  { pathMatch: 'full', path: 'redirect-uri', component: WelcomeComponent },
  // Users
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'users', component: UserSearchComponent },
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'users-list', component: UsersListComponent },
  // Add New User Form
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'user-add-edit', component: UserAddEditComponent },
  // Edit User Form
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'user-add-edit/:userId', component: UserAddEditComponent },
  // Eligibility Requirements Catalog
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'elegibility-requirements-catalog', component: RequirementComponent,
    data: {
      title: 'Eligibility Form Requirements Maintenance',
      requirementTypeId: 1
    }
  },
  // Platinum Functional Rebate Requirements Catalog
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'platinum-functional-rebate-requirements-catalog', component: RequirementComponent,
    data: {
      title: 'Functional Rebate Form Requirements Maintenance',
      requirementTypeId: 2
    }
  },
  // Training Plan Catalog
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'training-catalog', component: TrainingComponent },
  // Platinum Functional Rebate Maintenance
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'platinum-functional-rebate-maintenance', component: RebateMaintenanceComponent,
    data: {
      rebateTypeId: 1,
      title: 'Platinum Functional Rebate Maintenance'
    }
  },
  { // Platinum Rebate Exclusivity Maintenance
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'platinum-rebate-maintenance', component: RebateMaintenanceComponent,
    data: {
      rebateTypeId: 2,
      title: 'Platinum Rebate Exclusivity Maintenance'
    }
  },
  { // Platinum Plus Rebate Maintenance
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'platinum-plus-rebate-maintenance', component: RebateMaintenanceComponent,
    data: {
      rebateTypeId: 3,
      title: 'Platinum Plus Rebate Maintenance'
    }
  },
  // { pathMatch: 'full', path: 'customer-consecution', component: CustomerConsecutionComponent },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'annual-business-plan', component: AnnualBusinessPlanComponent
  },
  {
    pathMatch: 'full', path: 'customer-clause', component: ClauseSelectionComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'excel-upload', component: ExcelUploadComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'sales-target', component: SalesGoalEntryComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'target-entry', component: TargetEntryComponent
  },
  {
    pathMatch: 'full', path: 'rebate-level', component: RebateLevelComponent
  },
  {
    pathMatch: 'full', path: 'target-view', component: TargetDisplayComponent
  },
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'distributor', component: CustomerSearchComponent },
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'distributor/:id', component: CustomerHeaderComponent },
  { pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'dev-plan', component: DevPlanComponent },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'customer-rebate/:year/:id/:ctsid', component: CustomerRebateComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'annual-business-plan-view', component: AnnualBusinessPlanDisplayComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'contracts', component: ContractsComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'abp-data-entry', component: AnnualBusinessPlanDataEntryComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'follow-up', component: FollowUpComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'calculator', component: CalculatorComponent
  },
  {
    pathMatch: 'full', canActivate: [AuthenticationGuard], path: 'calculator-menu', component: CalculatorMenuComponent
  },
  {
    pathMatch: 'full', path: 'locations', component: LocationsComponent
  },
  { path: '**', component: WelcomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
