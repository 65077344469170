import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceCallResult } from './../../../core/models/service-call-result';
import { environment } from '../../../../environments/environment';
 import { ErrorService } from '../../error/services/error.service';
import { RebateLevelModel } from '../models/rebate-level.model';
import { CustomerBrandRebateExclusionModel } from '../models/customer-brand-rebate-exclusion.model';

@Injectable({
  providedIn: 'root'
})

export class RebateLevelService {

  constructor(private http: HttpClient,
    private errorService: ErrorService) { }

  public getRebateLevel( ctsId:number, year:number): Observable<RebateLevelModel[]> {
    let result = this.http.get<ServiceCallResult>(`${environment.apiEndpoint}RebateLevel/get-by-cts/${ctsId}/${year}`).
      pipe(
        map(response => response.result)
      );
    return result;
  }
  public saveRebateLevel(rebateLevel: RebateLevelModel): Observable<boolean> {
    console.log("In angular service call");
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    let result = this.http.post<ServiceCallResult>(`${environment.apiEndpoint}RebateLevel/save-rebate-level`, rebateLevel, httpOptions).pipe(
      map(response => response.result)
    );
    return result;
  }

  public deleteRebateLevel(rebateLevel: RebateLevelModel): Observable<boolean> {    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    let result = this.http.post<ServiceCallResult>(`${environment.apiEndpoint}RebateLevel/delete-rebate-level`, rebateLevel, httpOptions).pipe(
      map(response => response.result)
    );
    return result;
  }

  public saveBrandExclusion(brandExclusion: CustomerBrandRebateExclusionModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    let result = this.http.post<ServiceCallResult>(`${environment.apiEndpoint}RebateLevel/save-rebate-exclusion`, brandExclusion, httpOptions).pipe(
      map(response => response.result)
    );
    return result;
  }
  public getRebatesForAbpByCtsId(ctsId: number, year:number): Observable<RebateLevelModel[]> {
    let result = this.http.get<ServiceCallResult>(`${environment.apiEndpoint}RebateLevel/get-by-cts-abp/${ctsId}/${year}`).
      pipe(
        map(response => response.result)
      );
    return result;
  }
}
