import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AnnualBusinessPlanService } from '../../services/annual-business-plan.service';
import { BusinessPlanClauseDetailModel } from '../../models/business-plan-clause-detail.model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BusinessPlanClauseDetailModalComponent } from '../../components/modals/business-plan-clause-detail-modal/business-plan-clause-detail-modal.component';
import { BusinessPlanClauseModalComponent } from '../../components/modals/business-plan-clause-modal/business-plan-clause-modal.component';
import { BusinessPlanClauseModel } from '../../models/business-plan-clause.model';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annual-business-plan',
  templateUrl: './annual-business-plan.component.html',
  styleUrls: ['./annual-business-plan.component.scss']
})
export class AnnualBusinessPlanComponent implements OnInit {
  public groups: BusinessPlanClauseModel[];
  public year: number;
  public spinner: boolean = false;
  public title: string = "Clauses";
  public clauseHeader = new BusinessPlanClauseModel();
  public isPointerOverContainer: boolean;
  constructor(private planService: AnnualBusinessPlanService, private translateService: TranslateService,
    private dialog: MatDialog, private router: Router,) {
    GlobalVariablesService.setCtsIdVisibility(false);
  }

  ngOnInit(): void {
    this.year = GlobalVariablesService.getYear();
    if (this.year == 0) {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    else {
      this.loadList();
    }
  }

  public loadList(): void {
    this.spinner = true;
    this.planService.getAll(this.year).subscribe(response => {
      this.groups = response;
    });
    this.spinner = false;
  }

  onChangeEvent(event: any) {
    let clauseDetail = new BusinessPlanClauseDetailModel();
    clauseDetail.id = event.target.nextSibling.value;
    clauseDetail.description = event.target.value;
    this.planService.saveDetail(clauseDetail).subscribe();
    NotificationService.success(null, this.translateService.instant("Saved"));
    this.loadList();
  }

  onHeaderChangeEvent(event: any) {
    console.log(event.target);
    this.clauseHeader.id = event.target.nextSibling.value;
    this.clauseHeader.name = event.target.value;
    this.clauseHeader.year = this.year;
    this.planService.saveHeader(this.clauseHeader).subscribe();
    NotificationService.success(null, this.translateService.instant("Saved"));
    this.loadList();
  }

  onClick(event: any, group: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: group.id,
      name: ''
    };
    dialogConfig.width = '1000px';

    const dialogRef = this.dialog.open(BusinessPlanClauseDetailModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(x => {
      this.loadList();
    });
  }
  public onClauseClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: '',
      name: ''
    };
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(BusinessPlanClauseModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(x => {
      this.loadList();
    });
  }

  dropItem(event: CdkDragDrop<any[]>) {
    if (this.isPointerOverContainer) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        let newOrder = event.container.data;
        this.planService.saveDetailSortOrder(newOrder).subscribe();
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
        var clauses: BusinessPlanClauseModel[];
        let oldClause = new BusinessPlanClauseModel();
        // tslint:disable-next-line: radix
        oldClause.id = parseInt(event.previousContainer.id);
        oldClause.details = event.previousContainer.data;
        let newClause = new BusinessPlanClauseModel();
        // tslint:disable-next-line: radix
        newClause.id = parseInt(event.container.id);
        newClause.details = event.container.data;
        clauses = [newClause, oldClause];
        this.planService.moveDetailToNewClause(clauses).subscribe();
      }
    }
    else {
      console.log(event.item);
      let removeOrder = event.item.data;
      if (event.item.data != undefined) {
        Swal.fire({
          title: this.translateService.instant("Delete") + "?",
          text: this.translateService.instant("You won't be able to revert this!"),
          icon: 'warning',
          confirmButtonColor: '#01304A',
          cancelButtonColor: 'red',
          confirmButtonText: this.translateService.instant('Confirm'),
          cancelButtonText: this.translateService.instant('Cancel'),
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.planService.deleteDetailfromClause(removeOrder).subscribe(response => {
              if (response) {
                NotificationService.success(null, this.translateService.instant("Deleted"));
                this.loadList();
              }
              else {
                NotificationService.error(null, "Error");
              }
            });
          }
        });
      } else {
        NotificationService.error(null, this.translateService.instant("Error"));
        return;
      }
      this.spinner = false;
    }
  }
  dragDropped(event: CdkDragDrop<string[]>) {
    this.isPointerOverContainer = event.isPointerOverContainer;
    console.log("Pointer" + event.isPointerOverContainer);
  }

  getConnectedList(): any[] {
    return this.groups.map(x => `${x.id}`);
  }

  dropGroup(event: CdkDragDrop<any[]>) {
    if (this.isPointerOverContainer) {
      moveItemInArray(this.groups, event.previousIndex, event.currentIndex);
      let newOrder = event.container.data;
      this.planService.saveClauseSortOrder(newOrder).subscribe();
    }
    else {
      let removeClause = event.item.data;
      if (event.item.data != undefined) {
        Swal.fire({
          title: this.translateService.instant("Delete") + "?",
          text: this.translateService.instant("You won't be able to revert this!"),
          icon: 'warning',
          confirmButtonColor: '#01304A',
          cancelButtonColor: 'red',
          confirmButtonText: this.translateService.instant('Confirm'),
          cancelButtonText: this.translateService.instant('Cancel'),
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.planService.deleteClause(removeClause).subscribe(response => {
              if (response) {
                NotificationService.success(null, this.translateService.instant("Deleted"));
                this.loadList();
              }
              else {
                NotificationService.error(null, "Error");
              }
            });
          }
        });
      } else {
        NotificationService.error(null, this.translateService.instant("Error"));
        return;
      }
    }

  }
  public IsLocked() {
    let result = false;
    if (this.groups != null) {
      let locks = this.groups.filter(x => x.year == this.year);
      if (locks != null && locks.length > 0) {
        result = locks[0].lock;
      }
    }
    return result;
  }
  public ShowLockButton() {
    if (GlobalVariablesService.IsPPGAdmin()) {
      return true;
    } else {
      return false;
    }
  }


  public Lock() {

    if (this.groups != undefined || this.groups.length > 0) {
      Swal.fire({
        title: this.translateService.instant("Lock the plan list?"),
        text: this.translateService.instant("You won't be able to revert this!"),
        icon: 'warning',
        confirmButtonColor: '#01304A',
        cancelButtonColor: 'red',
        confirmButtonText: this.translateService.instant('Confirm'),
        cancelButtonText: this.translateService.instant('Cancel'),
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          if (!this.IsLocked()) {
            this.spinner = true;
            this.clauseHeader.lock = true;
            this.clauseHeader.year = this.year;
            this.planService.saveHeader(this.clauseHeader).subscribe(response => {
              if (response) {
                NotificationService.success(null, this.translateService.instant("Done"));
                this.loadList();
              } else {
                NotificationService.error(null, this.translateService.instant("Can't lock due to missing information"));
                return;
              }
            });
            this.spinner = false;

          }
        }
      });
    }
  }
}





