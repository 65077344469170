import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { ServiceCallResult } from './../../../core/models/service-call-result';

import { TrainingModel } from './../models/training.model';
import { CustomerTrainingModel } from './../models/customer-training.model';
import { TrainingHeaderModel } from '../models/training-header.model';

@Injectable({
    providedIn: 'root'
})
export class TrainingService {

    constructor(private http: HttpClient) { }

    public GetAllTrainings(data: TrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/GetAllTrainings", data).pipe(map(response => response));
    }

    public GetAllCustomerTrainings(data: CustomerTrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/GetAllCustomerTrainings", data).pipe(map(response => response));
    }

    public GetTrainingHeader(data: TrainingHeaderModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/GetTrainingHeader", data).pipe(map(response => response));
    }

    public CreateUpdate(data: TrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/CreateUpdate", data).pipe(map(response => response));
    }

    public CreateUpdateCustomerTraining(data: CustomerTrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/CreateUpdateCustomerTraining", data).pipe(map(response => response));
    }

    public CreateUpdateTrainingHeader(data: TrainingHeaderModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/CreateUpdateTrainingHeader", data).pipe(map(response => response));
    }

    public Delete(requirement: TrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/Delete", requirement).pipe(map(response => response));
    }

    public DeleteCustomerTraining(requirement: CustomerTrainingModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Training/DeleteCustomerTraining", requirement).pipe(map(response => response));
    }
}