import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { environment } from '../../../../environments/environment';
import { BusinessPlanClauseDetailModel } from '../models/business-plan-clause-detail.model';
import { BusinessPlanClauseModel } from '../models/business-plan-clause.model';
import { CustomerClauseHeaderModel } from '../models/customer-clause-header.model';
import { CustomerClauseSelectionModel } from '../models/customer-clause-selection.model';

@Injectable({
  providedIn: 'root'
})
export class AnnualBusinessPlanService {

  constructor(private http: HttpClient) { }

  public getAll(year: number): Observable<BusinessPlanClauseModel[]> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}businessplan/get-all/${year}`).pipe(map(response => response.result));
  }
  public saveDetail(detail: BusinessPlanClauseDetailModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/save-detail`, detail, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public saveHeader(detail: BusinessPlanClauseModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/save-header`, detail, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public addDetail(clause: BusinessPlanClauseModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/add-detail`, clause, httpOptions).pipe(
      map(response => response.result)
    );
  }
  public addClause(clause: BusinessPlanClauseModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/add-clause`, clause, httpOptions).pipe(
      map(response => response.result)
    );
  }
  public saveDetailSortOrder(details: BusinessPlanClauseDetailModel[]): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/detail-sort`, details, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public deleteDetailfromClause(details: BusinessPlanClauseDetailModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/delete-detail`, details, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public deleteClause(clause: BusinessPlanClauseModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/delete-clause`, clause, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public saveClauseSortOrder(clauses: BusinessPlanClauseModel[]): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/clause-sort`, clauses, httpOptions).pipe(
      map(response => response.result)
    );
  }
  public moveDetailToNewClause(clauses: BusinessPlanClauseModel[]): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/move-detail`, clauses, httpOptions).pipe(
      map(response => response.result)
    );
  }
  public getClausesForSelection(ctsId: number, year: number): Observable<CustomerClauseHeaderModel> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}businessplan/get-clauses-for-selection/${ctsId}/${year}`).pipe(
      map(response => response.result)
    );
  }
  public saveCustomerSelection(selections: CustomerClauseSelectionModel[]): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}businessplan/save-customer-selection`, selections, httpOptions).pipe(
      map(response => response.result)
    );
  }

  public getClausesForAbp(ctsId: number, year: number): Observable<CustomerClauseHeaderModel> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}businessplan/get-clauses-for-abp/${ctsId}/${year}`).pipe(
      map(response => response.result)
    );
  }
}
