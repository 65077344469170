<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <br>
    <h3 class="center">{{title|translate}}</h3>
    <br> <br> <br> <br>
    <div style="margin-left:200px;" class="center">
        <div class="row">
            <!-- <div class="col">
            <mat-form-field class="yearInput">
                <mat-label>Year</mat-label>
                <mat-select [(value)]="year" (selectionChange)="LoadData()">
                    <mat-option *ngFor="let year of years" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
            <div class="col-2">
                <mat-form-field>
                    <mat-label>{{"Rebate %"|translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="percentage">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>{{"Min. Consecution ABP %"|translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="minConsecutionAbp">
                </mat-form-field>
            </div>
            <div class="col-1">
                <button mat-button  class="btn btnPPG1" (click)="CreateUpdate()"
                    [disabled]="!enableSave">
                    <span class="material-icons">save</span><br />
                </button>
            </div>
        </div>
    </div>
    <!-- <div>
        <mat-label>{{"Rebate Details"|translate}}</mat-label>
        <textarea [(ngModel)]="detail" (change)="Validator()"></textarea>
    </div> -->
</div>