<div id="print-section" class="container">
    <br>
    <div *ngIf="(requirementTypeId == 1) && !IsCalculator">
        <hr />
        <h3>{{"Instructions"|translate}}:</h3><br />
        <p>{{instructions|translate}}</p><br />
        <hr />
    </div>
    <div class="overflowAuto">
        <h6 *ngIf="IsCalculator">{{GetRequirementTittle()}}</h6>
        <table mat-table [dataSource]="requirementsMet" class="approvals mat-elevation-z8">
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>{{"Requirement"|translate}}</th>
                <td mat-cell *matCellDef="let item"> {{item.description}} </td>
            </ng-container>
            <ng-container matColumnDef="met" *ngIf="IsValidColumn('met')">
                <th mat-header-cell *matHeaderCellDef>{{"Met"|translate}}</th>
                <td mat-cell *matCellDef="let item">
                    <mat-checkbox [checked]="item.met" (click)="CreateUpdateMet(item)"
                        [disabled]="!IsAdmin || IsReadOnly">
                    </mat-checkbox>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="GetRequirementsColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: GetRequirementsColumns();"></tr>
        </table>
        <hr />
    </div>
</div>
<app-spinner *ngIf="spinner"></app-spinner>