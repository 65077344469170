<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <div class="row">
    <div class="col-md-6 col-lg-6 B h-50">
      <div class="row d-flex flex-column h-100 border bg-light">
        <b>CAR PREMIUM</b>
        <div>
          <table style="width: 95%;" mat-table [dataSource]="premiumLines" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let premiumLine">
                <mat-checkbox (change)="$event ? premiumLineSelect(premiumLine) : null"
                  [checked]="premiumSelection.isSelected(premiumLine)" [disabled]="IsReadOnly" >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="segment">
              <th mat-header-cell *matHeaderCellDef>{{"Segment"| translate}}</th>
              <td mat-cell *matCellDef="let premiumLine">{{ premiumLine.brandName }}</td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>>
          </table>
        </div>
      </div>
      <div class="row d-flex flex-column h-100 border bg-light">
        <h3>&nbsp;</h3>
        <div class="col"
          *ngIf="premiumSelection?.isSelected(premiumLines[0]) || premiumSelection?.isSelected(premiumLines[1])">
          <b>CT</b>
        </div>
        <div>
          <table style="width: 95%" mat-table [dataSource]="ctLines" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let ctLine">
                <mat-checkbox (change)="$event ? ctSelection.toggle(ctLine) : null"
                  [checked]="ctSelection.isSelected(ctLine)" [disabled]="IsReadOnly">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="segment">
              <th mat-header-cell *matHeaderCellDef>{{"Segment"| translate}}</th>
              <td mat-cell *matCellDef="let ctLine">{{ ctLine.brandName }}</td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>>
          </table>
        </div>
        <div>
          <h3>&nbsp;</h3>
        </div>
        <div class="col">
          <div *ngFor="let line of licLines">
            <mat-checkbox #checkbox (change)="onLicClick(checkbox)" [checked]="line.selected" [disabled]="IsReadOnly">LIC (Selemix)
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 B h-50 col-ml-1">
      <div class="row d-flex flex-column h-100 border bg-light">
        <b>CAR VALUE</b>
        <div>
          <table style="width: 95%;" mat-table [dataSource]="valueLines" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let valueLine">
                <mat-checkbox (change)="$event ? valueSelection.toggle(valueLine) : null"
                  [checked]="valueSelection.isSelected(valueLine)" [disabled]="IsReadOnly">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="segment">
              <th mat-header-cell *matHeaderCellDef>Segment</th>
              <td mat-cell *matCellDef="let valueLine">{{ valueLine.brandName }}</td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>>
          </table>
        </div>
        <div class="col">
          <mat-label>{{"Link to:"|translate}}</mat-label>
        </div>
        <div class="col">
          <mat-select style="width:150px" [value]="this.selectValueSegmentId"
            (valueChange)="onValueLineLinkChange($event)">
            <mat-option style="width:100px" *ngFor="let line of premiumSelection.selected" [value]="line.brandId">
              {{line.brandName}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row d-flex flex-column h-100 border bg-light">
        <h3>&nbsp;</h3>
        <b>CAR VALUE COLOR</b>
        <div>
          <table style="width: 95%;" mat-table [dataSource]="colorLines" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let colorLine">
                <mat-checkbox (change)="$event ? colorSelection.toggle(colorLine) : null"
                  [checked]="colorSelection.isSelected(colorLine)" [disabled]="IsReadOnly">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="segment">
              <th mat-header-cell *matHeaderCellDef>Segment</th>
              <td mat-cell *matCellDef="let colorLine">{{ colorLine.brandName }}</td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>>
          </table>
        </div>
      </div>
    </div>
    <button mat-raised-button (click)="onClick($event)" [disabled]="IsReadOnly">{{"Save"|translate}}</button>
  </div>