<div class="container">
  <div *ngIf="this.brandTarget?.brandTargets === null">
    <b>{{"Please select brands on the home page for this Distributor"|translate}}.</b>
  </div>
  <div *ngFor="let target of this.brandTarget?.brandTargets">
    <div *ngIf="target.salesTargets!== null">
      <div>&nbsp;</div>
      <app-sales-goal-entry [salesTargets]="target?.salesTargets" IsReadOnly="{{IsReadOnly}}"></app-sales-goal-entry>
    </div>
    <div *ngIf="target.volumeTargets !== null">
      <div>&nbsp;</div>
      <app-volume-goal-entry *ngIf="target?.volumeTargets !== null" [volumeTargets]="target.volumeTargets"
        IsReadOnly="{{IsReadOnly}}">
      </app-volume-goal-entry>
    </div>
  </div>
</div>