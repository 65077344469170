export class RebateLevelModel {
  public isGroupBy: boolean;
  public ctsId: number;
  public year:number;
  public rebate: number;
  public hvlColor: number;
  public consecutionLevel: number;
  public segment: string;
  public brand: string;
  public segmentId: number;
  public brandId: number;
  public excludeFromRebate: boolean;
  public saveLinesFlag:boolean;
}
