import { MarketingModel } from '../models/marketing.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { environment } from '../../../../environments/environment';
import { NumberFormatService } from './../../../core/services/number-format-service';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  constructor(private http: HttpClient) { }

  public async GetMarketingOptions(request: MarketingModel): Promise<Observable<MarketingModel[]>> {
    var options = await (await this.getMarketingOptionsResponse(request)).toPromise();
    for (let i = 0; i < options.length; i++) {
      options[i].annualValueFormatted = NumberFormatService.numberWithCommas(options[i].annualValue.toString());
    }
    return of(options);
  }
  private async getMarketingOptionsResponse(request: MarketingModel): Promise<Observable<MarketingModel[]>> {
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}marketing/get-marketingoptions`, request).
      pipe(
        map(response => response = response.result)
      );
  }

  public CreateUpdate(request: MarketingModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Marketing/create-update", request).pipe(map(response => response.result));

  }
  public Delete(request: MarketingModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Marketing/delete", request).pipe(map(response => response.result));
  }

}