import { BusinessPlanClauseDetailModel } from './business-plan-clause-detail.model';

export class BusinessPlanClauseModel {
  public id: number;
  public year:number;
  public sortOrder: number;
  public name: string;
  public lock:boolean;
  public details: BusinessPlanClauseDetailModel[] = [];
}

