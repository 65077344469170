<div class="container">
  <div *ngFor="let target of this.brandTarget?.brandTargets">
    <div *ngIf="target.salesTargets">
      <mat-table #table [dataSource]="target.salesTargets" class="mat-elevation-z8">
        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="segment">
          <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.target }}</mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ getSalesTotal(target.salesTargets) }}</mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="salesDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: salesDisplayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="salesDisplayedColumns"></mat-footer-row>
      </mat-table>
    </div>
    <div *ngIf="target.volumeTargets">
      <mat-table [dataSource]="target?.volumeTargets" class="mat-elevation-z8">
        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
          <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="segment">
          <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="clearcoats">
          <mat-header-cell *matHeaderCellDef>{{"Clearcoats"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.clearcoatTarget }}</mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ getClearcoatsTotal(target.volumeTargets)|number:'1.2-2':languageCultureCode }}</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="primers">
          <mat-header-cell *matHeaderCellDef>{{"Primers"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ target.primerTarget }}</mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ getPrimersTotal(target.volumeTargets)|number:'1.2-2':languageCultureCode }}</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="totals">
          <mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let target">{{ (target.primers*1 +target.clearcoats*1)|number:'1.2-2':languageCultureCode }} </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ getVolumeTotal(target.volumeTargets)|number:'1.2-2':languageCultureCode}}</mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="volumeDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: volumeDisplayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="volumeDisplayedColumns"></mat-footer-row>
      </mat-table>
    </div>
  </div>
</div>
