import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { RequirementService } from '../../services/requirement.service';
import { NotificationService } from './../../../../core/services/notification-service';

import { RequirementHeaderModel } from './../../models/requirement-header.model';
import { RequirementMetModel } from '../../models/requirement-met.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-requirement-form',
  templateUrl: './requirement-form.component.html',
  styleUrls: ['./requirement-form.component.scss']
})

export class RequirementFormComponent implements OnInit {
  public spinner = false;
  @Input() requirementTypeId: number = 0;
  @Input() IsReadOnly: Boolean = false;
  @Input() IsCalculator: Boolean = false;
  @Output() metAllRequirements = new EventEmitter<boolean>();
  public year: number = null;
  public ctsId: number = null;
  public instructions: string = "";
  public requirementsMet: RequirementMetModel[];
  public IsAdmin = GlobalVariablesService.IsPPGAdmin();

  // public requirementsColumns: string[] = ['description'];
  public approvalColumns: string[] = ['firstName', 'lastName', 'emailAddress', 'userTypeName', 'date'];

  constructor(private route: ActivatedRoute, private translateService: TranslateService,
    private requirementService: RequirementService) {
  }

  ngOnInit() {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadRequirementsMet();
  }

  public LoadRequirementsMet() {
    if (this.ctsId > 0) {
      this.spinner = true;

      let requirementHeader = new RequirementHeaderModel();
      requirementHeader.requirementTypeId = this.requirementTypeId;
      requirementHeader.year = this.year;

      let requirementMet = new RequirementMetModel();
      requirementMet.requirementTypeId = this.requirementTypeId;
      requirementMet.year = this.year;
      requirementMet.ctsId = this.ctsId;

      this.requirementService.GetRequirementHeader(requirementHeader).subscribe(responseHeader => {
        if (responseHeader.result != null) {
          this.instructions = responseHeader.result.instructions;
        } else {
          this.instructions = null;
        }
        this.requirementService.GetRequirementsMet(requirementMet).subscribe(responseRequirements => {
          this.requirementsMet = responseRequirements.result;
          let validateRequirements = true;
          this.requirementsMet.forEach(x => {
            if (!x.met) {
              validateRequirements = false;
            }
          });
          this.metAllRequirements.emit(validateRequirements);
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public CreateUpdateMet(item) {
    if (this.IsAdmin && !this.IsReadOnly) {
      if (this.ctsId > 0) {
        this.spinner = true;
        let requirements: RequirementMetModel[] = [];
        let requirement = new RequirementMetModel();
        requirement.requirementId = item.requirementId;
        requirement.year = item.year;
        requirement.ctsId = item.ctsId;
        requirement.met = !item.met;
        requirements.push(requirement);
        this.requirementService.CreateUpdateMet(requirements).subscribe(response => {
          if (!response.success) {
            NotificationService.error(null, response.message);
          }
          this.LoadRequirementsMet();
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        NotificationService.error(null, this.translateService.instant("CTSID not selected."));
      }
    }
  }

  public GetRequirementsColumns() {
    if (this.IsCalculator) {
      return ['description', 'met'];
    } else {
      return ['description'];
    }
  }

  public IsValidColumn(column: string) {
    let columns = this.GetRequirementsColumns();
    if (column.indexOf(column) != -1) {
      return true;
    } else {
      return false;
    }
  }

  public GetRequirementTittle() {
    if (this.requirementTypeId == 1) {
      return "Elegibility Requirements";
    } else {
      return "Qualitative Actions";
    }
  }
}
