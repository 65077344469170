import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { RequirementService } from '../../services/requirement.service';

import { RequirementModel } from '../../models/requirement.model';
import { RequirementHeaderModel } from './../../models/requirement-header.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss']
})

export class RequirementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public spinner = false;
  public title: string = "Requirements";
  public requirementTypeId: number = 0;
  public year: number = 0;
  public years: number[] = [];
  public requirementHeader = new RequirementHeaderModel();
  public showAddCancel = false;

  public requirements: RequirementModel[];
  public requirementsFilter = new MatTableDataSource<RequirementModel>();
  public addrow: RequirementModel[];

  public sortOrder = new FormControl('', [Validators.required]);
  public description = new FormControl('', [Validators.required]);

  public addColumns = ["sortOrder", "description", "actions"];
  public requirementsColumns = ["sortOrder", "description", "actions"];

  constructor(private requirement_service: RequirementService, private route: ActivatedRoute, private translateService: TranslateService) {
    GlobalVariablesService.setCtsIdVisibility(false);
    this.title = route.root.firstChild.snapshot.data["title"];
    this.requirementTypeId = route.root.firstChild.snapshot.data["requirementTypeId"];
    this.year = GlobalVariablesService.getYear();
    this.years = GlobalVariablesService.getYears();
    this.LoadRequirements();
  }

  ngOnInit() {
    this.addrow = [new RequirementModel()];
  }

  ngAfterViewInit() {
    this.requirementsFilter.paginator = this.paginator;
  }

  public LoadRequirements(): void {
    let requirementHeader = new RequirementHeaderModel();
    requirementHeader.requirementTypeId = this.requirementTypeId;
    requirementHeader.year = this.year;

    let requirement = new RequirementModel();
    requirement.requirementTypeId = this.requirementTypeId;
    requirement.year = this.year;

    this.spinner = true;
    this.showAddCancel = false;
    this.requirement_service.GetRequirementHeader(requirementHeader).subscribe(responseHeader => {
      if (responseHeader.result != null) {
        this.requirementHeader = responseHeader.result;
      } else {
        this.requirementHeader.requirementTypeId = this.requirementTypeId;
        this.requirementHeader.year = this.year;
      }
      this.requirement_service.GetRequirementByYear(requirement).subscribe(responseRequirements => {
        this.requirements = responseRequirements.result;
        this.requirementsFilter.data = this.requirements;
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Lock() {
    if (this.requirementHeader.instructions.length > 0 ||
      this.requirements.length > 0) {
      Swal.fire({
        title: this.translateService.instant('Lock the requirement list?'),
        text: this.translateService.instant("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#01304A',
        cancelButtonColor: 'red',
        confirmButtonText: this.translateService.instant('Confirm')
      }).then((result) => {
        if (result.value) {
          if (!this.IsLocked()) {
            this.requirementHeader.lock = true;
            this.CreateUpdateHeader();
          }
        }
      });
    } else {
      NotificationService.error(null, this.translateService.instant("Can't lock due to missing information"));
      this.spinner = false;
      return;
    }
  }

  public CreateUpdateHeader() {
    this.spinner = true;
    this.requirement_service.CreateUpdateHeader(this.requirementHeader).subscribe(response => {
      if (response.success) {
        NotificationService.success(null, this.translateService.instant("Saved"));
        this.requirement_service.GetRequirementHeader(this.requirementHeader).subscribe(responseHeader => {
          this.requirementHeader = responseHeader.result;
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        NotificationService.error(null, response.message);
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.requirementsFilter.filter = filterValue.trim().toLowerCase();
  }

  public ShowAddCancel() {
    this.addrow = [new RequirementModel()];
    this.showAddCancel = !this.showAddCancel;
  }

  public getErrorMessage(element: string) {
    return 'Missing ' + element;
  }

  public CreateUpdate(requirement: RequirementModel) {
    if (!this.IsLocked()) {
      this.spinner = true;
      let requirements: RequirementModel[] = [];
      requirement.requirementTypeId = this.requirementTypeId;
      requirement.year = this.year;
      requirements.push(requirement);
      this.requirement_service.CreateUpdate(requirements).subscribe(response => {
        if (!response.success) {
          NotificationService.error(null, response.message);
          this.spinner = false;
        } else {
          NotificationService.success(null, this.translateService.instant("Saved"));
        }
        this.LoadRequirements();
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public DeleteRequirement(requirement: RequirementModel) {
    if (!this.IsLocked()) {
      this.spinner = true;
      this.requirement_service.Delete(requirement).subscribe(response => {
        if (response.success) {
          NotificationService.success(null, this.translateService.instant("Deleted"));
          this.LoadRequirements();
        } else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public IsLocked() {
    return this.requirementHeader.lock
  }

  public ShowLockButton() {
    if (GlobalVariablesService.IsPPGAdmin()) {
      return true;
    } else {
      return false;
    }
  }
}