import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from '../../../../core/services/notification-service';
import { TechnicalAssistanceModel } from '../../models/technical-assistance.model';
import { TechnicalAssistanceService } from '../../services/technical-assistance.service';

@Component({
  selector: 'app-technical-assistance-display',
  templateUrl: './technical-assistance-display.component.html',
  styleUrls: ['./technical-assistance-display.component.scss']
})

export class TechnicalAssistanceDisplayComponent implements OnInit {
  public title: string = "Technical Assistance";
  public year: number = 0;
  public ctsId: number;
  public showAddCancel = false;
  public technicalAssistance: TechnicalAssistanceModel[];
  public technicalAssistanceFilter = new MatTableDataSource<TechnicalAssistanceModel>();
  public addrow: TechnicalAssistanceModel[];
  public technicalAssistanceColumns = ["startDate", "endDate", "technician", "annualAmount", "periodicity", "detail"];
  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();
  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();

  constructor(private technicalAssistance_service: TechnicalAssistanceService, private route: ActivatedRoute) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadData();
  }

  ngOnInit(): void {
  }

  public LoadData(): void {
    let request = new TechnicalAssistanceModel();
    request.year = this.year;
    request.ctsId = this.ctsId;
    this.showAddCancel = false;
    this.technicalAssistance_service.GetAllTechnicalAssistance(request).subscribe(response => {
      this.technicalAssistance = response.result;
      this.technicalAssistanceFilter.data = this.technicalAssistance;
    }, error => {
      console.log(error);
      NotificationService.error(null, "An Error Ocurred");
    });
  }
}