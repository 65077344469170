<app-spinner *ngIf="spinner"></app-spinner>
<div class="container" *ngIf="visible; else notApproved">
    <mat-tab-group>
        <mat-tab label="Calculator">
            <ng-template matTabContent>
                <app-calculator></app-calculator>
            </ng-template>
        </mat-tab>
        <mat-tab label="End Of Year Calculator" *ngIf="eoyCalculatorSettings.enabled || IsPPGAdmin">
            <ng-template matTabContent>
                <app-calculator-eof></app-calculator-eof>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #notApproved class="container">
    {{ "Annual Business Plan has not been approved for this year. Please select a different year." | translate}}
</ng-template>