<table mat-table [dataSource]="targetAccountFilter" class="data_grid">
    <ng-container matColumnDef="targetCtsId">
        <th mat-header-cell *matHeaderCellDef>{{"CTS Id"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.targetCtsId }}
        </td>
    </ng-container>
    <ng-container matColumnDef="shopName">
        <th mat-header-cell *matHeaderCellDef>{{"Company"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.shopName }}
        </td>
    </ng-container>
    <ng-container matColumnDef="shopGrossSales">
        <th mat-header-cell *matHeaderCellDef>{{"Shop Gross Sales"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.shopGrossSales | number:'0.1-2' : languageCultureCode }}
        </td>
    </ng-container>
    <ng-container matColumnDef="potentialPurchases">
        <th mat-header-cell *matHeaderCellDef>{{"Potential Purchases"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.potentialPurchases | number:'0.1-2' : languageCultureCode }}
        </td>
    </ng-container>
    <ng-container matColumnDef="targetDate">
        <th mat-header-cell *matHeaderCellDef>{{"Target Date"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.targetDate | DateFormatPipe }}
        </td>
    </ng-container>
    <ng-container matColumnDef="competitor">
        <th mat-header-cell *matHeaderCellDef>{{"Competitor"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.competitor }}
        </td>
    </ng-container>
    <ng-container matColumnDef="numberPainters">
        <th mat-header-cell *matHeaderCellDef># {{"Painters"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.numberPainters }}
        </td>
    </ng-container>
    <ng-container matColumnDef="planDetails">
        <th mat-header-cell *matHeaderCellDef>{{"Plan Details"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{ item.planDetails }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="targetAccountsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: targetAccountsColumns;"></tr>
</table>