import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxPrintModule } from 'ngx-print';
import { GridModule } from '@progress/kendo-angular-grid';
import { AngularMaterialModule } from './../../core/angular-material.module';
import { CoreModule } from './../../core/core.module';

import { RequirementComponent } from './pages/requirement/requirement.component';
import { RequirementFormComponent } from './pages/requirement-form/requirement-form.component';
import { RebateMaintenanceComponent } from './pages/rebate-maintenance/rebate-maintenance.component';
import { CustomerRebateComponent } from './pages/customer-rebate/customer-rebate.component';
import { DevPlanComponent } from './pages/dev-plan/dev-plan.component';
import { DevPlanUserComponent } from './pages/devplan-user/devplan-user.component';
import { CustomerDevPlanComponent } from './pages/customer-dev-plan/customer-dev-plan.component';
import { ExcelUploadComponent } from './components/excel-upload/excel-upload.component';
import { RebateLevelComponent } from './components/rebate-level/rebate-level.component';
import { AnnualBusinessPlanComponent } from './pages/annual-business-plan/annual-business-plan.component';
import { BusinessPlanClauseDetailModalComponent } from './components/modals/business-plan-clause-detail-modal/business-plan-clause-detail-modal.component';
import { BusinessPlanClauseModalComponent } from './components/modals/business-plan-clause-modal/business-plan-clause-modal.component';
import { ClauseSelectionComponent } from './components/clause-selection/clause-selection.component';
import { SalesGoalEntryComponent } from './components/sales-goal-entry/sales-goal-entry.component';
import { VolumeGoalEntryComponent } from './components/volume-goal-entry/volume-goal-entry.component';
import { TargetEntryComponent } from './pages/target-entry/target-entry.component';
import { CustomerConsecutionComponent } from './components/customer-consecution/customer-consecution.component';
import { TrainingComponent } from './pages/training/training.component';
import { CustomerTrainingComponent } from './pages/customer-training/customer-training.component';
import { TargetAccountComponent } from './pages/target-account/target-account.component';
import { CustomerConsecutionDisplayComponent } from './components/customer-consecution-display/customer-consecution-display.component';
import { AnnualBusinessPlanDisplayComponent } from './pages/annual-business-plan-display/annual-business-plan-display.component';
import { BusinessPlanClauseDisplayComponent } from './components/business-plan-clause-display/business-plan-clause-display.component';
import { DynamicLoaderComponent } from './components/dynamic-loader/dynamic-loader.component';
import { FinancialGoalDisplayComponent } from './components/financial-goal-display/financial-goal-display.component';
import { VolumeGoalDisplayComponent } from './components/volume-goal-display/volume-goal-display.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { MarketingCustomerComponent } from './pages/marketing-customer/marketing-customer.component';
import { TechnicalAssistanceComponent } from './pages/technical-assistance/technical-assistance.component';
import { TargetDisplayComponent } from './components/target-display/target-display.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { ContractsModalComponent } from './components/modals/contracts-modal/contracts-modal.component';
import { AnnualBusinessPlanDataEntryComponent } from './pages/annual-business-plan-data-entry/annual-business-plan-data-entry.component';
import { MarketingDisplayComponent } from './components/marketing-display/marketing-display.component';
import { TranslateModule } from '@ngx-translate/core';
import { TechnicalAssistanceDisplayComponent } from './components/technical-assistance-display/technical-assistance-display.component';
import { TrainingDisplayComponent } from './components/training-display/training-display.component';
import { SalesTargetDisplayComponent } from './components/sales-target-display/sales-target-display.component';
import { VolumeTargetDisplayComponent } from './components/volume-target-display/volume-target-display.component';
import { TargetAccountDisplayComponent } from './components/target-account-display/target-account-display.component';
import { CustomerRebateDisplayComponent } from './components/customer-rebate-display/customer-rebate-display.component';
import { DevelopmentPlanDisplayComponent } from './components/development-plan-display/development-plan-display.component';
import { FollowUpComponent } from './pages/follow-up/follow-up.component';
import { CalculatorComponent } from './pages/calculator/calculator.component';
import { CalculatorMenuComponent } from './pages/calculator-menu/calculator-menu.component';
import { CalculatorEofComponent } from './pages/calculator-eof/calculator-eof.component';
import { MatMomentDateAdapterOptions, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [RequirementComponent, RequirementFormComponent, RebateMaintenanceComponent, CustomerRebateComponent,
    DevPlanComponent, CustomerDevPlanComponent, DevPlanUserComponent, ExcelUploadComponent, RebateLevelComponent,
    AnnualBusinessPlanComponent, BusinessPlanClauseDetailModalComponent, MarketingComponent, MarketingCustomerComponent,
    BusinessPlanClauseModalComponent, ClauseSelectionComponent, SalesGoalEntryComponent, VolumeGoalEntryComponent,
    TargetEntryComponent, CustomerConsecutionComponent, TrainingComponent, CustomerTrainingComponent, TargetAccountComponent, CustomerConsecutionDisplayComponent,
    AnnualBusinessPlanDisplayComponent, BusinessPlanClauseDisplayComponent, DynamicLoaderComponent, FinancialGoalDisplayComponent, VolumeGoalDisplayComponent,
    TechnicalAssistanceComponent, TargetDisplayComponent, ContractsComponent, ContractsModalComponent, AnnualBusinessPlanDataEntryComponent, MarketingDisplayComponent,
    TechnicalAssistanceDisplayComponent, BusinessPlanClauseDetailModalComponent, BusinessPlanClauseModalComponent, TrainingDisplayComponent, SalesTargetDisplayComponent,
    VolumeTargetDisplayComponent, TargetAccountDisplayComponent, CustomerRebateDisplayComponent, DevelopmentPlanDisplayComponent, FollowUpComponent, CalculatorComponent, CalculatorMenuComponent, CalculatorEofComponent],
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    GridModule,
    CoreModule,
    NgxPrintModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  entryComponents: [BusinessPlanClauseModalComponent, BusinessPlanClauseDetailModalComponent]
})
export class RebateModule { }
