<div class="container">
  <br> 
<div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="clauseSelection.hasValue() && isAllSelected()"
                      [indeterminate]="clauseSelection.hasValue() && !isAllSelected()"
                      [disabled]="IsReadOnly">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let clause">
        <mat-checkbox (change)="$event ? clauseSelection.toggle(clause) : null"
          [checked]="clauseSelection.isSelected(clause)"
          [disabled]="IsReadOnly">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="sortOrder">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let clause">{{ clause.sortOrder }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let clause"><pre class="pre-tag">{{ clause.description }}</pre></td>
    </ng-container>

    <mat-header-row style="border-style: solid ; border-width: 1px; background-color: white !important;" *matHeaderRowDef="displayedColumns"></mat-header-row>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-container matColumnDef="groupHeader">
      <td colspan="3" mat-cell *matCellDef="let groupBy"><strong>{{ groupBy.description }}</strong></td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
  </table>
</div>
<br>
<button mat-raised-button (click)="onSaveClick($event)" [disabled]="IsReadOnly">{{"Save"|translate}}</button>
</div>
