import { environment } from '../../../environments/environment';

export class GlobalVariablesService {
  private static CtsId: number;
  private static DistributorName: string;
  private static Authenticated: boolean = false;
  private static CtsIdVisibility: boolean = true;
  private static Year: number = 0;
  private static UserId: number;
  private static UserTypeId: number;
  private static UserTypeName: string;
  private static EmailAddress: string;
  private static LanguageId: number;
  private static LanguageCultureCode: string;
  private static UserTypePermissions: any[] = [];
  private static UserUniqueId: string;

  public static getCtsId() {
    if (sessionStorage.getItem("CTSID") != "") GlobalVariablesService.CtsId = Number(sessionStorage.getItem("CTSID"));
    return GlobalVariablesService.CtsId;
  }

  public static setCtsId(value: number) {
    GlobalVariablesService.CtsId = value;
    sessionStorage.setItem("CTSID", String(value));
  }

  public static IsAuthenticated() {
    if (sessionStorage.getItem("Authenticated") == "true") {
      GlobalVariablesService.Authenticated = true;
    } else {
      GlobalVariablesService.Authenticated = false;
    }
    return GlobalVariablesService.Authenticated;
  }

  public static setAuthentication(value: boolean) {
    GlobalVariablesService.Authenticated = value;
    sessionStorage.setItem("Authenticated", String(value));
  }

  public static getDistributorName() {
    if (sessionStorage.getItem("DistributorName") != "") GlobalVariablesService.DistributorName = sessionStorage.getItem("DistributorName");
    return GlobalVariablesService.DistributorName;
  }

  public static setDistributorName(value: string) {
    GlobalVariablesService.DistributorName = value;
    sessionStorage.setItem("DistributorName", value);
  }

  public static showCtsIdVisibility() {
    return GlobalVariablesService.CtsIdVisibility;
  }

  public static setCtsIdVisibility(value: boolean) {
    GlobalVariablesService.CtsIdVisibility = value;
  }

  public static getYear() {
    if (sessionStorage.getItem("Year") != "") GlobalVariablesService.Year = Number(sessionStorage.getItem("Year"));
    return GlobalVariablesService.Year;
  }

  public static setYear(value: number) {
    GlobalVariablesService.Year = value;
    sessionStorage.setItem("Year", String(value));
  }

  public static getUserId() {
    if (sessionStorage.getItem("UserId") != "") GlobalVariablesService.UserId = Number(sessionStorage.getItem("UserId"));
    return GlobalVariablesService.UserId;
  }

  public static getUserTypeId() {
    if (sessionStorage.getItem("UserTypeId") != "") GlobalVariablesService.UserTypeId = Number(sessionStorage.getItem("UserTypeId"));
    return GlobalVariablesService.UserTypeId;
  }

  public static getUserTypeName() {
    if (sessionStorage.getItem("UserTypeName") != "") GlobalVariablesService.UserTypeName = sessionStorage.getItem("UserTypeName");
    return GlobalVariablesService.UserTypeName;
  }

  public static getEmailAddress() {
    if (sessionStorage.getItem("EmailAddress") != "") GlobalVariablesService.EmailAddress = sessionStorage.getItem("EmailAddress");
    return GlobalVariablesService.EmailAddress;
  }

  public static getLanguageId() {
    if (sessionStorage.getItem("LanguageId") != "") GlobalVariablesService.LanguageId = Number(sessionStorage.getItem("LanguageId"));
    return GlobalVariablesService.LanguageId;
  }

  public static getLanguageCultureCode() {
    if (sessionStorage.getItem("LanguageCultureCode") != "") GlobalVariablesService.LanguageCultureCode = sessionStorage.getItem("LanguageCultureCode");
    return GlobalVariablesService.LanguageCultureCode;
  }

  public static getUserTypePermissions() {
    if (sessionStorage.getItem("UserTypePermissions") != "") GlobalVariablesService.UserTypePermissions = JSON.parse(sessionStorage.getItem("UserTypePermissions"));
    return GlobalVariablesService.UserTypePermissions;
  }

  public static getNetworkOrAwareID() {
    if (sessionStorage.getItem("Profile") != "") {
      var logOnId = JSON.parse(sessionStorage.getItem("Profile")).unique_name;
      if (typeof logOnId === 'string'){
        console.log(logOnId);
        return logOnId;
      } else {
        console.log(logOnId);
        return logOnId[0];
      }
     
    }
  }

  public static getEnvironment() {
    return environment;
  }

  public static getYears() {
    var currentYear = new Date().getFullYear();
    var years: number[] = [];
    var startYear = currentYear - 1;
    for (var i = startYear; i <= currentYear; i++) {
      years.push(startYear++);
    }
    years.sort((a, b) => b - a);
    return years;
  }

  public static IsPPGAdmin() {
    if (this.getUserTypeName().includes("PPG_PLATINUM_PROGRESS_ADMIN")) {
      return true;
    } else {
      return false;
    }
  }

  public static IsPPGGuest() {
    if (this.getUserTypeName().includes("PPG_GUEST")) {
      return true;
    } else {
      return false;
    }
  }

  public static IsPlatinumProgress() {
    if (this.getUserTypeName() == "PLATINUM_PROGRESS") {
      return true;
    } else {
      return false;
    }
  }

  public static IsDistributorAdmin() {
    if (this.getUserTypeName() == "DISTRIBUTOR_ADMIN") {
      return true;
    } else {
      return false;
    }
  }
  
  public static IsDistributorUser() {
    if (this.getUserTypeName() == "DISTRIBUTOR_USER") {
      return true;
    } else {
      return false;
    }
  }

  public static IsTM() {
    if (this.getUserTypeName().includes("PPG_TM")) {
      return true;
    } else {
      return false;
    }
  }

  public static IsRM() {
    if (this.getUserTypeName().includes("PPG_RM")) {
      return true;
    } else {
      return false;
    }
  }

  public static IsZD() {
    if (this.getUserTypeName().includes("PPG_ZD")) {
      return true;
    } else {
      return false;
    }
  }
}
