import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { CustomerService } from './../../../customer/services/customer.service';
import { TrainingService } from './../../services/training.service';

import { TrainingModel } from './../../models/training.model';
import { CustomerTrainingModel } from './../../models/customer-training.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-training',
  templateUrl: './customer-training.component.html',
  styleUrls: ['./customer-training.component.scss']
})

export class CustomerTrainingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() IsReadOnly: Boolean = false;

  public spinner = false;
  public title: string = "Training";

  public year: number = 0;
  public ctsId: number = null;

  public showAddCancel = false;

  public trainings: TrainingModel[] = [];

  public customerTrainings: CustomerTrainingModel[] = [];
  public trainingsFilter = new MatTableDataSource<CustomerTrainingModel>();
  public addrow: CustomerTrainingModel[];
  public targetDates: string[] = ["Trim 1", "Trim 2", "Trim 3", "Trim 4"];

  public shopCtsId = new FormControl('', [Validators.required]);
  public trainingId = new FormControl('', [Validators.required]);
  public training_year = new FormControl('', [Validators.required]);
  public name = new FormControl('', [Validators.required]);
  public targetDate = new FormControl('', [Validators.required]);

  public addColumns = ["name", "trainingId", "targetDate", "actions"];
  public trainingsColumns = ["name", "trainingId", "targetDate", "actions"];

  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();

  constructor(private route: ActivatedRoute,
    private training_service: TrainingService, private translateService: TranslateService,
    private customer_service: CustomerService) {
    this.year = GlobalVariablesService.getYear();
    this.ctsId = GlobalVariablesService.getCtsId();
    this.LoadCustomerTrainings();
  }

  ngOnInit(): void {
    this.addrow = [new CustomerTrainingModel()];
  }

  ngAfterViewInit() {
    this.trainingsFilter.paginator = this.paginator;
  }

  public LoadCustomerTrainings(): void {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.showAddCancel = false;
      let training = new TrainingModel();
      training.year = this.year;

      this.training_service.GetAllTrainings(training).subscribe(responseTrainings => {
        this.trainings = responseTrainings.result;
        let customerTraining = new CustomerTrainingModel();
        customerTraining.year = this.year;
        customerTraining.ctsId = this.ctsId;
        this.training_service.GetAllCustomerTrainings(customerTraining).subscribe(response => {
          this.customerTrainings = response.result;
          this.trainingsFilter.data = this.customerTrainings;
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
      this.spinner = false;
    }
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trainingsFilter.filter = filterValue.trim().toLowerCase();
  }

  public ShowAddCancel() {
    if (this.ctsId > 0) {
      this.addrow = [new CustomerTrainingModel()];
      this.showAddCancel = !this.showAddCancel;
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public getErrorMessage(element: string) {
    return this.translateService.instant('Missing')+" " + this.translateService.instant(element);
  }

  public CreateUpdate(training: CustomerTrainingModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      training.year = this.year;
      training.ctsId = this.ctsId;
      this.training_service.CreateUpdateCustomerTraining(training).subscribe(response => {
        if (!response.success) {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
        NotificationService.success(null, this.translateService.instant("Saved"));
        this.LoadCustomerTrainings();
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public Delete(training: CustomerTrainingModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      this.training_service.DeleteCustomerTraining(training).subscribe(response => {
        if (response.success) {
          NotificationService.success(null,this.translateService.instant("Deleted"));
          this.LoadCustomerTrainings();
        } else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }
}