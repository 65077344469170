<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <h3 class="center">{{title|translate}}</h3>
  <!-- <div class="row">
    <mat-form-field class="yearInput">
      <mat-label>Year</mat-label>
      <mat-select [(value)]="year" (selectionChange)="GetDevPlans()">
        <mat-option *ngFor="let year of years" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->

  <span style="color:red" *ngIf="!(year > 0)">{{"Choose a year to add/edit a Development Plan!!"|translate}}</span>

  <br>
  <button *ngIf="!addDevPlan" mat-button  (click)="AddDevPlan()"
    [disabled]="(IsLocked() || !(year > 0))">{{"Add Plan(s)"|translate}}
    <span class="material-icons">add_box</span>
  </button>
  <br> <br>
  <!-- Add Table-->
  <div *ngIf="addDevPlan">
    <table mat-table [dataSource]="addRow" class="add_grid mat-elevation-z8" width="100%">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{"Plan Description"|translate}}</th>
        <td mat-cell *matCellDef="let item">
          <mat-form-field class="form_field">
            <input [value]="item.description" [(ngModel)]="item.description" matInput
              placeholder='{{"AddPlan"|translate}}' type="text" required>
            <mat-error *ngIf="description.invalid">{{getErrorMessage("Description")|translate}}</mat-error>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef  style="width: 15%; align-self:center;">{{"Actions"|translate}}</th>
        <td mat-cell *matCellDef="let row" style="text-align:center;">
          <button mat-button  (click)="CreateUpdate(row)">
            <span class="material-icons">save</span>
          </button>
          <button *ngIf="addDevPlan" mat-button  (click)="AddDevPlan()"><span
              class="material-icons">cancel</span>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="addCols"></tr>
      <tr mat-row *matRowDef="let row; columns: addCols;"></tr>
    </table>
  </div>
  <br>

  <!-- Data Table-->
  <div>
    <mat-form-field>
      <mat-label>{{"Filter"|translate}}</mat-label>
      <input matInput (keyup)="Filter($event)">
    </mat-form-field>
    <br><br>
    <table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{"Plan Description"|translate}}</th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="form_field">
            <input [value]="element.description" [(ngModel)]="element.description" matInput
              placeholder="{{'Enter Plan Description'|translate}}" type="text" required
              [disabled]="(IsLocked() || !(year > 0))">
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 15%; align-self:center;">{{"Actions"|translate}}</th>
        <td mat-cell *matCellDef="let row" style="text-align:center;">
          <button mat-button (click)="CreateUpdate(row)" [disabled]="(IsLocked() || !(year > 0))">
            <span class="material-icons">save</span>
          </button>
          <button mat-button (click)="Delete(row)" [disabled]="(IsLocked() || !(year > 0))">
            <span class="material-icons">remove_circle</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
    <div>
      <button mat-button  class="btnPPG1" (click)="Lock()" *ngIf="ShowLockButton()"
        [disabled]="(IsLocked() || !(year > 0))">{{"Lock"|translate}}</button>
    </div>
  </div>
</div>