<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <h3 class="center">{{title|translate}}</h3>
  <br>
  <h4><u>{{this.distributorName}}</u></h4>
  <br>
  <mat-form-field>
    <mat-label>{{"Search"|translate}}..</mat-label>
    <input matInput (keyup)="SearchGrid($event)">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Status"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.status" class ="c-table__data-cell"  ngDefaultControl>
        {{element.status}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell"> {{"Type"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.type" class ="c-table__data-cell"  ngDefaultControl>
        {{element.type}}
      </td>
    </ng-container>

    <ng-container matColumnDef="subType">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Sub Type"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.subType" class ="c-table__data-cell"  ngDefaultControl>
        {{element.subType}}
      </td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Brand"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.brand" class ="c-table__data-cell"  ngDefaultControl>
        {{element.brand}}
      </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell date">{{"Start Date"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.startDate" class ="c-table__data-cell"  ngDefaultControl>
        {{element.startDate}}
      </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell date">{{"End Date"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.endDate" class ="c-table__data-cell" ngDefaultControl>
        {{element.endDate}}
      </td>
    </ng-container>
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell"> {{"Uploaded File"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.fileName" (click)="DownloadFile(element)" ngDefaultControl
         class="fileName">
        {{element.fileName}}    

    </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="c-table__header-cell" style="width: 8% !important;">{{"Actions"|translate}}</th>
      <td mat-cell *matCellDef="let element" class ="c-table__data-cell">
        <button mat-button  (click)="DeleteFile(element)" [disabled]="IsReadOnly" *ngIf="IsVisible(element)">
          <span class="material-icons" style="size: 2px;">remove_circle</span>
        </button>
        <button mat-button  (click)="UploadFile(element)" [disabled]="IsReadOnly" *ngIf="IsVisible(element)">
          <span class="material-icons" style="size: 2px;">cloud_upload</span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons>
  </mat-paginator>