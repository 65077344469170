import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2'

import { NotificationService } from './../../../../core/services/notification-service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { RebateService } from './../../services/rebate.service';
import { RebateModel } from '../../models/rebate.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rebate-maintenance',
  templateUrl: './rebate-maintenance.component.html',
  styleUrls: ['./rebate-maintenance.component.scss']
})

export class RebateMaintenanceComponent implements OnInit {
  public spinner: boolean = false;
  public title: string = "Rebate";
  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();
  public year: number;
  public years: number[] = [];
  public rebateTypeId: number = 1;

  public percentage: number = 0;
  public detail: string = "";
  public minConsecutionAbp: number = 0;


  public enableSave: boolean = false;

  constructor(private rebateService: RebateService, private route: ActivatedRoute, private translateService: TranslateService) {
    GlobalVariablesService.setCtsIdVisibility(false);
    this.rebateTypeId = route.root.firstChild.snapshot.data["rebateTypeId"];
    this.title = route.root.firstChild.snapshot.data["title"];
    this.years = GlobalVariablesService.getYears();
    this.year = GlobalVariablesService.getYear();
    this.LoadData();
  }

  ngOnInit() {
  }

  public LoadData() {
    try {
      this.spinner = true;
      const request = new RebateModel();
      request.rebateTypeId = this.rebateTypeId;
      request.year = this.year;
      this.rebateService.GetRebateByTypeId(request).subscribe(response => {
        if (response.success) {
          if (response.result != null) {
            this.detail = response.result.details;
            this.percentage = response.result.percentage;
            this.minConsecutionAbp = response.result.minConsecutionAbp;
          }
        }
        this.Validator();
        this.spinner = false;
      });
    } catch (e) {
      this.spinner = false;
    }
  }

  public CreateUpdate() {
    try {
      this.spinner = true;
      const request: RebateModel[] = [];
      const rebate = new RebateModel();
      rebate.details = "";
      rebate.percentage = this.percentage;
      rebate.year = this.year;
      rebate.minConsecutionAbp = this.minConsecutionAbp;
      rebate.rebateTypeId = this.rebateTypeId;
      request.push(rebate);
      this.rebateService.CreateUpdate(request).subscribe(response => {
        if (response.toString() == "Fail") {
          this.spinner = false;
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('Error Saving Data')
          });
        } else {
          NotificationService.success(null, this.translateService.instant("Saved"));
          this.LoadData()
        }
      });
    } catch (e) {
      this.spinner = false;
    }
  }

  public Validator() {
    if (this.year) {
      this.enableSave = true;
    } else {
      this.enableSave = false;
    }
  }
}
