<app-spinner *ngIf="spinner"></app-spinner>
<div class="col">
    <label>{{"Status"|translate}}: {{currentStatus|translate}}</label>
</div>
<br>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Target Purchases"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-target-entry [IsReadOnly]="IsReadOnly"></app-target-entry>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Eligibility Form"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-requirement-form requirementTypeId="1" [IsReadOnly]="IsReadOnly"></app-requirement-form>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Qualitative Actions"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-requirement-form requirementTypeId="2" [IsReadOnly]="IsReadOnly"></app-requirement-form>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Target Account"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-target-account [IsReadOnly]="IsReadOnly"></app-target-account>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Technical Assistance"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-technical-assistance [IsReadOnly]="IsReadOnly"></app-technical-assistance>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Development Plan"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-dev-plan *ngIf="IsPlatinumProgress"></app-customer-dev-plan>
        <app-devplan-user *ngIf="!IsPlatinumProgress" [IsReadOnly]="IsReadOnly"></app-devplan-user>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Training"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-training [IsReadOnly]="IsReadOnly"></app-customer-training>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Marketing"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-marketing-customer *ngIf="IsPlatinumProgress"></app-marketing-customer>
        <app-marketing *ngIf="!IsPlatinumProgress" [IsReadOnly]="IsReadOnly"></app-marketing>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Platinum Rebate - Exclusivity"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-rebate rebateTypeId="2" [IsReadOnly]="IsReadOnly"></app-customer-rebate>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Platinum Plus Rebate"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-rebate rebateTypeId="3" [IsReadOnly]="IsReadOnly"></app-customer-rebate>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Platinum Functional Rebate"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-rebate rebateTypeId="1" [IsReadOnly]="IsReadOnly"></app-customer-rebate>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Annual Rebate"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-customer-consecution [IsReadOnly]="IsReadOnly"></app-customer-consecution>
    </ng-template>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>{{"Customer Clauses"|translate}}</mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <app-clause-selection [IsReadOnly]="IsReadOnly"></app-clause-selection>
    </ng-template>
</mat-expansion-panel>
<br>
<div class="row" style="margin-left: 10px;">
    <div class="col-1" *ngIf="showApproveButton()">
        <button mat-button class="btnPPG1" (click)="CreateUpdate()">{{getApprovalButtonText()|translate}}</button>
    </div>
    <div class="col-2" *ngIf="showZDApprovalButton()">
        <button mat-button class="btnPPG1" (click)="CreateUpdate('Needs_ZD_Approval')">{{'ZD Approval Required'|translate}}</button>
    </div>
    <div class="col-1" *ngIf="showRejectButton()">
        <button mat-button class="btnPPG1" (click)="CreateUpdate('Rejected')">{{"Reject"|translate}}</button>
    </div>
    <div class="col-2" *ngIf="IsPPGAdmin">
        <mat-checkbox class="example-margin" [(ngModel)]="eoyCalculatorSettings.enabled"
            (change)="eoyCalculatorCheckBox()">{{"EoY Calculator"|translate}}
        </mat-checkbox>
    </div>
</div>
<br>
<table mat-table [dataSource]="abpApprovals" class="data_grid mat-elevation-z8">
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{"Status"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.status}}
        </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>{{"First Name"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.firstName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{"Last Name"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.lastName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="userTypeName">
        <th mat-header-cell *matHeaderCellDef>{{"User Type"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.userTypeName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{"Date"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.date}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="abpApprovalsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: abpApprovalsColumns;"></tr>
</table>