import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from '../../../core/services/global-variables.service';
import { ServiceCallResult } from '../../../core/models/service-call-result';

import { FollowUpModel } from './../models/follow-up.model';

@Injectable({
    providedIn: 'root'
})

export class FollowUpService {

    constructor(private http: HttpClient) { }

    public GetCustomerFollowUp(data: FollowUpModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "FollowUp/GetCustomerFollowUp", data).pipe(map(response => response));
    }
}