import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './../../../../core/services/notification-service';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { RebateLevelModel } from '../../models/rebate-level.model';
import { RebateLevelService } from '../../services/rebate-level.service';

@Component({
  selector: 'app-customer-consecution-display',
  templateUrl: './customer-consecution-display.component.html',
  styleUrls: ['./customer-consecution-display.component.scss']
})
export class CustomerConsecutionDisplayComponent implements OnInit {
  public gridData: RebateLevelModel[];
  displayedColumns = ['brand', 'segment', 'consecutionLevel', 'rebate'];
  premiumDisplayedColumns = ['brand', 'segment', 'consecutionLevel', 'rebate', 'car', 'volume'];
  addDisplayedColumns = ['segment', 'brand', 'consecutionLevel', 'rebate'];
  public showAddCancel = false;
  public addRow: RebateLevelModel[];
  public segmentId: number;
  public lineId: number;
  public premiumGridData: RebateLevelModel[];
  public ctsId: number;
  public year: number;
  public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();

  constructor(private rebateLevelService: RebateLevelService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId == 0) {
      NotificationService.warning("Error", this.translateService.instant("Select a CTS Id to continue!"))
    }
    else if (this.year == 0) {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    else {
      this.loadGrid();
    }
  }

  public loadGrid() {
    this.rebateLevelService.getRebatesForAbpByCtsId(this.ctsId, this.year).subscribe(x => {
      this.gridData = x.filter(x => {
        if ((x.segmentId != 3 || (x.segmentId == 3 && x.brandId ==3)) && x.excludeFromRebate == false) {
          return x;
        }
      });
      this.premiumGridData = x.filter(x => {
        if (x.segmentId == 3 && x.brandId != 3 && x.excludeFromRebate == false) {
          return x;
        }
      });
    });
  }
  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

}
