export class CustomerModel {
  ctsId: number;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;
  city: string;
  state: string;
  zoneDirector: string;
  territoryManager: string;
  intNumTechnicians: number;
  competitorBrand: string;
  intCustType: number;
  distributorCtsId: number;
  distributorAdmin: string;
}
