import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import Swal from 'sweetalert2';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TrainingService } from './../../services/training.service';

import { TrainingModel } from './../../models/training.model';
import { TrainingHeaderModel } from './../../models/training-header.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public spinner = false;
  public title: string = "Training Maintenance";

  public year: number = 0;
  public years: number[] = [];
  public showAddCancel = false;

  public trainings: TrainingModel[];
  public trainingHeader = new TrainingHeaderModel();
  public trainingsFilter = new MatTableDataSource<TrainingModel>();
  public addrow: TrainingModel[];

  public training_year = new FormControl('', [Validators.required]);
  public name = new FormControl('', [Validators.required]);

  public addColumns = ["name", "actions"];
  public trainingsColumns = ["name", "actions"];

  constructor(private training_service: TrainingService, private route: ActivatedRoute, private translateService: TranslateService) {
    GlobalVariablesService.setCtsIdVisibility(false);
    this.years = GlobalVariablesService.getYears();
    this.year = GlobalVariablesService.getYear();
    this.LoadTrainings();
  }

  ngOnInit(): void {
    this.addrow = [new TrainingModel()];
  }

  ngAfterViewInit() {
    this.trainingsFilter.paginator = this.paginator;
  }

  public LoadTrainings(): void {

    let training = new TrainingModel();
    training.year = this.year;

    this.spinner = true;
    this.showAddCancel = false;
    this.trainingHeader.year = this.year;
    this.training_service.GetTrainingHeader(this.trainingHeader).subscribe(responseHeader => {
      if (responseHeader.result != null) {
        this.trainingHeader = responseHeader.result;
      } else {
        this.trainingHeader.lock = false;
      }
      this.training_service.GetAllTrainings(training).subscribe(response => {
        this.trainings = response.result;
        this.trainingsFilter.data = this.trainings;
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trainingsFilter.filter = filterValue.trim().toLowerCase();
  }

  public ShowAddCancel() {
    this.addrow = [new TrainingModel()];
    this.showAddCancel = !this.showAddCancel;
  }

  public getErrorMessage(element: string) {
    return this.translateService.instant('Missing') + ' ' + this.translateService.instant(element);
  }

  public CreateUpdate(training: TrainingModel) {
    this.spinner = true;
    training.year = this.year;
    this.training_service.CreateUpdate(training).subscribe(response => {
      if (!response.success) {
        NotificationService.error(null, response.message);
        this.spinner = false;
      }
      NotificationService.success(null, this.translateService.instant("Saved"));
      this.LoadTrainings();
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Delete(training: TrainingModel) {
    this.spinner = true;
    this.training_service.Delete(training).subscribe(response => {
      if (response.success) {
        NotificationService.success(null, this.translateService.instant("Deleted"));
        this.LoadTrainings();
      } else {
        NotificationService.error(null, response.message);
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public CreateUpdateHeader() {
    this.spinner = true;
    this.trainingHeader.year = this.year;
    this.training_service.CreateUpdateTrainingHeader(this.trainingHeader).subscribe(response => {
      if (response.success) {
        this.training_service.GetTrainingHeader(this.trainingHeader).subscribe(responseHeader => {
          this.trainingHeader = responseHeader.result;
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        NotificationService.error(null, response.message);
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Lock() {
    if (this.trainings.length > 0) {
      Swal.fire({
        title: this.translateService.instant('Lock the training list?'),
        text: this.translateService.instant("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#01304A',
        cancelButtonColor: 'red',
        confirmButtonText: this.translateService.instant('Confirm')
      }).then((result) => {
        if (result.value) {
          if (!this.IsLocked()) {
            this.trainingHeader.lock = true;
            this.CreateUpdateHeader();
          }
        }
      });
    } else {
      NotificationService.error(null, this.translateService.instant("Can't lock due to missing information"));
      this.spinner = false;
      return;
    }
  }

  public IsLocked() {
    return this.trainingHeader.lock
  }

  public ShowLockButton() {
    if (GlobalVariablesService.IsPPGAdmin()) {
      return true;
    } else {
      return false;
    }
  }
}