<app-spinner *ngIf="spinner"></app-spinner>
<br>
<br>
<div class="container" *ngIf="visible; else notApproved">
    <div class="container">
        <!-- <button mat-button class="btnPPG1" (click)="generatePDF()">{{"Generate PDF"|translate}}</button> -->
        <a href="javascript:void(0);" (click)="generatePDF()" class="no-print">
            <span class="material-icons icon-display">print</span>
        </a>
        <br>
        <br>
        <h3 class="center">{{"Annual Business Plan Period"| translate}} {{year}}</h3>
        <br>
        <h6>{{customer.companyName}} ({{customer.ctsId}})</h6>
    </div>
    <br>
    <div #abpPrintSection id="abpPrintSection">
        <app-business-plan-clause-display></app-business-plan-clause-display>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                ________________________________________ <br>
                {{customer.zoneDirector}} <br>
                Regional Manager
            </div>
            <div class="col">
                ________________________________________ <br>
                {{customer.territoryManager}} <br>
                Territory Manager
            </div>
            <div class="col">
                ________________________________________ <br>
                {{customer.distributorAdmin}} <br>
                Distributor Administrator
            </div>
        </div>
    </div>
</div>
<ng-template #notApproved class="container">
    {{ "Annual Business Plan has not been approved for this year. Please select a different year." | translate}}
</ng-template>