export class MarketingModel { 
    public ctsId:number;   
    public typeOfAgreement: string; 
    public annualValue: number;   
    public year:number;
    public details: string;
    public createdDate: Date;
    public modifiedDate: Date;
    public modifiedBy: number;
    public annualValueFormatted: string;
}
