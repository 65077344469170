<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <br>
    <h3 class="center">{{title|translate}}</h3>
    <!-- <div class="row">
        <mat-form-field class="yearInput">
            <mat-label>Year</mat-label>
            <mat-select [(value)]="year" (selectionChange)="LoadRequirements()">
                <mat-option *ngFor="let year of years" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
    <div class="row" *ngIf="(requirementTypeId == 1)">
        <div class="col-sm-11">
            <mat-form-field class="instructionsInput">
                <textarea matInput placeholder="{{'Instructions'|translate}}"
                    [(ngModel)]="requirementHeader.instructions" [disabled]="(IsLocked() || !(year > 0))"></textarea>
            </mat-form-field>
            <button mat-button (click)="CreateUpdateHeader()"
                [disabled]="(IsLocked() || !(year > 0))">
                <span class="material-icons">save</span>
            </button>
        </div>
    </div>
    <div>
        <button *ngIf="!showAddCancel" mat-button (click)="ShowAddCancel()"
            [disabled]="(IsLocked() || !(year > 0))">
            <span class="material-icons">add_box</span>
        </button>
        <!-- Add Requirements Table-->
        <div *ngIf="showAddCancel">
            <table mat-table [dataSource]="addrow" class="addRequirement">
                <ng-container matColumnDef="sortOrder">
                    <th mat-header-cell *matHeaderCellDef>{{"Order"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.sortOrder" [(ngModel)]="item.sortOrder" matInput
                                [formControl]="sortOrder" type="number" required>
                            <mat-error *ngIf="sortOrder.invalid">{{getErrorMessage("Order")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>{{"Description"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <textarea matInput placeholder="{{'Description'|translate}}" [value]="item.description"
                                cdkTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5
                                [(ngModel)]="item.description"></textarea>
                            <mat-error *ngIf="description.invalid">{{getErrorMessage("Description")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button (click)="CreateUpdate(row)">
                            <span class="material-icons">save</span>
                        </button>
                        <button *ngIf="showAddCancel" mat-button (click)="ShowAddCancel()"><span
                                class="material-icons">cancel</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
            </table>
        </div>
        <br>
        <!-- Requirements Table-->
        <div>
            <mat-form-field>
                <mat-label>{{"Filter"|translate}}</mat-label>
                <input matInput (keyup)="Filter($event)" [disabled]="!(year > 0)">
            </mat-form-field>
            <table mat-table [dataSource]="requirementsFilter" class="requirements mat-elevation-z8">
                <ng-container matColumnDef="sortOrder">
                    <th mat-header-cell *matHeaderCellDef>{{"Order"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input matInput [value]="item.sortOrder" [(ngModel)]="item.sortOrder" type="number"
                                [disabled]="IsLocked()">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>{{"Description"|translate}}</th>
                    <td mat-cell *matCellDef="let item" class="textarea_description">
                        <mat-form-field class="form_field">
                            <textarea matInput placeholder="{{'Description'|translate}}" [value]="item.description"
                                cdkTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5
                                [(ngModel)]="item.description" [disabled]="IsLocked()"></textarea>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)" [disabled]="IsLocked()">
                            <span class="material-icons">save</span>
                        </button>
                        <button mat-button  (click)="DeleteRequirement(row)" [disabled]="IsLocked()">
                            <span class="material-icons">remove_circle</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="requirementsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: requirementsColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [disabled]="!(year > 0)">
            </mat-paginator>
        </div>
        <div>
            <button mat-button class="btnPPG1" (click)="Lock()" *ngIf="ShowLockButton()"
                [disabled]="(IsLocked() || !(year > 0))">{{"Lock"|translate}}</button>
        </div>
    </div>
</div>