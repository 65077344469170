import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { CustomerClauseHeaderModel } from '../../models/customer-clause-header.model';
import { AnnualBusinessPlanService } from '../../services/annual-business-plan.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-business-plan-clause-display',
  templateUrl: './business-plan-clause-display.component.html',
  styleUrls: ['./business-plan-clause-display.component.scss']
})
export class BusinessPlanClauseDisplayComponent implements OnInit {
  public spinner: boolean = false;
  public ctsId: number;
  public year: number
  public clauses: CustomerClauseHeaderModel;
  public headers: any[];

  constructor(private clauseService: AnnualBusinessPlanService, private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId == 0) {
      NotificationService.warning("Error", this.translateService.instant("Select a CTS Id to continue!"))
    }
    else if (this.year == 0) {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    else {
      this.loadClauses();
    }
  }

  ngOnInit(): void { }

  public loadClauses() {
    this.spinner = true;
    this.clauseService.getClausesForAbp(this.ctsId, this.year).subscribe(x => {
      this.spinner = false;
      this.clauses = x;
      this.headers = x.clauses;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public showHeaderDescription(description: string) {
    let response: boolean = true;
    if (description.includes('[Consecution Level]')
      || description.includes('[Sales Target]')
      || description.includes('[Volume Target]')
      || description.includes('[Marketing]')
      || description.includes('[Technical Assistance]')
      || description.includes('[Training]')
      || description.includes('[Target Accounts]')
      || description.includes('[Plus Rebate]')
      || description.includes('[Exclusivity Rebate]')
      || description.includes('[Functional Rebate]')
    ) {
      response = false;
    }
    return response;
  }
}