<div *ngFor="let target of this.brandTarget?.brandTargets">
    <div *ngIf="target.volumeTargets">
        <mat-table [dataSource]="target?.volumeTargets" class="mat-elevation-z8">
            <ng-container matColumnDef="brand">
                <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="segment">
                <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="clearcoats">
                <mat-header-cell *matHeaderCellDef>{{"Clearcoats"|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let target">{{ target.clearcoatTarget|number:'1.0-0':languageCultureCode }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getClearcoatsTotal(target.volumeTargets)|number:'1.0-0':languageCultureCode }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="primers">
                <mat-header-cell *matHeaderCellDef>{{"Primers"|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let target">{{ target.primerTarget|number:'1.0-0':languageCultureCode }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getPrimersTotal(target.volumeTargets)|number:'1.0-0':languageCultureCode }}</mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="totals">
                <mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let target">{{ ((target.primerTarget * 1) + (target.clearcoatTarget * 1))|number:'1.0-0':languageCultureCode }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getVolumeTotal(target.volumeTargets)|number:'1.0-0':languageCultureCode }}</mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="volumeDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: volumeDisplayedColumns;"></mat-row>
            <mat-footer-row *matFooterRowDef="volumeDisplayedColumns"></mat-footer-row>
        </mat-table>
    </div>
</div>