import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RebateModel } from '../models/rebate.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { ServiceCallResult } from './../../../core/models/service-call-result';
import { GlobalVariablesService } from './../../../core/services/global-variables.service';

import { CalculatorModel } from './../models/calculator.model';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  constructor(private http: HttpClient) { }

  public GetEOYCalculatorSettings(request: CalculatorModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Calculator/GetEOYCalculatorSettings", request).pipe(map(response => response));
  }

  public CreateUpdateEOYCalculatorSettings(request: CalculatorModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Calculator/CreateUpdateEOYCalculatorSettings", request).pipe(map(response => response.result));
  }

}
