import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './../../modules/error/services/error.service';
import { GlobalVariablesService } from './global-variables.service';
import { ServiceCallResult } from '../models/service-call-result';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient,
    private errorService: ErrorService) { }

  public SaveExcel(file: File, year: string): Observable<ServiceCallResult> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year);
    const fileHeaders = new HttpHeaders();
    fileHeaders.set('Accept', 'application/json');
    fileHeaders.delete('Content-Type');
    return this.http.post<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}Upload/SaveExcel`,
      formData);
  }

  public GetExcelSales(year: string): Observable<ServiceCallResult> {
    const formData = new FormData();
    formData.append('year', year);
    const fileHeaders = new HttpHeaders();
    fileHeaders.set('Accept', 'application/json');
    fileHeaders.delete('Content-Type');
    return this.http.post<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}Upload/GetExcelSales`,
      formData);
  }

  public GetExcelVolume(year: string): Observable<ServiceCallResult> {
    const formData = new FormData();
    formData.append('year', year);
    const fileHeaders = new HttpHeaders();
    fileHeaders.set('Accept', 'application/json');
    fileHeaders.delete('Content-Type');
    return this.http.post<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}Upload/GetExcelVolume`,
      formData);
  }
}