<table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">
  <ng-container matColumnDef="typeOfAgreement">
    <th mat-header-cell *matHeaderCellDef>{{"Type Of Agreement"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.typeOfAgreement" ngDefaultControl>
      {{element.typeOfAgreement}}
  </ng-container>
  <ng-container matColumnDef="annualValue">
    <th mat-header-cell *matHeaderCellDef>{{"Annual Value"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.annualValue" ngDefaultControl>
      {{element.annualValue | number:'0.1-2' : languageCultureCode}}
    </td>
  </ng-container>
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>{{"Details"|translate}}</th>
    <td mat-cell *matCellDef="let element" [(ngModel)]="element.details" ngDefaultControl>
      {{element.details}}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </tr>
</table>