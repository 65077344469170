import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../core/services/notification-service';
import { CustomerService } from '../../../customer/services/customer.service';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { CustomerModel } from '../../../customer/models/customer-model';
import { CustomerDevPlanModel } from '../../models/customer-dev-plan-request.model';
import { DevPlanModel } from '../../models/dev-plan-request.model';
import { ShopDataModel } from '../../models/shop-data.model';
import { DevPlanService } from '../../services/dev-plan.service';

@Component({
  selector: 'app-development-plan-display',
  templateUrl: './development-plan-display.component.html',
  styleUrls: ['./development-plan-display.component.scss']
})
export class DevelopmentPlanDisplayComponent implements OnInit {
  public title: string = "Development Plan Maintenance";
  public ctsId: number = 0;
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public dataSource = new MatTableDataSource<CustomerDevPlanModel>();
  public displayedColumns = ["shopId", "description", "comments"];
  public devplans: DevPlanModel[];
  public custdevplans: DevPlanModel[];
  public year: number;
  public AddOrDeleteFlag = false;
  public shopData: ShopDataModel[];
  
  constructor(private devPlanService: DevPlanService, private customerService: CustomerService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId != 0) {
      this.GetCustomerById();
      this.GetShopAndDevPlansData();
      this.GetDevPlansForAllShops();
    }
  }
  public GetCustomerById() {
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName =
              (this.customer.ctsId == null ? '' : this.customer.ctsId + ' - ') +
              (this.customer.companyName == null ? '' : this.customer.companyName);

          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        });
    } catch (e) {
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }
  public GetShopAndDevPlansData() {
    try {
      let shoprequest = new ShopDataModel();
      shoprequest.ctsId = this.ctsId;

      let request = new DevPlanModel();
      if (this.year > 0) {
        request.year = this.year;
        this.customerService.GetShopByCTSId(shoprequest).subscribe(shopData => {
          if (shopData.success) {
            this.shopData = shopData.result;
            this.devPlanService.GetPlansByYear(request).subscribe(devplanData => {
              if (devplanData.success) {
                this.devplans = devplanData.result;
              }
            })
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        });
      }
      else {
        NotificationService.warning(null, this.translateService.instant("Choose a year to add/edit a Development Plan!!"));
      }
    } catch (e) {
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }
  public GetDevPlansForAllShops(): void {
    try {
      let planrequest = new CustomerDevPlanModel();
      planrequest.ctsId = this.ctsId;
      if (this.year > 0) {
        planrequest.year = this.year;
        this.devPlanService.GetCustomerPlans(planrequest).subscribe(planresponse => {
          if (planresponse.success) {
            this.dataSource.data = planresponse.result;
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        });
      }
      else {
        NotificationService.warning(null, this.translateService.instant("Choose a year to add/edit a Development Plan!!"));
      }
    } catch (e) {
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }
}
