import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { BrandTargetModel } from '../../models/brand-target.model';
import { SalesTargetModel } from '../../models/sales-target.model';
import { VolumeTargetModel } from '../../models/volume-target.model';
import { TargetService } from '../../services/target.service';
import { SalesGoalEntryComponent } from '../sales-goal-entry/sales-goal-entry.component';

@Component({
  selector: 'app-target-display',
  templateUrl: './target-display.component.html',
  styleUrls: ['./target-display.component.scss']
})
export class TargetDisplayComponent implements OnInit {
  public ctsId: number;
  public year: number;
  public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();
  public brandTarget: BrandTargetModel;
  volumeDisplayedColumns = ['brand', 'segment', 'clearcoats', 'primers', 'totals'];
  salesDisplayedColumns = ['brand', 'segment', 'total'];

  constructor(private targetService: TargetService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.targetService.getTargetsForDisplay(this.ctsId, this.year).subscribe(x => {
      this.brandTarget = x;
      console.log(x);
    });
  }

  getSalesTotal(targets: SalesTargetModel[]) {
    // tslint:disable-next-line: radix
    return targets.map(t => t.target).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
  getVolumeTotal(targets: VolumeTargetModel[]) {
    // tslint:disable-next-line: radix
    return targets.map(t => t.primerTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0) +
      targets.map(t => t.clearcoatTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
  getPrimersTotal(targets: VolumeTargetModel[]) {
    return targets.map(t => t.primerTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
  getClearcoatsTotal(targets: VolumeTargetModel[]) {
    return targets.map(t => t.clearcoatTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
}
