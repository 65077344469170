<mat-table #table [dataSource]="targets" class="mat-elevation-z8">
  <ng-container matColumnDef="brand">
    <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="segment">
    <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="clearcoats">
    <mat-header-cell *matHeaderCellDef>{{"Clearcoats"|translate}} (Lts.)</mat-header-cell>
    <mat-cell *matCellDef="let target">      
        <input matInput  matInputSeparatorsDirective placeholder="0" [value]="target.clearcoatTargetFormatted"
          (change)="onChange($event, 'clearcoat',  target)" [(ngModel)]="target.clearcoatTargetFormatted"
          [disabled]="IsReadOnly">  
    </mat-cell>
    <mat-footer-cell *matFooterCellDef> {{ getClearcoatsTotal()| number:'1.0-0' :languageCultureCode }}</mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="primers">
    <mat-header-cell *matHeaderCellDef>{{"Primers"|translate}} (Lts)</mat-header-cell>
    <mat-cell *matCellDef="let target">   
        <input matInput matInputSeparatorsDirective placeholder="0" [value]="target.primerTargetFormatted" (change)="onChange($event, 'primer',  target)"
          [(ngModel)]="target.primerTargetFormatted" [disabled]="IsReadOnly">     
    </mat-cell>
    <mat-footer-cell *matFooterCellDef> {{ getPrimersTotal()| number:'1.0-0' :languageCultureCode }}</mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="totals">
    <mat-header-cell *matHeaderCellDef>{{"Total"|translate}} (Lts.)</mat-header-cell>
    <mat-cell *matCellDef="let target">{{ (target.primerTarget*1 +target.clearcoatTarget*1)|number:'1.0-0':languageCultureCode}} </mat-cell>
    <mat-footer-cell *matFooterCellDef> {{ getTotal()| number:'1.0-0' :languageCultureCode }}</mat-footer-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
</mat-table>