export class LineSegmentModel {
  public id: number;
  public brandId: number;
  public segmentId: number;
  public segmentName: string;
  public brandName: string;
  public selected: boolean;
  public year: number;
  public isUserSelected: boolean;
}
