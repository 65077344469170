import { Component, Input, OnInit } from '@angular/core';
import { VolumeTargetModel } from '../../models/volume-target.model';
import { TargetService } from '../../services/target.service';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { GoalMessageService } from '../../services/goal-message.service';
import { NumberFormatService } from '../../../../core/services/number-format-service';

@Component({
  selector: 'app-volume-goal-entry',
  templateUrl: './volume-goal-entry.component.html',
  styleUrls: ['./volume-goal-entry.component.scss']
})
export class VolumeGoalEntryComponent implements OnInit {
  @Input() public volumeTargets: VolumeTargetModel[];
  @Input() IsReadOnly: Boolean = false;
  public year: number;
  public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();

  public targets: VolumeTargetModel[];
  displayedColumns = ['brand', 'segment', 'clearcoats', 'primers', 'totals'];
  constructor(private targetService: TargetService, private goalMessageService: GoalMessageService) { }

  ngOnInit(): void {
    this.year=GlobalVariablesService.getYear();
    this.loadTargets();
  }

  loadTargets(): void {
    this.targets = this.volumeTargets;
  }

  getTotal() {
    return this.volumeTargets.map(t => t.primerTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0) +
      this.targets.map(t => t.clearcoatTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }

  getPrimersTotal() {
    return this.volumeTargets.map(t => t.primerTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }

  getClearcoatsTotal() {
    return this.volumeTargets.map(t => t.clearcoatTarget).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }

  async onChange($event, name: string, data: VolumeTargetModel) {
    let targetToSend = new VolumeTargetModel();
    targetToSend.id = data.id;
    targetToSend.brandId = data.brandId;
    targetToSend.segmentId = data.segmentId;
    
    if (name === 'primer') {
      data.primerTarget = parseInt(NumberFormatService.removeSeparators(data.primerTargetFormatted));
      targetToSend.primerTarget = parseInt($event.target.value);
      targetToSend.clearcoatTarget = -1000;
    } else {
      data.clearcoatTarget = parseInt(NumberFormatService.removeSeparators(data.clearcoatTargetFormatted));
      targetToSend.primerTarget = -1000;
      targetToSend.clearcoatTarget = parseInt($event.target.value);
    }
    targetToSend.ctsId = data.ctsId;
    targetToSend.year=this.year;
    await this.targetService.saveVolumeTarget(targetToSend).subscribe(x => {
    });
    this.goalMessageService.sendMessage('changed');

  }
}