export class ABPApprovalModel {
    year: number;
    ctsId: number;
    userId: number;
    date: Date;
    status: string;
    firstName: string;
    lastName: string;
    userTypeId: number;
    userTypeName: string;
    stepId: number;
}