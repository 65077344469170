<app-spinner *ngIf="spinner"></app-spinner>
<div *ngFor="let header of headers;let i = index" class="container justify">
  <mat-label *ngIf="header.sortOrder != 0"><strong>{{ (i) + "." }} </strong></mat-label>
  <mat-label><strong>{{ header.description }}</strong></mat-label>
  <br><br>
  <div *ngFor="let detail of header.details;let y = index">
    <mat-label *ngIf="header.sortOrder != 0">{{ i + '.' + (y+1) }} </mat-label>
    <mat-label style="white-space: pre-wrap;"> {{ detail.description 
      | replace : '[Consecution Level]' : ' ' 
      | replace : '[Volume Target]' : ' ' 
      | replace : '[Sales Target]' : ' ' 
      | replace : '[Functional Rebate]' : ' '
      | replace : '[Exclusivity Rebate]' : ' '
      | replace : '[Plus Rebate]' : ' '
      | replace : '[Marketing]' : ' '
      | replace : '[Technical Assistance]' : ' '
      | replace : '[Training]' : ' '
      | replace : '[Target Accounts]' : ' '
      | replace : '[Development Plan]' : ' '
    }}
    </mat-label> 
    <br><br>
    <div *ngIf="detail.description.includes('[Consecution Level]')">
      <app-customer-consecution-display></app-customer-consecution-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Sales Target]')">
      <app-sales-target-display></app-sales-target-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Functional Rebate]')">
      <app-customer-rebate-display rebateTypeId="1"></app-customer-rebate-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Exclusivity Rebate]')">
      <app-customer-rebate-display rebateTypeId="2"></app-customer-rebate-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Plus Rebate]')">
      <app-customer-rebate-display rebateTypeId="3"></app-customer-rebate-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Volume Target]')">
      <app-volume-goal-display></app-volume-goal-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Marketing]')">
      <app-marketing-display></app-marketing-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Technical Assistance]')">
      <app-technical-assistance-display></app-technical-assistance-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Training]')">
      <app-training-display></app-training-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Target Accounts]')">
      <app-target-account-display></app-target-account-display>
      <br><br>
    </div>
    <div *ngIf="detail.description.includes('[Development Plan]')"> 
      <app-development-plan-display></app-development-plan-display>
      <br><br>
    </div>
  </div>
  <br><br><br>
</div>