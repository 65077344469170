import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

import { PageLoaderService } from './../../../core/services/page-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private pageLoaderService: PageLoaderService) { }

  public handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    this.pageLoaderService.displayMessage(
      'An error occurred, please refresh and try again.'
    );

    return throwError('');
  }
}
