export class UserModel {
  userGuid: string;
  userId: number;
  userTypeId: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  awareId: string;
  languageId: number;
  active: boolean;
  createDate: Date;
  createdBy: number;
  modifyDate: Date;
  modifiedBy: number;
  ppgNetworkId: string;
  isPPGUser:boolean;
  userTypeName: string;
  languageCultureCode: string;
}