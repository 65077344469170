import { formatNumber } from "@angular/common";
import { GlobalVariablesService } from "./global-variables.service";

export class NumberFormatService {
  public static removeSeparators(value): string {
    if (value != null) {
    return value.replace(/[^\d-]/g, '');
    }
    return '0';
  }

  public static numberWithCommas(value: string): string {
    return formatNumber(parseInt(value), GlobalVariablesService.getLanguageCultureCode(), '1.0-0');
  }
}
