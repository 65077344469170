import { CustomerTrainingModel } from './../../models/customer-training.model';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from '../../../../core/services/notification-service';
import { TrainingHeaderModel } from '../../models/training-header.model';
import { TrainingModel } from '../../models/training.model';
import { TrainingService } from '../../services/training.service';

@Component({
  selector: 'app-training-display',
  templateUrl: './training-display.component.html',
  styleUrls: ['./training-display.component.scss']
})
export class TrainingDisplayComponent implements OnInit {
  public spinner = false;
  public title: string = "Training Maintenance";
  public year: number = 0;
  public years: number[] = [];
  public ctsId: number = null;
  public showAddCancel = false;
  public customerTrainings: CustomerTrainingModel[] = [];
  public trainingHeader = new TrainingHeaderModel();
  public trainingsColumns = ["name", "trainingName", "targetDate"];

  constructor(private training_service: TrainingService, private route: ActivatedRoute, private translateService: TranslateService) {
    this.years = GlobalVariablesService.getYears();
    this.year = GlobalVariablesService.getYear();
    this.ctsId = GlobalVariablesService.getCtsId();
    this.loadTrainings();
  }

  ngOnInit(): void {
    this.loadTrainings();
  }

  public loadTrainings(): void {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.showAddCancel = false;
      let training = new TrainingModel();
      training.year = this.year;
      let customerTraining = new CustomerTrainingModel();
      customerTraining.year = this.year;
      customerTraining.ctsId = this.ctsId;
      this.training_service.GetAllCustomerTrainings(customerTraining).subscribe(response => {
        this.customerTrainings = response.result;
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }
}