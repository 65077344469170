import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalMessageService {
  private subject = new Subject<any>();
  
  sendMessage(message: string) {
    console.log(message);
    this.subject.next({ text: message});
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  constructor() { }
}
