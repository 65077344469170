<div *ngFor="let target of this.brandTarget?.brandTargets">
  <div *ngIf="target.salesTargets">
    <mat-table #table [dataSource]="target.salesTargets" class="mat-elevation-z8">
      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="segment">
        <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let target">{{ target.target|number:'1.0-0' :languageCultureCode }}</mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ getSalesTotal(target.salesTargets)|number:'1.0-0' :languageCultureCode }}</mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="salesDisplayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: salesDisplayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="salesDisplayedColumns"></mat-footer-row>
    </mat-table>
  </div>