<div class="container" *ngIf="authenticated">
  <div class="row">
    <div class="content container center">
      <app-customer-header>
      </app-customer-header>
      <h1>&nbsp;</h1>
      <app-customer-lines>
      </app-customer-lines>
    </div>
  </div>
  <hr class="lineSpacing">
  <div class="row">
    <div class="container content center">
      <div class="row">
        <div class="col">
          <br />
          <img src="./assets/img/annual_business_plan.png" />
          <br /><br />
          <a routerLink="/annual-business-plan-view">{{"Annual Business Plan"|translate}}</a>
        </div>
        <div class="col">
          <br />
          <a routerLink="/follow-up">
            <img src="./assets/img/follow_up.png" />
            <br /><br />
            {{"Follow up"|translate}}</a>
        </div>
        <div class="col">
          <br />
          <a routerLink="/calculator-menu"> <img src="./assets/img/calculator.png" />
            <br /><br />
            {{"Calculators"|translate}} </a>
        </div>
      </div>
    </div>
  </div>
  <hr class="lineSpacing">
</div>