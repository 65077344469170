import { Component, OnInit } from '@angular/core';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';
import { FollowUpService } from './../../services/follow-up.service';
import { RebateLevelService } from './../../services/rebate-level.service';
import { RebateService } from './../../services/rebate.service';

import { FollowUpModel } from './../../models/follow-up.model';
import { RebateLevelModel } from './../../models/rebate-level.model';
import { CustomerRebateModel } from './../../models/customer-rebate.model';
import { getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})

export class CalculatorComponent implements OnInit {
  public spinner = false;
  public ctsId: number;
  public year: number;

  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();

  public follow_up: FollowUpModel[] = [];
  public rebateLevel: RebateLevelModel[] = [];
  public customerRebate: CustomerRebateModel[] = [];

  public map: { [key: string]: any } = {};

  public follow_upColumnsS = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "rebatePercentage", "pendingPurchase"];
  public follow_upColumnsH = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "rebatePercentage", "pendingPurchase"];
  public follow_upColumnsV = ["brandName", "segmentName", "total", "clearcoatTarget", "primerTarget", "excelValue", "archivePercentage", "rebatePercentage", "pendingPurchase"];
  public rebatesColumns = ["rebateTypeName", "exclusiveDistributor", "qualitativeActions", "minConsecutionAbp", "targetPurchase", "consecutionABP", "ammountConsecutionABP", "baseValue", "percentage", "rebate"];
  public rebatesResumeColumns = ["rebateTypeName", "rebate"];

  public brands: string[] = [];
  public segments: string[] = [];

  public baseValue: number = 0;
  public baseValueProccessed: string[] = [];

  public total: number = 0;
  public totalProccessed: string[] = [];

  public pendingPurchase: number = 0;
  public pendingPurchaseProccessed: any[] = [];

  public pendingPurchaseVolume: number = 0;
  public pendingPurchaseVolumeProccessed: any[] = [];

  constructor(private followUpService: FollowUpService,
    private rebateLevelService: RebateLevelService,
    private rebateService: RebateService,
    private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadFollowUp();
  }

  ngOnInit(): void {
  }

  public LoadFollowUp(): void {
    this.spinner = true;
    let request = new FollowUpModel();
    request.year = this.year;
    request.ctsId = this.ctsId;
    this.followUpService.GetCustomerFollowUp(request).subscribe(response => {
      this.follow_up = response.result;
      if (this.follow_up != null) {
        this.Brands();
        this.Segments();
        this.rebateLevelService.getRebateLevel(request.ctsId, request.year).subscribe(response => {
          this.rebateLevel = response;
          let customerRebate = new CustomerRebateModel();
          customerRebate.year = this.year;
          customerRebate.ctsId = this.ctsId;
          this.rebateService.GetAllCustomerRebate(customerRebate).subscribe(response => {
            this.customerRebate = response.result;
            this.spinner = false;
          }, error => {
            console.log(error);
            NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
            this.spinner = false;
          });
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Brands() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.brandName === y.brandName) === i
    );
    filter.sort(function (a, b) {
      if (a.brandName < b.brandName) { return -1; }
      if (a.brandName > b.brandName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.brands.push(item.brandName)
    });
  }

  public Segments() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.segmentName === y.segmentName) === i
    );
    filter.sort(function (a, b) {
      if (a.segmentName < b.segmentName) { return -1; }
      if (a.segmentName > b.segmentName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.segments.push(item.segmentName)
    });
  }

  public GetBrandData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.brandName == brand);
      if (array != null) {
        let total: number = 0;
        let clearcoatTarget: number = 0;
        let primerTarget: number = 0;
        let excelValue: number = 0;
        let segmentName: string;
        array.forEach(item => {
          if (item.type == 'Sales' && item.segmentName.includes('CAR')) {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB') {
              total = total + item.total;
              excelValue = excelValue + item.excelValue;
              segmentName = 'CAR + CAR GB';             
            } else {
              response.push(item);
            }
          }
          else if (item.type == 'Volume' && item.segmentName.includes('CAR')
            || (item.type == 'Volume' && brand == "PPG" && item.segmentName == 'HVLS')
            ||(item.type == 'Volume' && item.segmentName == 'HVLS')) {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB' || item.segmentName == 'HVLS') {

              clearcoatTarget = clearcoatTarget + item.clearcoatTarget;
              primerTarget = primerTarget + item.primerTarget;
              total = clearcoatTarget + primerTarget;
              excelValue = excelValue + item.excelValue
              segmentName = 'CAR + CAR GB + HVLS';
            } else {
              response.push(item);
            }
          }
        });
        if (total > 0 || excelValue > 0) {
          let data = new FollowUpModel();
          data.type = type;
          data.year = this.year;
          data.ctsId = this.ctsId;
          data.brandName = brand;
          data.segmentName = segmentName;
          data.total = total;
          data.clearcoatTarget = clearcoatTarget;
          data.primerTarget = primerTarget;
          data.excelValue = excelValue;
          data.archivePercentage = excelValue / total;
          response.push(data);
        }
      }
    }
    return response;
  }

  public GetSegmentHVLSData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (brand == 'AutoLux' || brand == 'QuickLine' || brand == 'Valuepro') {
      if (this.follow_up != null) {
        let array = this.follow_up.filter(x => x.type == 'Sales' && x.segmentName.includes('HVLS') && x.brandName == brand);
        if (array != null) {
          let totalHVLS: number = 0;
          let excelValueHVLS: number = 0;
          let totalHVLSCOLOR: number = 0;
          let excelValueHVLSCOLOR: number = 0;
          array.forEach(item => {
            if (item.type == 'Sales') {
              if (item.segmentName == 'HVLS') {
                totalHVLS = totalHVLS + item.total;
                excelValueHVLS = excelValueHVLS + item.excelValue;
              }
              if (item.segmentName.toUpperCase() == 'HVLS COLOR') {
                totalHVLSCOLOR = totalHVLSCOLOR + item.total;
                excelValueHVLSCOLOR = excelValueHVLSCOLOR + item.excelValue;
              }
            }
          });
          if (totalHVLS > 0 || excelValueHVLS > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS';
            data.total = totalHVLS;
            data.excelValue = excelValueHVLS;
            data.archivePercentage = excelValueHVLS / totalHVLS;
            response.push(data);
          }
          if (totalHVLSCOLOR > 0 || excelValueHVLSCOLOR > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS COLOR';
            data.total = totalHVLSCOLOR;
            data.excelValue = excelValueHVLSCOLOR;
            data.archivePercentage = excelValueHVLSCOLOR / totalHVLSCOLOR;
            response.push(data);
          }
        }
      }
    }
    return response;
  }

  public GetSegmentCTData(type: string, brand: string) {
    if (brand == 'Delfleet One' || brand == 'Turbo Vision') {
      return this.follow_up.filter(x => x.type == 'Sales' && x.segmentName == 'CT' && x.brandName == brand);
    } else {
      return [];
    }
  }

  public GetSegmentData(type: string, segment: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.segmentName == segment && !x.segmentName.includes('HVLS'));
      if (array != null) {
        array.forEach(item => {
          if (item.segmentName == 'LIC') {
            response.push(item);
          }
        });
      }
    }
    return response;
  }

  public getTotal(data: FollowUpModel[]) {
    let result = data.map(t => t.total).reduce((acc, value) => acc + value, 0);
    let key: string;
    data.forEach(item => {
      if (data.findIndex(x => x.segmentName.includes('HVLS')) !== -1) {
        key = item.type + item.brandName + 'HVLS';
      } else {
        key = item.type + item.brandName + item.segmentName;
      }

      if (this.totalProccessed.findIndex(x => x.includes(key)) == -1) {
        this.totalProccessed.push(key);
        this.total = this.total + result;
        // console.log(key + ' ' + result + ' ' + this.total);
      }
    });
    return result;
  }

  public getTotalExcelValue(data: FollowUpModel[]) {
    let response = data.map(t => t.excelValue).reduce((acc, value) => acc + value, 0);
    let key: string;
    data.forEach(item => {
      if (data.findIndex(x => x.segmentName.includes('HVLS')) !== -1) {
        key = item.type + item.brandName + 'HVLS';
      } else {
        key = item.type + item.brandName + item.segmentName;
      }

      if (this.baseValueProccessed.findIndex(x => x.includes(key)) == -1) {
        this.baseValueProccessed.push(key);
        this.baseValue = this.baseValue + response;
        // console.log(key + ' ' + result + ' ' + this.baseValue);
      }
    });
    if (response < 0) response = 0;
    return response;
  }

  public getTotalArchivePercentage(data: FollowUpModel[]) {
    return data.map(t => t.archivePercentage).reduce((acc, value) => acc + value, 0);
  }

  public getTotalPendingPurchase(data: FollowUpModel[], code: string, key: string) {
    // return data.map(t => t.pendingPurchase).reduce((acc, value) => acc + value, 0);
    let response = 0;
    data.forEach(item => {
      response = this.getPendingPurchase(item, code, key) + response;
    });
    if (response < 0) response = 0;
    let index: number = 0;
    if (code == 'CarV') {
      if (this.pendingPurchaseVolumeProccessed.length > 0) {
        index = this.pendingPurchaseVolumeProccessed.findIndex(x => x.uq.includes(code + key));
      } else {
        index = -1;
      }
      if (index == -1) {
        this.pendingPurchaseVolumeProccessed.push({ uq: code + key, value: response });
      } else {
        this.pendingPurchaseVolumeProccessed[index] = { uq: code + key, value: response }
      }
      this.pendingPurchaseVolume = 0;
      this.pendingPurchaseVolumeProccessed.forEach(item => {
        this.pendingPurchaseVolume = this.pendingPurchaseVolume + item.value;
      });
    } else {
      if (this.pendingPurchaseProccessed.length > 0) {
        index = this.pendingPurchaseProccessed.findIndex(x => x.uq.includes(code + key));
      } else {
        index = -1;
      }
      if (index == -1) {
        this.pendingPurchaseProccessed.push({ uq: code + key, value: response });
      } else {
        this.pendingPurchaseProccessed[index] = { uq: code + key, value: response }
      }
      this.pendingPurchase = 0;
      this.pendingPurchaseProccessed.forEach(item => {
        this.pendingPurchase = this.pendingPurchase + item.value;
      });
    }
    return response;
  }

  public getPendingPurchase(item: FollowUpModel, code: string, key: string) {
    let id: string = code + key;
    let response: number = 0;
    if ((<HTMLInputElement>document.getElementById(id)) != null) {
      response = Number(((<HTMLInputElement>document.getElementById(id)).value)) / 100;
    }
    response = (item.total * response) - item.excelValue;
    if (response < 0) response = 0;
    return response;
  }

  public getRebatePercentage(code: string, brand: string, segment: string) {
    let id: string;
    if (segment == 'LIC') {
      id = code + segment;
    } else {
      id = code + brand;
    }
    let response: number = 0;
    let segmentKey = "";
    if (<HTMLInputElement>document.getElementById(id) != null) {
      let estimatedRebatePercentage: number = Number(((<HTMLInputElement>document.getElementById(id)).value));
      if (segment.includes('CAR') || segment == 'CAR + CAR GB + HVLS') segmentKey = 'CAR';
      else if (segment.includes('HVLS')) segmentKey = 'HVLS';
      else if (segment.includes('CT')) segmentKey = 'CT';
      else if (segment.includes('LIC')) segmentKey = 'LIC';
      if (estimatedRebatePercentage >= 90 && estimatedRebatePercentage < 95) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90)[0].rebate;
        }
      }
      if (estimatedRebatePercentage >= 95 && estimatedRebatePercentage < 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95)[0].rebate;
        }
      }
      if (estimatedRebatePercentage == 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1)[0].rebate;
        }
      }
    }
    if (response < 0) response = 0;
    return response;
  }

  public getConsecutionOnABP() {
    return this.getBaseValue() / this.total;
  }

  public getRebate(baseValue: number, rebatePercentage: number) {
    return this.getBaseValue() * (rebatePercentage / 100);
  }

  public getConsecution() {
    return (this.getBaseValue() / this.total) * 100;
  }

  public getBaseValue() {
    return this.baseValue + this.pendingPurchase;
  }

  public getTargetPurchase(item: CustomerRebateModel) {
    return ((this.total * item.minConsecutionAbp) / 100);
  }

  public getConsecutionAmmount() {
    return this.baseValue + this.pendingPurchase;
  }

  public getRebateTotal(data: CustomerRebateModel[]) {
    let result = data.map(t => this.getRebate(this.getBaseValue(), t.percentage)).reduce((acc, value) => acc + value, 0);
    return result;
  }
  public GetCurrencyCode() {
    return getLocaleCurrencyCode(this.languageCultureCode);
  }

  public GetCurrencySymbol() {
    return getCurrencySymbol(this.GetCurrencyCode(), "narrow", this.languageCultureCode);
  }
}
