<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <h3 class="center">{{title|translate}}</h3>
    <!-- <div>
        <button mat-button matTooltip="Save" class="btn btnPPG1" (click)="CreateUpdate()"
            *ngIf="!isPlatinumProgress && !IsReadOnly">
            <span class="material-icons">save</span><br />
        </button>
    </div> -->
    <br />
    <!-- <div>
        <mat-label>{{"Details"|translate}}:</mat-label><br />
        <textarea [(ngModel)]="rebate.details" [disabled]="true"></textarea>
    </div> -->
    <div class="row">
        <div class="col-sm">
            <mat-form-field style="display: inline !important;">
                <mat-label>{{"Min Consecution on ABP"|translate}}</mat-label>
                <input matInput placeholder="Min Consecution on ABP" disabled>{{minConsecutionAbp}}%
            </mat-form-field>
        </div>
        <div class="col-sm">
            <mat-form-field style="display: inline !important;">
                <mat-label>{{"Rebate"|translate}} %</mat-label>
                <input matInput placeholder="Rebate %" disabled> {{percentage|number:'1.2-2':languageCultureCode}}%
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <mat-form-field [hidden]="HideBaseValue()" style="display: inline !important;">
                <mat-label>{{"Rebate Target"|translate}}</mat-label>
                <input matInput placeholder="Base Value" disabled> {{customerRebate.baseValue|number:'1.2-2':languageCultureCode}}
            </mat-form-field>
        </div>
        <div class="col-sm">
            <!-- <mat-checkbox [(ngModel)]="customerRebate.qualitativeActions" [hidden]="HideQualitativeActions()"
                [disabled]="isPlatinumProgress || IsReadOnly">
                {{"Qualitative Actions"|translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="customerRebate.exclusiveDistributor" [hidden]="HideExclusiveDistributor()"
                [disabled]="isPlatinumProgress || IsReadOnly">
                {{"Exclusive Distributor"|translate}}</mat-checkbox> -->
        </div>
    </div>
</div>