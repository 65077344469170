import { Component, OnInit, Input, PipeTransform, Injectable, Pipe } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { LineSegmentModel } from '../../../customer/models/line-segment.model';
import { LineSegmentService } from '../../../customer/services/line-segment.service';
import { CustomerBrandRebateExclusionModel } from '../../models/customer-brand-rebate-exclusion.model';
import { RebateLevelModel } from '../../models/rebate-level.model';
import { RebateLevelService } from '../../services/rebate-level.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './../../../../core/services/notification-service';

@Component({
  selector: 'app-customer-consecution',
  templateUrl: './customer-consecution.component.html',
  styleUrls: ['./customer-consecution.component.scss']
})

export class CustomerConsecutionComponent implements OnInit {
  @Input() IsReadOnly: Boolean = false;

  public IsPPGAdmin = GlobalVariablesService.IsPPGAdmin();
  public gridData: RebateLevelModel[];
  displayedColumns = ['brand', 'segment', 'consecutionLevel', 'rebate', 'actions'];
  premiumDisplayedColumns = ['brand', 'segment', 'consecutionLevel', 'rebate', 'car', 'volume', 'actions'];
  addDisplayedColumns = ['segment', 'brand', 'consecutionLevel', 'rebate', 'actions'];
  public showAddCancel = false;
  public addRow: RebateLevelModel[];
  public lines: LineSegmentModel[];
  public segments: LineSegmentModel[];
  public brands: LineSegmentModel[];
  public segmentId: number;
  public lineId: number;
  public premiumGridData: RebateLevelModel[];
  public ctsId: number;
  public year: number;
  public languageCultureCode:string =GlobalVariablesService.getLanguageCultureCode();

  constructor(private lineService: LineSegmentService, private translateService: TranslateService,
    private rebateLevelService: RebateLevelService) {

  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId == 0) {
      NotificationService.warning(null, this.translateService.instant("Select a CTS Id to continue!"))
    }
    else if (this.year == 0) {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    else {
      this.loadGrid();
      this.loadDropdowns();
    }
  }

  public loadGrid() {
    this.rebateLevelService.getRebateLevel(this.ctsId, this.year).subscribe(x => {
      this.gridData = x.filter(x => {
        if ((x.segmentId != 3) || (x.segmentId == 3 && x.brandId == 3)) {
          return x;
        }
      });
      this.premiumGridData = x.filter(x => {
        if (x.segmentId == 3 && x.brandId != 3) {
          return x;
        }
      });
    });
  }

  isGroup(event, item): boolean {
    return item.isGroupBy;
  }

  loadDropdowns() {

    this.lineService.getLinesByCtsId(this.ctsId, this.year).subscribe(x => {
      this.lines = x;
    });
  }

  onSegmentChange(obj) {
    this.lineService.getSegmentsByLineCtsId(parseInt(obj), this.ctsId).subscribe(x => {
      this.brands = x;
    });

  }

  public ShowAddCancel() {
    this.addRow = [new RebateLevelModel()];
    this.showAddCancel = !this.showAddCancel;
  }

  public async create(obj: RebateLevelModel, segment, brand) {
    obj.year = this.year;
    obj.brandId = brand;
    obj.segmentId = segment;
    obj.ctsId = this.ctsId;
    obj.rebate = obj.rebate / 100;
    obj.consecutionLevel = obj.consecutionLevel / 100;
    await this.rebateLevelService.saveRebateLevel(obj).toPromise();
    this.ShowAddCancel();
    this.loadGrid();
  }

  public async onChange(level: RebateLevelModel, obj) {
    if (!this.IsReadOnly) {
      level.rebate = obj.target.value / 100;
      level.year = this.year;
      level.saveLinesFlag = false;
      await this.rebateLevelService.saveRebateLevel(level).toPromise();
      //this.ShowAddCancel();
      this.loadGrid();
    }
  }

  public onExcludeClick(level: RebateLevelModel, $event: MatCheckbox) {
    let exclusion = new CustomerBrandRebateExclusionModel();
    exclusion.ctsId = this.ctsId;
    exclusion.brandId = level.brandId;
    exclusion.year = this.year;
    exclusion.excludeFromRebate = !$event.checked;
    this.rebateLevelService.saveBrandExclusion(exclusion).subscribe();
  }


  public async Delete(data: RebateLevelModel) {
    data.year = this.year;
    data.ctsId = this.ctsId;
    data.consecutionLevel = data.consecutionLevel;
    data.rebate = data.rebate / 100;
    await this.rebateLevelService.deleteRebateLevel(data).toPromise();
    this.loadGrid();
    console.log("Deleted");
  }
}