<h2 mat-dialog-title>{{"Add Clause"|translate}}</h2>
<mat-dialog-content style="height:100%;position:relative; overflow: visible;">

  <textarea style="width: 100%;height: 100px;background: #e3edf7;" matInput [(ngModel)]="this.detailText"></textarea>

  <p></p>
  <div style="text-align: center;">
    <button mat-raised-button (click)="onSaveClick($event)">{{"Save"|translate}}</button>
    <button mat-raised-button (click)="onCancelClick($event)">{{"Cancel"|translate}}</button>
  </div>
</mat-dialog-content>