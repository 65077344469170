import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { BrandTargetModel } from '../../models/brand-target.model';
import { TargetService } from '../../services/target.service';

@Component({
  selector: 'app-target-entry',
  templateUrl: './target-entry.component.html',
  styleUrls: ['./target-entry.component.scss']
})
export class TargetEntryComponent implements OnInit {
  @Input() IsReadOnly: Boolean = false;

  public brandTarget: BrandTargetModel;
  public ctsId: number;
  public year: number;
  constructor(private targetService: TargetService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.loadTargets(this.ctsId);
  }
  public async loadTargets(ctsId: number) {
    await (await this.targetService.getTargets(this.ctsId, this.year)).subscribe(x => {
      this.brandTarget = x;
      console.log(x);
    });
  }
}
