<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="grid-container">
        <kendo-grid #grid="kendoGrid" [kendoGridBinding]="gridView" [scrollable]="'none'" [pageSize]="pageSize"
          [skip]="skip" [pageable]="true" [selectable]="{enabled: true, checkboxOnly: true}" filterable="menu"
          (pageChage)="onPageChange($event)">
          <ng-template kendoGridToolbarTemplate>
            <core-custom-search-all [gridData]="gridData" [columns]="['customerName', 'ctsId']" (filtered)="onSearch($event)">
            </core-custom-search-all>
          </ng-template>
          <kendo-grid-column field="ctsId" title="CTS Id"></kendo-grid-column>
          <kendo-grid-column field="customerName" title="Customer Name"></kendo-grid-column>
          <kendo-grid-command-column title="Detail">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button mat-button
                routerLink="/distributor/{{dataItem.ctsId}}">
                <span class="material-icons">open_in_new</span></button>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
