import { Injectable } from '@angular/core';
import { process } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})

export class KGridService {

  constructor() { }

  /**
   * Filter by Columns
   *
   * @param gridData Unmodified grid data
   * @param columns Columns to include in filter search
   * @param filter Text to search for
   * @return Filtered gridData
   */
  public filterColumns(gridData: any[], columns: string[], filter: string): any[] {
    let filters = [];

    columns.forEach((x) => {
      filters.push({
        field: x,
        operator: 'contains',
        value: filter
      });
    });

    return process(gridData, {
      filter: {
        logic: "or",
        filters: filters
      }
    }).data;
  }
}
