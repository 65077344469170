<app-spinner *ngIf="spinner"></app-spinner>
<div class="container center">
  <br>
  <h3>{{"Upload Excel File"|translate}}</h3>
  <br>
  <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()">
    <div class="row">
      <!-- <div class="col-sm-12">
        <div>
          <h6 class="head-title margin-top-8"><span>UPLOAD</span></h6>
        </div>
      </div> -->
      <div class="col-sm-6 text-center">
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile" name="myfile" (change)="onFileSelect($event)"
            #UploadFileInput>
          <label class="custom-file-label" for="customFile">{{fileInputLabel || this.translateService.instant('Choose
            File')}}</label>
        </div>
      </div>
      <div class="col-sm-6 text-center">
        <button class="btn btn-primary" type="submit">{{"Upload"|translate}}</button>
      </div>
    </div>
  </form>
  <br>
  <br>
  <mat-tab-group>
    <mat-tab label="{{'Sales'|translate}}">
      <mat-form-field>
        <mat-label>{{"Filter"|translate}}</mat-label>
        <input matInput (keyup)="ExcelSalesFilter($event)">
      </mat-form-field>
      <table mat-table [dataSource]="excelSalesFilter" class="data_grid mat-elevation-z8">
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef>{{"Year"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.year}}</td>
        </ng-container>

        <ng-container matColumnDef="stRegion">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Region"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stRegion}}</td>
        </ng-container>

        <ng-container matColumnDef="stName">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Name"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stName}}</td>
        </ng-container>

        <ng-container matColumnDef="stNumber">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Number"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="ctsId">
          <th mat-header-cell *matHeaderCellDef>{{"CTSID"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.ctsId}}</td>
        </ng-container>

        <ng-container matColumnDef="stBrand">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Brand"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stBrand}}</td>
        </ng-container>

        <ng-container matColumnDef="espbud">
          <th mat-header-cell *matHeaderCellDef>{{"ESPBUD"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.espbud}}</td>
        </ng-container>

        <ng-container matColumnDef="cyYtd">
          <th mat-header-cell *matHeaderCellDef>{{"CY_YTD"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.cyYtd | number:'0.1-2' : languageCultureCode}}</td>
        </ng-container>

        <ng-container matColumnDef="oem">
          <th mat-header-cell *matHeaderCellDef>{{"OEM"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.oem | number:'0.1-2' : languageCultureCode}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="excelSalesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: excelSalesColumns;"></tr>
      </table>
      <mat-paginator #salesPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </mat-tab>
    <mat-tab label="{{'Volume'|translate}}">
      <mat-form-field>
        <mat-label>{{"Filter"|translate}}</mat-label>
        <input matInput (keyup)="ExcelVolumeFilter($event)">
      </mat-form-field>
      <table mat-table [dataSource]="excelVolumeFilter" class="data_grid mat-elevation-z8">
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef>{{"Year"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.year}}</td>
        </ng-container>

        <ng-container matColumnDef="stRegion">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Region"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stRegion}}</td>
        </ng-container>

        <ng-container matColumnDef="stName">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Name"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stName}}</td>
        </ng-container>

        <ng-container matColumnDef="stNumber">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Number"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="ctsId">
          <th mat-header-cell *matHeaderCellDef>{{"CTSID"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.ctsId}}</td>
        </ng-container>

        <ng-container matColumnDef="stBrand">
          <th mat-header-cell *matHeaderCellDef>{{"ST_Brand"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.stBrand}}</td>
        </ng-container>

        <ng-container matColumnDef="espbud">
          <th mat-header-cell *matHeaderCellDef>{{"ESPBUD"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.espbud}}</td>
        </ng-container>

        <ng-container matColumnDef="cyYtd">
          <th mat-header-cell *matHeaderCellDef>{{"CY_YTD"|translate}}</th>
          <td mat-cell *matCellDef="let item">{{item.cyYtd | number:'0.1-2' : languageCultureCode}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="excelVolumeColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: excelVolumeColumns;"></tr>
      </table>
      <mat-paginator #volumePaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </mat-tab>
  </mat-tab-group>
</div>