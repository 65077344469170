import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomSearchAllComponent } from './kendo/custom-search/custom-search-all.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { ReplacePipe } from './pipes/replace.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { MatInputSeparatorsDirectiveDirective } from './directives/mat-input-separators-directive.directive';

@NgModule({
  declarations: [
    SpinnerComponent,
    CustomSearchAllComponent,
    ReplacePipe,
    DateFormatPipe,
    MatInputSeparatorsDirectiveDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    CustomSearchAllComponent,
    SpinnerComponent,
    ReplacePipe,
    DateFormatPipe,
    MatInputSeparatorsDirectiveDirective
  ]
})

export class CoreModule { }
