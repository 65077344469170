<app-spinner *ngIf="spinner"></app-spinner>
<div class="container" *ngIf="visible; else notApproved">
    <br>
    <h3 class="center">{{"Follow up"|translate}}</h3>
    <br>
    <!-- CAR -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetBrandData('Sales',brand).length > 0">
            <h4>CAR - VALUE - {{brand}} ({{GetCurrencyCode()}})</h4>
            <table mat-table [dataSource]="GetBrandData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>{{getTotal(GetBrandData('Sales',brand))
                        |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetBrandData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetBrandData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr>
            </table>
            <br>
        </div>
        <div *ngIf="GetBrandData('Volume',brand).length > 0">
            <h4>CAR - VOLUME - {{brand}} (LITERS)</h4>
            <table mat-table [dataSource]="GetBrandData('Volume',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotal(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="clearcoatTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Clearcoat Target"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.clearcoatTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="primerTarget">
                    <th mat-header-cell *matHeaderCellDef>{{"Primer Target"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.primerTarget |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalExcelValue(GetBrandData('Volume',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage |percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetBrandData('Volume',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsV"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsV;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsV"></tr>
            </table>
            <br>
        </div>
    </div>
    <!-- HVLS -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
            <h4>VALUE LINES - {{brand}} ({{GetCurrencyCode()}})</h4>
            <table mat-table [dataSource]="GetSegmentHVLSData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentHVLSData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentHVLSData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalArchivePercentage(GetSegmentHVLSData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsH"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsH;"></tr>
                <tr mat-footer-row *matFooterRowDef="follow_upColumnsH"></tr>
            </table>
            <br *ngIf="GetSegmentHVLSData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- CT -->
    <div *ngFor="let brand of brands">
        <div *ngIf="GetSegmentCTData('Sales',brand).length > 0">
            <h4>CT - VALUE ({{GetCurrencyCode()}})</h4>
            <table mat-table [dataSource]="GetSegmentCTData('Sales',brand)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentCTData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentCTData('Sales',brand)) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalArchivePercentage(GetSegmentCTData('Sales',brand)) |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <!-- <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr> -->
            </table>
            <br *ngIf="GetSegmentCTData('Sales',brand).length > 0">
        </div>
    </div>
    <!-- LIC -->
    <div *ngFor="let segment of segments">
        <div *ngIf="GetSegmentData('Sales',segment).length > 0">
            <h4>{{segment}} - VALUE ({{GetCurrencyCode()}})</h4>
            <table mat-table [dataSource]="GetSegmentData('Sales',segment)" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.brandName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="segmentName">
                    <th mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.segmentName}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{"Total"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.total |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotal(GetSegmentData('Sales',segment)) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="excelValue">
                    <th mat-header-cell *matHeaderCellDef>{{"CY-YTD"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.excelValue |number:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        {{getTotalExcelValue(GetSegmentData('Sales',segment)) |
                        number:'1.2-2' :languageCultureCode}}</td>
                </ng-container>
                <ng-container matColumnDef="archivePercentage">
                    <th mat-header-cell *matHeaderCellDef>{{"%Achieved"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        {{item.archivePercentage|percent:'1.2-2' :languageCultureCode}}
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalArchivePercentage(GetSegmentData('Sales',segment))
                        |
                        percent:'1.2-2' :languageCultureCode}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="follow_upColumnsS"></tr>
                <tr mat-row *matRowDef="let row; columns: follow_upColumnsS;"></tr>
                <!-- <tr mat-footer-row *matFooterRowDef="follow_upColumnsS"></tr> -->
            </table>
            <br>
        </div>
    </div>
</div>
<ng-template #notApproved class="container">
    {{ "Annual Business Plan has not been approved for this year. Please select a different year." | translate}}
</ng-template>