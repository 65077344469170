import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { KGridService } from './../../services/k-grid.service';

@Component({
  selector: 'core-custom-search-all',
  templateUrl: './custom-search-all.component.html',
  styleUrls: ['./custom-search-all.component.scss']
})

export class CustomSearchAllComponent implements OnInit {

  @Input() public gridData: any[];
  @Input() public columns: string[];
  @Output() public filtered: EventEmitter<FilterModel> = new EventEmitter();

  public filterText: string;
  public filter: FilterModel = new FilterModel();

  constructor(private gridService: KGridService) { }

  ngOnInit() {
  }

  public filterAll(): void {
    this.filter.gridData = this.gridService.filterColumns(this.gridData, this.columns, this.filterText);
    this.filtered.emit(this.filter);
  }

}

export class FilterModel {
  gridData: any[];
  skip: number = 0;
}