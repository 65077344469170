import { Component, OnInit } from '@angular/core';
import { getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';
import { FollowUpService } from './../../services/follow-up.service';
import { RebateLevelService } from './../../services/rebate-level.service';
import { RebateService } from './../../services/rebate.service';

import { FollowUpModel } from './../../models/follow-up.model';
import { RebateLevelModel } from './../../models/rebate-level.model';
import { CustomerRebateModel } from './../../models/customer-rebate.model';
import { EoyRebateExceptionModel } from './../../models/eoyRebateException-model';

@Component({
  selector: 'app-calculator-eof',
  templateUrl: './calculator-eof.component.html',
  styleUrls: ['./calculator-eof.component.scss']
})
export class CalculatorEofComponent implements OnInit {
  public spinner = false;
  public ctsId: number;
  public year: number;

  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();
  public isAdmin: boolean = GlobalVariablesService.IsPPGAdmin();

  public follow_up: FollowUpModel[] = [];
  public rebateLevel: RebateLevelModel[] = [];
  public customerRebate: CustomerRebateModel[] = [];
  public eoyRebateException: EoyRebateExceptionModel[] = [];

  public map: { [key: string]: any } = {};

  public rebatesResumeColumns = ["rebateTypeName", "rebate"];

  public brands: string[] = [];
  public segments: string[] = [];

  public baseValue: number = 0;
  public baseValueProccessed: string[] = [];

  public total: number = 0;
  public totalProccessed: string[] = [];

  public annualRebate: number = 0;
  public annualRebateProccessed: any[] = [];

  public metEligibilityForm: boolean = false;
  public metQualitativeActions: boolean = false;

  constructor(private followUpService: FollowUpService,
    private rebateLevelService: RebateLevelService,
    private rebateService: RebateService,
    private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadFollowUp();
  }

  ngOnInit(): void {
  }

  public LoadFollowUp(): void {
    this.spinner = true;
    let request = new FollowUpModel();
    request.year = this.year;
    request.ctsId = this.ctsId;
    this.followUpService.GetCustomerFollowUp(request).subscribe(response => {
      this.follow_up = response.result;
      if (this.follow_up != null) {
        this.Brands();
        this.Segments();
        this.rebateLevelService.getRebateLevel(request.ctsId, request.year).subscribe(response => {
          this.rebateLevel = response;
          let customerRebate = new CustomerRebateModel();
          customerRebate.year = this.year;
          customerRebate.ctsId = this.ctsId;
          this.rebateService.GetAllCustomerRebate(customerRebate).subscribe(response => {
            this.customerRebate = response.result;
            let eoyRequest = new EoyRebateExceptionModel();
            eoyRequest.year = this.year;
            eoyRequest.ctsId = this.ctsId;
            this.rebateService.GetAllEOFRebateException(eoyRequest).subscribe(response => {
              this.eoyRebateException = response.result;
              this.spinner = false;
            }, error => {
              console.log(error);
              NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
              this.spinner = false;
            });
          }, error => {
            console.log(error);
            NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
            this.spinner = false;
          });
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Brands() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.brandName === y.brandName) === i
    );
    filter.sort(function (a, b) {
      if (a.brandName < b.brandName) { return -1; }
      if (a.brandName > b.brandName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.brands.push(item.brandName)
    });
  }

  public Segments() {
    let filter = this.follow_up.filter(
      (y, i, arr) => arr.findIndex(x => x.segmentName === y.segmentName) === i
    );
    filter.sort(function (a, b) {
      if (a.segmentName < b.segmentName) { return -1; }
      if (a.segmentName > b.segmentName) { return 1; }
      return 0;
    });
    filter.forEach(item => {
      this.segments.push(item.segmentName)
    });
  }

  public GetBrandData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.brandName == brand);
      if (array != null) {
        let total: number = 0;
        let clearcoatTarget: number = 0;
        let primerTarget: number = 0;
        let excelValue: number = 0;
        let oem: number = 0;
        let segmentName: string;
        array.forEach(item => {
          if (item.type == 'Sales' && item.segmentName.includes('CAR')) {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB') {
              total = total + item.total;
              excelValue = excelValue + item.excelValue;
              oem = oem + item.oem;
              segmentName = 'CAR + CAR GB';
            } else {
              response.push(item);
            }
          }
          else if (item.type == 'Volume' && item.segmentName.includes('CAR')
            || (item.type == 'Volume' && brand == "PPG" && item.segmentName == 'HVLS')) {
            if (item.segmentName == 'CAR' || item.segmentName == 'CAR BR'
              || item.segmentName == 'CAR GB' || item.segmentName == 'HVLS') {
              clearcoatTarget = clearcoatTarget + item.clearcoatTarget;
              primerTarget = primerTarget + item.primerTarget;
              total = clearcoatTarget + primerTarget;
              excelValue = excelValue + item.excelValue
              oem = oem + item.oem;
              segmentName = 'CAR + CAR GB + HVLS';
            } else {
              response.push(item);
            }
          }
        });
        if (total > 0 || excelValue > 0) {
          let data = new FollowUpModel();
          data.type = type;
          data.year = this.year;
          data.ctsId = this.ctsId;
          data.brandName = brand;
          data.segmentName = segmentName;
          data.total = total;
          data.clearcoatTarget = clearcoatTarget;
          data.primerTarget = primerTarget;
          data.excelValue = excelValue;
          data.oem = oem;
          data.archivePercentage = excelValue / total;
          response.push(data);
        }
      }
    }
    return response;
  }

  public GetBrandFilterData(brand: string) {
    let response = this.GetBrandData('Sales', brand);
    let volume = this.GetBrandData('Volume', brand);
    if (response.length > 0) {
      response.forEach(item => {
        if (volume.length > 0) {
          let index = volume.findIndex(x => x.brandName == item.brandName);
          if (index != -1) {
            item.totalVolume = volume[index].total;
            item.excelValueVolume = volume[index].excelValue;
            item.achievedPercentageVolume = volume[index].archivePercentage;
          }
        }
      });
    }
    return response;
  }

  public GetSegmentHVLSData(type: string, brand: string) {
    let response: FollowUpModel[] = [];
    if (brand == 'AutoLux' || brand == 'QuickLine' || brand == 'Valuepro') {
      if (this.follow_up != null) {
        let array = this.follow_up.filter(x => x.type == 'Sales' && x.segmentName.includes('HVLS') && x.brandName == brand);
        if (array != null) {
          let totalHVLS: number = 0;
          let excelValueHVLS: number = 0;
          let oemValueHVLS: number = 0;
          let totalHVLSCOLOR: number = 0;
          let excelValueHVLSCOLOR: number = 0;
          let oemHVLSCOLOR: number = 0;
          array.forEach(item => {
            if (item.type == 'Sales') {
              if (item.segmentName == 'HVLS') {
                totalHVLS = totalHVLS + item.total;
                excelValueHVLS = excelValueHVLS + item.excelValue;
                oemValueHVLS = oemValueHVLS + item.oem;
              }
              if (item.segmentName.toUpperCase() == 'HVLS COLOR') {
                totalHVLSCOLOR = totalHVLSCOLOR + item.total;
                excelValueHVLSCOLOR = excelValueHVLSCOLOR + item.excelValue;
                oemHVLSCOLOR = oemHVLSCOLOR + item.oem;
              }
            }
          });
          if (totalHVLS > 0 || excelValueHVLS > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS';
            data.total = totalHVLS;
            data.excelValue = excelValueHVLS;
            data.oem = oemValueHVLS;
            data.archivePercentage = excelValueHVLS / totalHVLS;
            // response.push(data);
          }
          if (totalHVLSCOLOR > 0 || excelValueHVLSCOLOR > 0) {
            let data = new FollowUpModel();
            data.type = 'Sales';
            data.year = this.year;
            data.ctsId = this.ctsId;
            data.brandName = brand;
            data.segmentName = 'HVLS + HVLS COLOR';
            data.total = totalHVLSCOLOR + totalHVLS;
            data.excelValue = excelValueHVLSCOLOR + excelValueHVLS;
            data.oem = oemHVLSCOLOR;
            data.archivePercentage = data.excelValue / data.total;
            data.hvlsBaseValue = excelValueHVLSCOLOR
            response.push(data);
          }
        }
      }
    }
    return response;
  }

  public GetSegmentCTData(type: string, brand: string) {
    if (brand == 'Delfleet One' || brand == 'Turbo Vision') {
      return this.follow_up.filter(x => x.type == 'Sales' && x.segmentName == 'CT' && x.brandName == brand);
    } else {
      return [];
    }
  }

  public GetSegmentData(type: string, segment: string) {
    let response: FollowUpModel[] = [];
    if (this.follow_up != null) {
      let array = this.follow_up.filter(x => x.type == type && x.segmentName == segment && !x.segmentName.includes('HVLS'));
      if (array != null) {
        array.forEach(item => {
          if (item.segmentName == 'LIC') {
            response.push(item);
          }
        });
      }
    }
    return response;
  }

  public getTotal(data: FollowUpModel[]) {
    let result = data.map(t => t.total).reduce((acc, value) => acc + value, 0);
    let key: string;
    data.forEach(item => {
      if (data.findIndex(x => x.segmentName.includes('HVLS')) !== -1) {
        key = item.type + item.brandName + 'HVLS';
      } else {
        key = item.type + item.brandName + item.segmentName;
      }
      if (this.totalProccessed.findIndex(x => x.includes(key)) == -1) {
        this.totalProccessed.push(key);
        this.total = this.total + result;
      }
    });
    return result;
  }

  public getTotalExcelValue(data: FollowUpModel[]) {
    let response = data.map(t => t.excelValue).reduce((acc, value) => acc + value, 0);
    //let oem = data.map(t => t.oem).reduce((acc, value) => acc + value, 0);
    let key: string;
    data.forEach(item => {
      if (data.findIndex(x => x.segmentName.includes('HVLS')) !== -1) {
        key = item.type + item.brandName + 'HVLS';
      } else {
        key = item.type + item.brandName + item.segmentName;
      }
      if (this.baseValueProccessed.findIndex(x => x.includes(key)) == -1) {
        this.baseValueProccessed.push(key);
        //if (data.findIndex(x => x.segmentName.includes('LIC')) !== -1) {
        // this.baseValue = this.baseValue + response + oem;
        //} else {
        this.baseValue = this.baseValue + response;
        //}
      }
    });
    if (response < 0) response = 0;
    return response;
  }

  public getTotalArchivePercentage(data: FollowUpModel[]) {
    return data.map(t => t.archivePercentage).reduce((acc, value) => acc + value, 0);
  }

  public getAnnualRebate(item: FollowUpModel, code: string, key: string, brand: string, segment: string) {
  
  
    let id: string = code + key;
    let response: number = 0;
    let rebatePercentage = (this.getRebatePercentage(item, code, brand, segment) / 100);
    let rebatePercentageVolume = (this.getRebatePercentageVolume(item, code, brand, segment) / 100);  
  

    if(code.includes("HVLS"))
    {
      response = item.hvlsBaseValue *rebatePercentage;
    }
    else if(key=="Max Meyer")
    {
      response = (item.excelValue+ item.oem) * rebatePercentage ;
    }
    else if (segment.includes("CAR")) {
        response = ((item.excelValue + item.oem) * (rebatePercentage * .65)) + ((item.excelValue + item.oem) * (rebatePercentageVolume * .35));
    }
    else {
      response = ((item.excelValue + item.oem) * rebatePercentage) + ((item.excelValue + item.oem) * rebatePercentageVolume);
    }
    // if (segment.includes("CAR") || segment.includes("CT")) {
    //   response = ((item.excelValue - item.oem) * rebatePercentage) + ((item.excelValue - item.oem) * rebatePercentageVolume);
    // } else {
     // response = ((item.excelValue+ item.oem) * rebatePercentage) + ((item.excelValue+ item.oem) * rebatePercentageVolume);
    // }
    if (response <= 0) {
      response = 0
    } else {
      if (this.annualRebateProccessed.findIndex(x => x.key.includes(code + key)) == -1) {
        this.annualRebateProccessed.push({ key: code + key, value: response });
      }
    }
    return response;
  }

  public getSumAnnualRebate(code: string, key: string) {
    let result = 0;
    if (this.annualRebateProccessed.findIndex(x => x.key.includes(code + key)) == -1) {
      result = 0
    } else {
      let index = this.annualRebateProccessed.findIndex(x => x.key.includes(code + key));
      result = this.annualRebateProccessed[index].value;
    }
    return result;
  }

  public getTotalAnnualRebate() {
    return this.annualRebateProccessed.map(t => t.value).reduce((acc, value) => acc + value, 0);
  }

  public getRebatePercentage(item: FollowUpModel, code: string, brand: string, segment: string) {
    let id: string;
    if (segment == 'LIC') {
      id = code + segment;
    } else {
      id = code + brand;
    }
    let response: number = 0;
    let segmentKey = "";
    if (item.archivePercentage != null) {
      let estimatedRebatePercentage: number = item.archivePercentage * 100;
      if (segment.includes('CAR') || segment == 'CAR + CAR GB + HVLS') segmentKey = 'CAR';
      else if (segment.includes('HVLS')) segmentKey = 'HVLS';
      else if (segment.includes('CT')) segmentKey = 'CT';
      else if (segment.includes('LIC')) segmentKey = 'LIC';
      if (estimatedRebatePercentage >= 90 && estimatedRebatePercentage < 95) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90)[0].rebate;
        }
      }
      if (estimatedRebatePercentage >= 95 && estimatedRebatePercentage < 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95)[0].rebate;
        }
      }
      if (estimatedRebatePercentage >= 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1)[0].rebate;
        }
      }
    }
    if (response < 0) response = 0;
    return response;
  }

  public getRebatePercentageVolume(item: FollowUpModel, code: string, brand: string, segment: string) {
    let id: string;
    if (segment == 'LIC') {
      id = code + segment;
    } else {
      id = code + brand;
    }
    let response: number = 0;
    let segmentKey = "";
    if (item.achievedPercentageVolume != null) {
      let estimatedRebatePercentage: number = item.achievedPercentageVolume * 100;     
      if (segment.includes('CAR') || segment == 'CAR + CAR GB + HVLS') segmentKey = 'CAR';
      else if (segment.includes('HVLS')) segmentKey = 'HVLS';
      else if (segment.includes('CT')) segmentKey = 'CT';
      else if (segment.includes('LIC')) segmentKey = 'LIC';
      if (estimatedRebatePercentage >= 90 && estimatedRebatePercentage < 95) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .90)[0].rebate;
        }
      }
      if (estimatedRebatePercentage >= 95 && estimatedRebatePercentage < 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == .95)[0].rebate;
        }
      }
      if (estimatedRebatePercentage >= 100) {
        if (this.rebateLevel.filter(x => x.year == this.year && x.ctsId == this.ctsId && x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1).length > 0) {
          response = this.rebateLevel.filter(x => x.brand == brand && x.segment.includes(segmentKey) && x.consecutionLevel == 1)[0].rebate;
        }
      }
    }
    if (response < 0) response = 0;
    return response;
  }

  public getConsecutionOnABP() {
    return this.getBaseValue() / this.total;
  }

  public getRebate(baseValue: number, rebatePercentage: number, rebate: CustomerRebateModel) {  
    let response = this.getBaseValue() * (rebatePercentage / 100);
    if (rebate.rebateTypeName == "Platinum Rebate Exclusivity") {
      if (!this.metEligibilityForm) {
        response = 0;
      }
    } else if (rebate.rebateTypeName == "Platinum Functional Rebate") {
      if (!this.metQualitativeActions) {
        response = 0;
      }
    }
    return response;
  }

  public getConsecution() {
    return (this.getBaseValue() / this.total) * 100;
  }

  public getBaseValue() {
    return this.baseValue;
  }

  public getTargetPurchase(item: CustomerRebateModel) {
    return ((this.total * ((item.minConsecutionAbp > 100) ? item.minConsecutionAbp : 100)) / 100);
  }

  public getConsecutionAmmount() {
    return this.baseValue;
  }

  public getRebateTotal(data: CustomerRebateModel[]) {
    let result = data.filter(x => x.rebateTypeName != "Annual Rebate").map(t => this.getRebate(this.getBaseValue(), t.percentage, t)).reduce((acc, value) => acc + value, 0);
    result = result + this.getTotalAnnualRebate();
    return result;
  }
  public GetCurrencyCode() {
    return getLocaleCurrencyCode(this.languageCultureCode);
  }

  public GetCurrencySymbol() {
    return getCurrencySymbol(this.GetCurrencyCode(), "narrow", this.languageCultureCode);
  }

  public GetCustomerRebates() {
    if (this.customerRebate.findIndex(x => x.rebateTypeName == "Annual Rebate") == -1) {
      let annualRebate = new CustomerRebateModel();
      annualRebate.year = this.year;
      annualRebate.ctsId = this.ctsId;
      annualRebate.rebateTypeId = 0;
      annualRebate.rebateTypeName = "Annual Rebate";
      this.customerRebate.unshift(annualRebate);
    }
    return this.customerRebate;
  }

  public GetCellBaseValue(code: string, excelValue: number, oem: number) {
    if (code.includes('CAR')
      || code.includes('CT')) {
      return excelValue + oem;
    } else {
      return excelValue;
    }
  }

  public FilterCustomerRebates(item: CustomerRebateModel) {
    let response: CustomerRebateModel[] = []
    if (item.rebateTypeName != "Annual Rebate") {
      response.push(item);
    }
    return response;
  }

  public SaveRebateException(code: string) {
    let id: string = code;
    let value: number = 0;
    let request = new EoyRebateExceptionModel();
    if ((<HTMLInputElement>document.getElementById(id)) != null) {
      value = Number(((<HTMLInputElement>document.getElementById(id)).value));
    }
    request.year = this.year;
    request.ctsId = this.ctsId;
    request.code = code;
    request.userId = GlobalVariablesService.getUserId();
    request.rebateAmmount = value;
    this.rebateService.CreateUpdateEOYRebateException(request).subscribe(response => {
      this.rebateService.GetAllEOFRebateException(request).subscribe(response => {
        this.eoyRebateException = response.result;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      });
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
    });
  }
  public GetRebateException(code: string) {
    let response: EoyRebateExceptionModel;
    if (response != null) {
      response = this.eoyRebateException.filter(x => x.code == code)[0];
      return response.rebateAmmount;
    } else {
      return 0;
    }
  }

  public GetColumns(code: string, key: string) {
    let response = [];
    if (code == "CAR") {
      if (key == "Max Meyer") {
        response = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "oem", "baseValue", "rebatePercentage", "annualRebate"];
      } else {
        response = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "achievedPercentageVolume", "oem", "baseValue", "rebatePercentage", "rebatePercentageVolume", "annualRebate"];
      }
    }
    if (code == "HVLS") {
      response = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "baseValue", "rebatePercentage", "annualRebate"];
    }
    if (code == "CT") {
      response = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "oem", "baseValue", "rebatePercentage", "annualRebate"];
    }
    if (code == "LIC") {
      response = ["brandName", "segmentName", "total", "excelValue", "archivePercentage", "baseValue", "rebatePercentage", "annualRebate"];
    }
    if (this.GetRebateException(code + key) > 0) {
      response.push("rebateException")
    }
    return response;
  }

  public IsValidColumn(code: string, key: string, column: string) {
    let columns = this.GetColumns(code, key);
    if (columns.indexOf(column) != -1) {
      return true;
    } else {
      return false;
    }
  }

  public GetRebateColumns(item: CustomerRebateModel) {
    let response = [];
    if (item.rebateTypeName == "Platinum Rebate Exclusivity") {
      response = ["rebateTypeName", "exclusiveDistributor", "minConsecutionAbp", "targetPurchase", "consecutionABP", "ammountConsecutionABP", "baseValue", "percentage", "rebate"];
    }
    if (item.rebateTypeName == "Platinum Plus Rebate") {
      response = ["rebateTypeName", "minConsecutionAbp", "targetPurchase", "consecutionABP", "ammountConsecutionABP", "baseValue", "percentage", "rebate"];
    }
    if (item.rebateTypeName == "Platinum Functional Rebate") {
      response = ["rebateTypeName", "qualitativeActions", "minConsecutionAbp", "targetPurchase", "consecutionABP", "ammountConsecutionABP", "baseValue", "percentage", "rebate"];
    }
    return response;
  }

  public IsValidColumnRebates(item: CustomerRebateModel, column: string) {
    let columns = this.GetRebateColumns(item);
    if (column.indexOf(column) != -1) {
      return true;
    } else {
      return false;
    }
  }

  public showRequirements(item: CustomerRebateModel) {
    let response: boolean = false;
    if (item.rebateTypeName == "Platinum Rebate Exclusivity") {
      response = true;
    }
    if (item.rebateTypeName == "Platinum Functional Rebate") {
      response = true;
    }
    return response;
  }

  public GetRequirementTypeId(item: CustomerRebateModel) {
    let response = 0;
    if (item.rebateTypeName == "Platinum Rebate Exclusivity") {
      response = 1;
    }
    if (item.rebateTypeName == "Platinum Functional Rebate") {
      response = 2;
    }
    return response;
  }

  public GetMetAllRequirements(data: boolean, rebate: CustomerRebateModel) {
    if (rebate.rebateTypeName == "Platinum Rebate Exclusivity") {
      this.metEligibilityForm = data;
    }
    if (rebate.rebateTypeName == "Platinum Functional Rebate") {
      this.metQualitativeActions = data;
    }
  }
}
