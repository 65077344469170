import { NotificationService } from './../../../core/services/notification-service';
import { Component, OnInit } from '@angular/core';

import { CustomerService } from './../../customer/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariablesService } from './../../../core/services/global-variables.service';

import { LocationModel } from './../../customer/models/location-model';
import { CustomerModel } from './../../customer/models/customer-model';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  public spinner = false;
  public ctsId: number;
  public locations: LocationModel[] = [];
  public locationsColumns = ["companyName", "address", "type"];

  constructor(private customerService: CustomerService, private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.LoadLocations();
  }


  ngOnInit(): void {
  }

  public LoadLocations(): void {
    this.spinner = true;
    let request = new CustomerModel();
    request.ctsId = this.ctsId;
    this.customerService.GetLocationsByCTSId(request).subscribe(response => {
      this.locations = response.result;
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }
}