
import Swal from 'sweetalert2';

export class NotificationService {
    private static time: number = 2000;
    public static error(tittle: string, message: string) {
        Swal.fire({
            icon: 'error',
            title: tittle,
            text: message,
            timer: this.time,
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    public static warning(tittle: string, message: string) {
        Swal.fire({
            icon: 'warning',
            title: tittle,
            text: message,
            timer: this.time,
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    public static success(tittle: string, message: string) {
        Swal.fire({
            icon: 'success',
            title: tittle,
            text: message,
            timer: this.time,
            showCancelButton: false,
            showConfirmButton: false
        });
    }
}