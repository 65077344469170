import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { Observable } from 'rxjs';
import { CustomerClauseHeaderModel } from '../../models/customer-clause-header.model';
import { CustomerClauseSelectionModel } from '../../models/customer-clause-selection.model';
import { CustomerClauseModel } from '../../models/customer-clause.model';
import { AnnualBusinessPlanService } from '../../services/annual-business-plan.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clause-selection',
  templateUrl: './clause-selection.component.html',
  styleUrls: ['./clause-selection.component.scss']
})
export class ClauseSelectionComponent implements OnInit {
  @Input() IsReadOnly: Boolean = false;
  public IsPPGGuest=GlobalVariablesService.IsPPGGuest();
  public clauses: CustomerClauseModel[];
  public dataSource: MatTableDataSource<CustomerClauseModel>;
  public clauseHeader: CustomerClauseHeaderModel;
  public ctsId: number;
  public year: number;
  public clauseSelection: SelectionModel<CustomerClauseModel> = new SelectionModel<CustomerClauseModel>();
  public clauseSelected: Observable<boolean>;
  constructor(private clauseService: AnnualBusinessPlanService, private translateService: TranslateService) { }

  displayedColumns: string[] = ['selected', 'description'];

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if(this.IsPPGGuest)
    {
      this.IsReadOnly=true;
    }
    if (this.ctsId == 0) {
      NotificationService.warning("Error", this.translateService.instant("Select a CTS Id to continue!"))
    }
    else if (this.year == 0) {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    else {
      this.loadTable();
    }    

  }

  loadTable(): void {
    this.clauseService.getClausesForSelection(this.ctsId, this.year).subscribe(x => {
      this.clauseHeader = x;
      this.clauses = this.clauseHeader.clauses;
      this.dataSource = new MatTableDataSource<CustomerClauseModel>(this.clauseHeader.clauses);
      this.clauseSelection = new SelectionModel<CustomerClauseModel>(true, this.clauses.filter(x => {
        if (x.selected) {
          this.clauseSelected = Observable.of(true);
          return x;
        }
      }))
    });
  }
  // dataSource = ELEMENT_DATA;

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
  isAllSelected() {
    const numSelected = this.clauseSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.clauseSelection.clear() :
      this.dataSource.data.forEach(row => this.clauseSelection.select(row));
  }
  onSaveClick($event) {
    if (!this.IsReadOnly) {
    let selections: CustomerClauseSelectionModel[] = new Array();
    this.clauseSelection.selected.forEach( x => {
      let selection = new CustomerClauseSelectionModel();
      selection.ctsId = this.ctsId;
      selection.detailId = x.id;
      selections.push(selection);
    });

    if (selections.length <= 1) {
      console.log(selections);
      let noSelection = new CustomerClauseSelectionModel();
      noSelection.ctsId = this.ctsId;
      noSelection.detailId = 0;
      selections.push(noSelection);
    }
    this.clauseService.saveCustomerSelection(selections).subscribe(response => {
      if (response == true) {
        NotificationService.success(null,this.translateService.instant("Save Complete"));
      }
    });
  }
}
}


