import { environment as localhost } from './environment.localhost';
import { environment as development } from './environment.development';
import { environment as staging } from './environment.staging';
import { environment as production } from './environment.production';

export const environment = getEnvironment();

function getEnvironment() {

  if (window.location.origin.includes("localhost")) {
    return localhost;
  } else if (window.location.origin.includes("platinumprogressdev")) {
    return development;
  } else if (window.location.origin.includes("platinumprogressstg")) {
    return staging;
  } else if (window.location.origin.includes("platinumprogressprd")) {
    return production;
  } else if (window.location.origin.includes("progresstool")) {
    return production;
  }
}