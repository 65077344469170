import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { ServiceCallResult } from './../../../core/models/service-call-result';

import { TechnicalAssistanceModel } from './../models/technical-assistance.model';
import { NumberFormatService } from './../../../core/services/number-format-service';

@Injectable({
    providedIn: 'root'
})
export class TechnicalAssistanceService {

    constructor(private http: HttpClient) { }

    public async GetAllTechnicalAssistanceEdit(request: TechnicalAssistanceModel): Promise<Observable<TechnicalAssistanceModel[]>> {
        var results = await(await this.GetAllTechnicalAssistanceResponse(request)).toPromise();
        for(let i = 0; i < results.length; i++) {
            if (results[i].annualAmount != null) {
                results[i].annualAmountFormatted = NumberFormatService.numberWithCommas(results[i].annualAmount.toString());
            }
        }
        console.log(results);
        return of(results);
    }
    private async GetAllTechnicalAssistanceResponse(request: TechnicalAssistanceModel): Promise<Observable<TechnicalAssistanceModel[]>> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TechnicalAssistance/GetAllTechnicalAssistance", request).pipe(map(response => response.result));
    }
    public GetAllTechnicalAssistance(request: TechnicalAssistanceModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TechnicalAssistance/GetAllTechnicalAssistance", request).pipe(map(response => response));
    }

    public CreateUpdate(request: TechnicalAssistanceModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TechnicalAssistance/CreateUpdate", request).pipe(map(response => response));
    }

    public Delete(request: TechnicalAssistanceModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TechnicalAssistance/Delete", request).pipe(map(response => response));
    }
}