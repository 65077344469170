<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <!-- <h3 class="center animated rollIn delay-1s">{{title}}</h3>
    <br> -->
  <!-- <h4><u>{{this.distributorName}}</u></h4> -->
  <!-- <br> -->
  <mat-form-field>
    <mat-label>{{"Search"|translate}}..</mat-label>
    <input matInput (keyup)="SearchGrid($event)">
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">

    <ng-container matColumnDef="typeOfAgreement">
      <th mat-header-cell *matHeaderCellDef>{{"Type Of Agreement"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.typeOfAgreement" ngDefaultControl>
        {{element.typeOfAgreement}}
    </ng-container>

    <ng-container matColumnDef="annualValue">
      <th mat-header-cell *matHeaderCellDef>{{"Annual Value"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.annualValue" ngDefaultControl>
        {{element.annualValue}}
      </td>
    </ng-container>

    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef>{{"Details"|translate}}</th>
      <td mat-cell *matCellDef="let element" [(ngModel)]="element.details" ngDefaultControl>
        {{element.details}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>