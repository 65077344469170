<div class="container">
    <p style="font-style:unset;">{{title|translate}}</p>   
    <div class="row">
        <div class="col-sm">          
                <mat-label style="font-weight:bold;">{{"Min Consecution on ABP"|translate}} : </mat-label>
                {{rebate.minConsecutionAbp}}%                           
        </div>
        <div class="col-sm">         
                <mat-label style="font-weight:bold;"> {{"Rebate"|translate}} % : </mat-label>
                {{rebate.percentage|number:'1.2-2':languageCultureCode}}%            
        </div>  
        <div class="col-sm">        
                <mat-label style="font-weight:bold;">{{"Rebate Target"|translate}} : </mat-label>
                {{customerRebate.baseValue| number:'1.2-2':languageCultureCode}}        
        </div>
    </div>    
</div>