import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { SalesTargetModel } from '../../models/sales-target.model';
import { GoalMessageService } from '../../services/goal-message.service';
import { TargetService } from '../../services/target.service';

@Component({
  selector: 'app-sales-goal-entry',
  templateUrl: './sales-goal-entry.component.html',
  styleUrls: ['./sales-goal-entry.component.scss']
})
export class SalesGoalEntryComponent implements OnInit {
  @Input() public salesTargets: SalesTargetModel[];
  @Input() IsReadOnly: Boolean = false;
  public ctsId: number;
  public year: number;
  public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();

  public targets: SalesTargetModel[];
  displayedColumns = ['brand', 'segment', 'total'];

  constructor(private targetService: TargetService, private goalMessageService: GoalMessageService) { }

  ngOnInit() {
    this.loadTargets();
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year=GlobalVariablesService.getYear();
  }

  loadTargets(): void {
    this.targets = this.salesTargets;
  }

  getTotal() {
    return this.targets.map(t => t.target).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
  onchange($event, data: SalesTargetModel) {
    data.ctsId = this.ctsId;
    data.year=this.year;
    this.targetService.saveSalesTarget(data).subscribe(x => {

    });
    this.goalMessageService.sendMessage('changed');
  }

}



export interface Target {
  segment: string;
  id: number;
  total: number;

}

const TARGET_DATA: Target[] = [
  { segment: 'HVLS', id: 1, total: 75 },
  { segment: 'HVLS COLOR', id: 2, total: 0 }
];