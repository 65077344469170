import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from '../../../../core/services/notification-service';
import { CustomerModel } from '../../../customer/models/customer-model';
import { CustomerService } from '../../../customer/services/customer.service';
import { MarketingModel } from '../../models/marketing.model';
import { MarketingService } from '../../services/marketing.service';

@Component({
  selector: 'app-marketing-display',
  templateUrl: './marketing-display.component.html',
  styleUrls: ['./marketing-display.component.scss']
})
export class MarketingDisplayComponent implements OnInit {

  public title: string = "Marketing Actions and Promotions";
  public displayedColumns = ["typeOfAgreement", "annualValue", "details"];
  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public marketingOptions: MarketingModel[];
  public dataSource = new MatTableDataSource<MarketingModel>();
  public marketingOption = new MarketingModel();
  public spinner = false;
  public addMarketingOptions = false;
  public addRow: MarketingModel[];
  public year: number = 0;
  public ctsId: number;

  constructor(private marketingService: MarketingService, public customerService: CustomerService, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.addRow = [new MarketingModel()];
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId > 0) {
      //this.GetCustomerById();
      this.GetMarketingOptions();
    }
  }

  public async GetMarketingOptions() {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.addMarketingOptions = false;
      this.marketingOption.year = this.year;
      this.marketingOption.ctsId = this.ctsId;
      (await this.marketingService.GetMarketingOptions(this.marketingOption)).subscribe(response => {
        this.marketingOptions = response;
        this.dataSource.data = this.marketingOptions;
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
    else {
      NotificationService.error("Error", this.translateService.instant("Select a CTS Id to continue!"))
    }
  }
}
