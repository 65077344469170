<mat-table #table [dataSource]="targets" class="mat-elevation-z8">
  <ng-container matColumnDef="brand">
    <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let target">{{ target.brandName }}</mat-cell>
    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="segment">
    <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let target">{{ target.segmentName }}</mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef>{{"Total"|translate}} (€)</mat-header-cell>
    <mat-cell *matCellDef="let target">   
        <input matInput matInputSeparatorsDirective placeholder="0" (change)="onchange($event, target)" [value]="target.targetFormatted" [(ngModel)]="target.targetFormatted" [disabled]="IsReadOnly">   
    </mat-cell>
    <mat-footer-cell *matFooterCellDef> {{ getTotal()| number:'1.0' :languageCultureCode }}</mat-footer-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
</mat-table> 
