import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TechnicalAssistanceService } from './../../services/technical-assistance.service';

import { TechnicalAssistanceModel } from './../../models/technical-assistance.model';
import { TranslateService } from '@ngx-translate/core';
import { NumberFormatService } from './../../../../core/services/number-format-service';

@Component({
  selector: 'app-technical-assistance',
  templateUrl: './technical-assistance.component.html',
  styleUrls: ['./technical-assistance.component.scss']
})
export class TechnicalAssistanceComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() IsReadOnly: Boolean = false;

  public spinner = false;
  public title: string = "Technical Assistance";

  public year: number = 0;

  public ctsId: number;
  public showAddCancel = false;

  public technicalAssistance: TechnicalAssistanceModel[];
  public technicalAssistanceFilter = new MatTableDataSource<TechnicalAssistanceModel>();
  public addrow: TechnicalAssistanceModel[];
  public periodicityOptions: String[] = ["Quarterly", "Annual"];

  public startDate = new FormControl('', [Validators.required]);
  public endDate = new FormControl('', [Validators.required]);
  public technician = new FormControl('', [Validators.required]);
  public annualAmount = new FormControl('', [Validators.required]);
  public periodicity = new FormControl('', [Validators.required]);
  public detail = new FormControl('', [Validators.required]);

  public addColumns = ["col1", "col2", "col3", "actions"];
  public technicalAssistanceColumns = ["startDate", "endDate", "technician", "annualAmount", "periodicity", "detail", "actions"];

  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();

  constructor(private technicalAssistance_service: TechnicalAssistanceService, private route: ActivatedRoute,private translateService:TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadData();
  }

  ngOnInit(): void {
  }

  public async LoadData() {

    let request = new TechnicalAssistanceModel();
    request.year = this.year;
    request.ctsId = this.ctsId;
    this.spinner = true;
    this.showAddCancel = false;
    (await this.technicalAssistance_service.GetAllTechnicalAssistanceEdit(request)).subscribe(response => {
      this.technicalAssistance = response;
      this.technicalAssistanceFilter.data = this.technicalAssistance;
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.technicalAssistanceFilter.filter = filterValue.trim().toLowerCase();
  }

  public ShowAddCancel() {
    this.addrow = [new TechnicalAssistanceModel()];
    this.showAddCancel = !this.showAddCancel;
  }

  public getErrorMessage(element: string) {
    return this.translateService.instant('Missing')+' ' + this.translateService.instant(element);
  }

  public CreateUpdate(request: TechnicalAssistanceModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      request.year = this.year;
      request.ctsId = this.ctsId;
      console.log(request.startDate);
      console.log(request.endDate);

      request.annualAmount = parseInt(NumberFormatService.removeSeparators(request.annualAmountFormatted));
      this.technicalAssistance_service.CreateUpdate(request).subscribe(response => {
        if (!response.success) {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
        NotificationService.success(null, this.translateService.instant("Saved"));
        this.LoadData();
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public Delete(request: TechnicalAssistanceModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      this.technicalAssistance_service.Delete(request).subscribe(response => {
        if (response.success) {
          NotificationService.success(null, this.translateService.instant("Deleted"));
          this.LoadData();
        } else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }
}
