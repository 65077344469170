import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { ServiceCallResult } from './../../../core/models/service-call-result';

import { TargetAccountModel } from './../models/target-account.model';
import { NumberFormatService } from './../../../core/services/number-format-service';

@Injectable({
    providedIn: 'root'
})
export class TargetAccountService {

    constructor(private http: HttpClient) { }

    public async GetAllTargetAccounts(request: TargetAccountModel): Promise<Observable<TargetAccountModel[]>> {
        var results = await (await this.getAllTargetAccountsResponse(request)).toPromise();
        for (let i = 0; i < results.length; i++) {
            if (results[i].potentialPurchases != null) {
                results[i].potentialPurchasesFormatted = NumberFormatService.numberWithCommas(results[i].potentialPurchases.toString());
            }
            if (results[i].shopGrossSales != null) {
                results[i].shopGrossSalesFormatted = NumberFormatService.numberWithCommas(results[i].shopGrossSales.toString());
            }
        }
        console.log(results);
        return of(results);
    }
    private async getAllTargetAccountsResponse(request: TargetAccountModel): Promise<Observable<TargetAccountModel[]>> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TargetAccount/GetAllTargetAccounts", request).pipe(map(response => response.result));
    }
    public GetAllTargetAccountsForDisplay(request: TargetAccountModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TargetAccount/GetAllTargetAccounts", request).pipe(map(response => response));
    }
    public CreateUpdate(request: TargetAccountModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TargetAccount/CreateUpdate", request).pipe(map(response => response));
    }

    public Delete(request: TargetAccountModel): Observable<ServiceCallResult> {
        return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "TargetAccount/Delete", request).pipe(map(response => response));
    }
}