import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {
  private loading$ = new BehaviorSubject<boolean>(false);
  public loading = this.loading$.asObservable();

  private message$ = new BehaviorSubject<string>('');
  public message = this.message$.asObservable();

  constructor() { }

  /**
   * Show/hide page level loading icon
   * @param loading true to show, false to hide
   */
  public isLoading(loading: boolean): void {
    this.loading$.next(loading);
  }

  /**
   * Display a message on the page level loading screen
   * @param message Message to display to user
   */
  public displayMessage(message: string = ''): void {
    this.message$.next(message);
  }
}
