import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVariablesService } from '../../../../../core/services/global-variables.service';
import { BusinessPlanClauseModel } from '../../../models/business-plan-clause.model';
import { AnnualBusinessPlanService } from '../../../services/annual-business-plan.service';
import { BusinessPlanClauseDetailModalComponent } from '../business-plan-clause-detail-modal/business-plan-clause-detail-modal.component';
import { NotificationService } from './../../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-business-plan-clause-modal',
  templateUrl: './business-plan-clause-modal.component.html',
  styleUrls: ['./business-plan-clause-modal.component.scss']
})
export class BusinessPlanClauseModalComponent implements OnInit {

  public detailText: string;
  public form: FormGroup;
  constructor(public dialogRef: MatDialogRef<BusinessPlanClauseDetailModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,private translateService: TranslateService,
              private planService: AnnualBusinessPlanService) {}

  ngOnInit(): void {
  }

  async onSaveClick($event: any): Promise<any> {
    let clause = new BusinessPlanClauseModel();
    clause.year=GlobalVariablesService.getYear();
    clause.name = this.detailText;
    if (clause.name!=undefined && clause.name.trim()!="")
    {
    let result = await this.planService.addClause(clause).toPromise();    
    NotificationService.success(null,this.translateService.instant("Saved"));
  }
  else
  {
    NotificationService.warning(null,this.translateService.instant("Error"));
  }
    this.dialogRef.close();
  }
  onCancelClick($event): void {
    this.dialogRef.close();
  }

}
