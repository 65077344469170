import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from './../../../../core/services/notification-service';
import { CustomerModel } from './../../../customer/models/customer-model';
import { ContractsModel } from './../../../rebate/models/contracts.model';
import { CustomerService } from './../../../customer/services/customer.service';
import { ContractsService } from './../../../rebate/services/contracts.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { ContractsModalComponent } from '../../components/modals/contracts-modal/contracts-modal.component';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { FileAttachmentModel } from '../../models/file-attachment.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public ctsId: number;
  public IsPPGAdmin = GlobalVariablesService.IsPPGAdmin();
  public IsReadOnly :boolean=false;
  public title: string = "Contracts"
  public dataSource = new MatTableDataSource<ContractsModel>();
  public spinner: boolean = false;
  public displayedColumns = ["status", "type", "subType", "brand", "startDate", "endDate", "fileName", "actions"];
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  blob: Blob;


  constructor(private route: ActivatedRoute, private contractService: ContractsService, private customerService: CustomerService, private dialog: MatDialog, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();   
    if(!this.IsPPGAdmin)
    {
      this.IsReadOnly=true;
    }
    if (this.ctsId != 0) {
      this.GetCustomerById();  
    }
    else
    {
    NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName = (this.customer.companyName == null ? '' : this.customer.companyName);
              this.GetCustomerContracts();

          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
            this.spinner = false;
          }
         
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }

  public GetCustomerContracts(): void {
    this.spinner = true;
    try {
      let request = new ContractsModel();
      request.ctsId = this.ctsId;
      request.userLanguageId=Number(sessionStorage.getItem("LanguageId"));
      this.contractService.GetCustomerContracts(request).subscribe(reponse => {
        if (reponse.success) {
          console.log(reponse.result);
          this.dataSource.data = reponse.result;
        } else {
          NotificationService.error(null, this.translateService.instant("Internal Error"));
        }
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }

  public SearchGrid(event: Event) {
    const searchkey = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchkey.trim().toLowerCase();
  }

  public IsVisible(row: ContractsModel) {      
    if (row.platinumUploadFlag == 1)   
      return true;
    else
      return false;
  }

  public UploadFile(file: ContractsModel) {
    console.log(file);
    let dialogRef: MatDialogRef<ContractsModalComponent> = this.dialog.open(ContractsModalComponent, {
      width: '350px',
      height: '350px',
      panelClass: 'my-dialog',
    
      data: { contractId: file.contractType, customerContractDataID:file.customerContractDataID, fileId: file.fileID, ctsId: file.ctsId }
    });

  }

  public DeleteFile(file: ContractsModel) {
    if (isNullOrEmptyString(file.fileName)) {
      NotificationService.error(this.translateService.instant("Error"), this.translateService.instant("No file available to delete"));
    }
    else {
      this.spinner = true;
      this.contractService.DeleteFile(file).subscribe(response => {
        if (response.success) {
          NotificationService.success(this.translateService.instant("Deleted"), this.translateService.instant("File Removed"));
          this.GetCustomerContracts();         
        } else {
          NotificationService.error(null, response.message);        
        }
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public DownloadFile(file: ContractsModel) {
    this.spinner = true;
    let fileAttachment = new FileAttachmentModel();
    console.log(file.contractSubType+"File");
    fileAttachment.fileId = file.fileID;
    fileAttachment.fileName = file.fileName;
    fileAttachment.fileContent = file.fileContent;
    fileAttachment.contractType =file.contractType;
    fileAttachment.contractSubType=file.contractSubType; 
    this.contractService.download(fileAttachment).subscribe(blob =>
      this.fileDownload(blob, "application/pdf"));
      this.spinner = false;
  }
  fileDownload(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert(this.translateService.instant('Please disable your Pop-up blocker and try again.'));
    }
  }
}
