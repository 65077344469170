<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <!-- <h3 class="center">{{title}}</h3>
  <br>
  <h4><u>{{this.distributorName}}</u></h4>
  <br> -->

  <div>
    <button *ngIf="!AddOrDeleteFlag" mat-button (click)="AddOrDeleteDevPlans()"
      [disabled]="(!(year > 0)) || IsReadOnly"> {{"Map Development Plans to a location"|translate}}
      <span class="material-icons">add_box</span>
    </button>

    <div *ngIf="AddOrDeleteFlag">
      <table mat-table [dataSource]="addRow" class="add_grid">
        <ng-container matColumnDef="shopCtsId">
          <th mat-header-cell *matHeaderCellDef>{{"Shop/Dealership Location"|translate}}</th>
          <td mat-cell *matCellDef="let item">
            <mat-form-field>
              <mat-label>{{"Select a location"|translate}}..</mat-label>
              <mat-select [(value)]="item.shopCtsId" [(ngModel)]="item.shopCtsId" [formControl]="shopCtsId" required>
                <form>
                  <mat-form-field class="w-100">
                    <input type="text" (keyup)="FilterShop($event.target.value)" matInput>
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </form>
                <mat-option *ngFor="let shop of this.shopSearch" [value]="shop.shopId">
                  {{shop.shopId}} - {{shop.strCompanyName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="devPlanId">
          <th mat-header-cell *matHeaderCellDef>{{"Development Plan"|translate}}</th>
          <td mat-cell *matCellDef="let item">
            <mat-form-field>
              <mat-label>{{"Select a plan"|translate}}..</mat-label>
              <mat-select [(value)]="item.devPlanId" [(ngModel)]="item.devPlanId" [formControl]="devPlanId" required>
                <mat-option *ngFor="let plan of this.devplans" [value]="plan.id">
                  {{plan.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef>{{"Comments"|translate}}</th>
          <td mat-cell *matCellDef="let item">
            <mat-form-field class="form_field">
              <input [value]="item.comments" [(ngModel)]="item.comments" matInput
                placeholder='{{"Enter Comments"|translate}}..' type="text" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-button  (click)="CreateUpdate(row)">
              <span class="material-icons">save</span>
            </button>
            <button *ngIf="AddOrDeleteFlag" mat-button (click)="AddOrDeleteDevPlans()"><span
                class="material-icons">cancel</span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
      </table>
    </div>

    <br><br>
    <mat-form-field>
      <mat-label>{{"Select a location"|translate}}..</mat-label>
      <input matInput (keyup)="SearchGrid($event)">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">

      <ng-container matColumnDef="shopId">
        <th mat-header-cell *matHeaderCellDef>{{"Shop/Dealership Location"|translate}}</th>
        <td mat-cell *matCellDef="let element" [(ngModel)]="element.shopCtsId" ngDefaultControl>
          {{element.shopCtsId}} - {{element.shopName}}
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{"Plan Description"|translate}}</th>
        <td mat-cell *matCellDef="let element" [(ngModel)]="element.planDescription" ngDefaultControl>
          {{element.planDescription}}
        </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef>{{"Comments"|translate}}</th>
        <td mat-cell *matCellDef="let element" [(ngModel)]="element.comments" ngDefaultControl>{{element.comments}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
        <td mat-cell *matCellDef="let row">
          <button mat-button (click)="Delete(row)" [disabled]="IsReadOnly"><span
              class="material-icons">remove_circle</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>