export class RebateModel {
    id: number;
    rebateTypeId: number;
    year: number;
    details: string;
    percentage: number;
    minConsecutionAbp: number;
    active: boolean;
    createDate: Date;
    createdBy: number;
}
