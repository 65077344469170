import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import * as JWT from 'jwt-decode';

import { environment } from '../environments/environment';
import { SsoCallback } from './core/security/sso-callback';
import { ServiceCallResult } from './core/models/service-call-result';

import { GlobalVariablesService } from './core/services/global-variables.service';
import { NotificationService } from './core/services/notification-service';
import { CustomerService } from './modules/customer/services/customer.service';
import { UserService } from './modules/users/services/user.service';

import { MLlabelsModel } from './modules/users/models/mlLabels-model';
import { LanguagesModel } from './modules/users/models/languages-model';
import { CustomerModel } from './modules/customer/models/customer-model';
import { UserModel } from './modules/users/models/user-model';

import language_es from '@angular/common/locales/es';
import { UserVPRoleModel } from './modules/users/models/user-vprole.model';

registerLocaleData(language_es);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public spinner: boolean = false;
  public titleDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  public authenticated: boolean = false;

  public ctsId: number;
  public year: number;
  public years: number[] = [];
  public leftMenu: boolean = true;
  public navbarOpen: boolean = false;
  public showMaintenance: boolean = false;
  public showOther: boolean = false;

  public customers: CustomerModel[] = [];
  public customersFilter: CustomerModel[] = [];

  public profile: any;
  public message: number;
  public done: number = 1;
  public userLanguage: string;
  public user: UserModel = new UserModel();
  public languages: LanguagesModel[] = [];
  public label: MLlabelsModel = new MLlabelsModel();
  mySubscription: any;

  constructor(private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private http: HttpClient, private router: Router,
    private userService: UserService,
    private customerService: CustomerService) {
    this.ctsId = sessionStorage.getItem("CTSID") != "" ? Number(sessionStorage.getItem("CTSID")) : 0;
    this.year = sessionStorage.getItem("Year") != "" ? Number(sessionStorage.getItem("Year")) : 0;
    this.years = GlobalVariablesService.getYears();
    if (sessionStorage.getItem('LanguageCultureCode') != "" && sessionStorage.getItem('LanguageCultureCode') != undefined) {
      localStorage.setItem('LanguageCultureCode', sessionStorage.getItem('LanguageCultureCode'));
    }
    else {
      translateService.setDefaultLang('es');
      translateService.use('es')
      localStorage.setItem('LanguageCultureCode', 'es');
    }
    this.LoadAPIToken();
    this.loadUserType();
    this.user.userId = parseInt(sessionStorage.getItem("UserId"));
    this.user.languageCultureCode = sessionStorage.getItem("LanguageCultureCode");
    this.user.userTypeId = parseInt(sessionStorage.getItem("UserTypeId"));
    this.user.languageId = parseInt(sessionStorage.getItem("LanguageId"));
    if (sessionStorage.getItem("LanguageId") != undefined && sessionStorage.getItem("UserId") != undefined) {
      this.fetchLanguages(this.user);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  public LogIn() {
    let apiToken = sessionStorage.getItem('ApiToken');
    if (window.location.href.includes('#access_token') && apiToken != null) {
      let queryParams = window.location.href.split('#')[1];
      let ADFS: SsoCallback = this.QueryStringToJSON(queryParams);
      let access_token: any = JWT(ADFS.access_token);
      sessionStorage.setItem('AwareToken', ADFS.access_token);
      sessionStorage.setItem('Profile', JSON.stringify(access_token));
    } else {
      if (!this.IsValidToken(sessionStorage.getItem('AwareToken'))) {
        sessionStorage.clear();
        window.location.href = environment.sso;
      }
    }
  }

  public LoadAPIToken() {
    this.spinner = true;
    if (window.location.href.includes('#access_token')) {
      let queryParams = window.location.href.split('#')[1];
      let ADFS: SsoCallback = this.QueryStringToJSON(queryParams);
      let access_token: any = JWT(ADFS.access_token);
      console.log(access_token);
      sessionStorage.setItem('AwareToken', ADFS.access_token);
      sessionStorage.setItem('Profile', JSON.stringify(access_token));
    }
    let awareToken = sessionStorage.getItem('AwareToken');
    let apiToken = sessionStorage.getItem('ApiToken');

    const params = new HttpParams().set('token', awareToken);
    console.log(environment.apiEndpoint);
    if (awareToken != null) {
      if (apiToken == null) {
        this.http.post<ServiceCallResult>(environment.apiEndpoint + 'authorization/authorize', params)
          .subscribe(response => {
            if (response.success) {
              sessionStorage.setItem('ApiToken', response.result.ApiToken);
              sessionStorage.setItem('UserId', response.result.UserId);
              sessionStorage.setItem('UserTypeId', response.result.UserTypeId);
              sessionStorage.setItem('UserTypeName', response.result.UserTypeName);
              sessionStorage.setItem('LanguageId', response.result.LanguageId);
              sessionStorage.setItem('LanguageCultureCode', response.result.LanguageCultureCode);
              sessionStorage.setItem('EmailAddress', response.result.EmailAddress);
              sessionStorage.setItem('UserTypePermissions', response.result.UserTypePermissions);
              sessionStorage.setItem('LanguageId', response.result.LanguageId);
              this.user.userId = parseInt(response.result.UserId);
              this.user.languageCultureCode = response.result.LanguageCultureCode;
              this.user.userTypeId = parseInt(response.result.UserTypeId);
              this.user.languageId = parseInt(response.result.LanguageId);
              this.translateService.setDefaultLang(sessionStorage.getItem('LanguageCultureCode'));
              localStorage.setItem("LanguageCultureCode", sessionStorage.getItem('LanguageCultureCode'));
              this.translateService.use(sessionStorage.getItem('LanguageCultureCode'));
              if (sessionStorage.getItem("LanguageId") != undefined && sessionStorage.getItem("UserId") != undefined) {
                this.fetchLanguages(this.user);
              }
              let userTypeId = parseInt(sessionStorage.getItem("UserTypeId"));
              if (userTypeId === 3 || userTypeId === 4 || userTypeId === 5) {
                this.loadUserType();
              }
              this.Authenticated();
            } else {
              console.log(response);
              this.AuthenticationError();
            }
          }, error => {
            console.log(error);
            this.AuthenticationError();
          });
      }
    } else {
      this.spinner = false;
    }
  }

  private async loadUserType() {
    let userTypeId = parseInt(sessionStorage.getItem("UserTypeId"));

    if (userTypeId === 3 || userTypeId === 4 || userTypeId === 5) {
      var networkId =  GlobalVariablesService.getNetworkOrAwareID();
      console.log(networkId);
      
      var role = await (await this.userService.getVpRole(networkId).toPromise()).result as UserVPRoleModel;
      console.log(role);
      console.log(sessionStorage.getItem('UserTypePermissions'));
      console.log(sessionStorage.getItem('UserTypeName'));
      console.log(role);
      sessionStorage.setItem('UserTypeId', role.progressUserTypeId.toString());
      sessionStorage.setItem('UserTypeName', role.progressUserTypeName);
      sessionStorage.setItem('UserTypePermissions', role.permissions.toString());
      console.log(sessionStorage.getItem('UserTypePermissions'));

      
      let currentUser = (await this.userService.GetUserById(this.user).toPromise()).result;
      currentUser.userTypeId = role.progressUserTypeId;
      await this.userService.CreateUpdate(currentUser).toPromise();
      this.LoadCustomers();

    }
  }

  public LoadCustomers() {
    this.spinner = true;
    let user = new UserModel();
    user.emailAddress = GlobalVariablesService.getEmailAddress();
    if (user.emailAddress.includes("ppg.com")) {
      user.isPPGUser = true;
      user.ppgNetworkId = GlobalVariablesService.getNetworkOrAwareID();
      user.awareId = null;
    }
    else {
      user.isPPGUser = false;
      user.ppgNetworkId = null
      user.awareId = GlobalVariablesService.getNetworkOrAwareID();
      console.log(user.awareId);
    }
    console.log(user);
    
    this.customerService.GetCustomersByUserType(user).subscribe(response => {
      if (response.success) {
        this.customers = response.result;
        if (this.ctsId > 0) {
          this.customersFilter = this.customers.filter(x => x.ctsId == this.ctsId);
        } else {
          this.customersFilter = this.customers;
        }
      } else {
        console.log(response);
        NotificationService.error(null, this.translateService.instant("Error Loading Customers List"));
      }
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public fetchLanguages(user: UserModel) {
    this.userService.GetUserLanguages(user).subscribe(response => {
      if (response != null && response.length > 0) {
        this.languages = response;
        this.userLanguage = this.languages.find(x => x.languageId == user.languageId).languageName;
        if (localStorage.getItem('LanguageCultureCode') != undefined) {
          this.userLanguage = this.languages.find(x => x.languageCultureCode == localStorage.getItem('LanguageCultureCode')).languageName;
        }
        else {
          this.userLanguage = this.languages.find(x => x.languageId == user.languageId).languageName;
        }
        this.SwitchLang(localStorage.getItem('LanguageCultureCode'));
      } else {
        this.languages.push();
      }
      this.Authenticated();
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  SwitchLang(value: string) {
    this.translateService.use(value);
    sessionStorage.setItem("LanguageCultureCode", value);
    localStorage.setItem("LanguageCultureCode", value);
    this.router.navigate([this.router.url]);
  }

  public Authenticated() {
    this.authenticated = true;
    GlobalVariablesService.setAuthentication(true);
    this.LoadCustomers();
  }

  public AuthenticationError() {
    this.spinner = false;
    this.authenticated = false;
    GlobalVariablesService.setAuthentication(false);
    sessionStorage.clear();
    NotificationService.error(null, this.translateService.instant("Authentication Error"));
  }

  public LogOut() {
    sessionStorage.clear();
    window.location.href = environment.ssoLogout;
  }

  public IsValidToken(tokenstr: string): boolean {
    if (tokenstr === null || tokenstr.length === 0) {
      return false;
    }
    const accessToken: any = JWT(tokenstr);
    sessionStorage.setItem('Profile', JSON.stringify(accessToken));
    return accessToken.exp && new Date() < new Date(accessToken.exp * 1000);
  }

  public QueryStringToJSON(str): any {
    const pairs = str.split('&');
    const result = new SsoCallback();
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      const name = pair[0];
      const value = pair[1];
      if (name.length) {
        if (result[name] !== undefined) {
          if (!result[name].push) {
            result[name] = [result[name]];
          }
          result[name].push(value || '');
        } else {
          result[name] = value || '';
        }
      }
    });
    return (result);
  }

  public SetCtsId(value) {
    sessionStorage.setItem("CTSID", value);
    GlobalVariablesService.setCtsId(value);
    sessionStorage.setItem("DistributorName", (this.customersFilter.filter(option => option.ctsId == value)[0]).companyName);
    GlobalVariablesService.setDistributorName((this.customersFilter.filter(option => option.ctsId == value)[0]).companyName);
    this.router.navigate([this.router.url]);
  }

  public SetYear(value) {
    sessionStorage.setItem("Year", value);
    GlobalVariablesService.setYear(value);
    this.router.navigate([this.router.url]);
  }

  public ShowOptions() {
    this.navbarOpen = true;
    document.getElementById("options_menu").style.width = "300px";
  }

  public HideOptions() {
    this.navbarOpen = false;
    document.getElementById("options_menu").style.width = "0";
  }

  public TopNavMenu() {
    var x = document.getElementById("menu_topnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  public ShowHideSubMenus(menu: string) {
    if (menu == 'maintenance') this.showMaintenance = !this.showMaintenance;
    if (menu == 'other') this.showOther = !this.showOther;
  }

  public HasPermission(name: string) {
    let hasPermission = false;
    var permissions = GlobalVariablesService.getUserTypePermissions();
    if (permissions != null) {
      permissions.forEach(x => {
        if (name == x.Name) {
          hasPermission = true;
        }
      });
    }
    return (hasPermission && this.authenticated);
  }

  public getEmailAddress() {
    return GlobalVariablesService.getEmailAddress();
  }

  public FilterCustomer(value) {
    this.customersFilter = this.customers;
    this.customersFilter = this.Search(value);
  }

  public Search(value: string) {
    let filter = value.toLowerCase();
    return this.customersFilter.filter(option => option.companyName.toLowerCase().includes(filter) || option.ctsId.toString().includes(filter));
  }

  public ClearCustomerFilter() {
    if (this.ctsId > 0) {
      this.customersFilter = this.customers.filter(x => x.ctsId == this.ctsId);
    } else {
      this.customersFilter = this.customers;
    }
  }

  public showCtsIdVisibility() {
    return GlobalVariablesService.showCtsIdVisibility();
  }
}
