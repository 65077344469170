<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
  <br>
  <h3 class="center">{{"Users"|translate}}</h3>
  <div>
    <button mat-button  routerLink="/user-add-edit">
      <span class="material-icons">add_box</span>
    </button>
  </div>
  <div>
    <mat-form-field>
      <mat-label>{{"Filter"|translate}}</mat-label>
      <input matInput (keyup)="Filter($event)">
    </mat-form-field>
    <table mat-table [dataSource]="usersFilter" class="users_table mat-elevation-z8">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"First Name"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.firstName}}</td>
      </ng-container>
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Last Name"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Email Address"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.emailAddress}}</td>
      </ng-container>
      <ng-container matColumnDef="userTypeName">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"User Type"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.userTypeName}}</td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Active"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.active? 'Active': 'Inactive'}}</td>
      </ng-container>
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Created"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell">{{item.createDate}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="c-table__header-cell">{{"Actions"|translate}}</th>
        <td mat-cell *matCellDef="let item" class="c-table__data-cell" style="text-align: left!important;">
          <button mat-button  (click)="EditUser(item)">
            <span class="material-icons">mode_edit</span></button>
          <button *ngIf="(item.awareId != null)" mat-button  (click)="ResetPassword(item)">
            <span class="material-icons">refresh</span></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
  </div>
</div>