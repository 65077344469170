import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomerService } from '../../services/customer.service';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { NotificationService } from '../../../../core/services/notification-service';

import { CustomerModel } from '../../models/customer-model';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.scss']
})
export class CustomerHeaderComponent implements OnInit {
  public ctsId: number;
  public customer: CustomerModel = new CustomerModel();
  public message: number;
  public spinner: boolean = false;
  public address: string;

  constructor(private customerService: CustomerService,
    private route: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    if (this.ctsId != 0) {
      this.GetCustomerById();
    }
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            if (response.result != null) {
              this.customer = response.result;
              this.address =
                (this.customer.addressLine1 == null ? '' : this.customer.addressLine1 + ', ') +
                (this.customer.addressLine2 == null ? '' : this.customer.addressLine2 + ', ') +
                (this.customer.addressLine3 == null ? '' : this.customer.addressLine3 + ', ') +
                (this.customer.postalCode == null ? '' : 'C.P. ' + this.customer.postalCode + ', ') +
                (this.customer.city == null ? '' : this.customer.city);
            } else {
              NotificationService.error(null, "Customer Not Found In Vantage Point");
            }
          } else {
            NotificationService.error(null, "Internal Error");
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, "An Error Ocurred");
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, "Internal Error");
    }
  }
}