import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { BrandTargetModel } from '../../models/brand-target.model';
import { SalesTargetModel } from '../../models/sales-target.model';
import { TargetService } from '../../services/target.service';

@Component({
  selector: 'app-sales-target-display',
  templateUrl: './sales-target-display.component.html',
  styleUrls: ['./sales-target-display.component.scss']
})
export class SalesTargetDisplayComponent implements OnInit {

  public ctsId: number;
  public year: number;
  public languageCultureCode : string = GlobalVariablesService.getLanguageCultureCode();
  public brandTarget: BrandTargetModel;
  volumeDisplayedColumns = ['brand', 'segment', 'clearcoats', 'primers', 'totals'];
  salesDisplayedColumns = ['brand', 'segment', 'total'];

  constructor(private targetService: TargetService) { }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.targetService.getTargetsForDisplay(this.ctsId, this.year).subscribe(x => {
      this.brandTarget = x;
    });
  }

  getSalesTotal(targets: SalesTargetModel[]) {
    return targets.map(t => t.target).reduce((acc, value) => parseInt(acc.toString()) + parseInt(value.toString()), 0);
  }
}