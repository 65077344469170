<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <br>
    <!-- <h3 class="center">{{title}}</h3>
    <br>
    <h4><u>{{distributorName}}</u></h4>
    <br>
    <br> -->
    <button *ngIf="!addMarketingOptions" mat-button (click)="AddMarketingOptions()"
        [disabled]="IsReadOnly"> {{"Add Marketing Option(s)"|translate}}
        <span class="material-icons">add_box</span>
    </button>
    <br> <br>

    <!-- Add Table-->
    <div *ngIf="addMarketingOptions">
        <table mat-table [dataSource]="addRow" class="add_grid mat-elevation-z8" width="100%">
            <ng-container matColumnDef="typeOfAgreement">
                <th mat-header-cell *matHeaderCellDef>{{"Type Of Agreement"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.typeOfAgreement" [(ngModel)]="element.typeOfAgreement" matInput
                            placeholder="Add Type Of Agreement" type="text" required>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="annualValue">
                <th mat-header-cell *matHeaderCellDef>{{"Annual Value"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.annualValueFormatted" [(ngModel)]="element.annualValueFormatted" matInput matInputSeparatorsDirective
                            placeholder="Enter Annual Value" type="text" required>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>{{"Details"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.details" [(ngModel)]="element.details" matInput
                            placeholder="Enter Details" type="text" required>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button  (click)="CreateUpdate(row)">
                        <span class="material-icons">save</span>
                    </button>
                    <button *ngIf="addMarketingOptions" mat-button 
                        (click)="AddMarketingOptions()"><span class="material-icons">cancel</span>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="addCols"></tr>
            <tr mat-row *matRowDef="let row; columns: addCols;"></tr>
        </table>
    </div>
    <br>

    <!-- Data Table-->
    <div>
        <mat-form-field>
            <mat-label>{{"Filter"|translate}}</mat-label>
            <input matInput (keyup)="Filter($event)">
        </mat-form-field>
        <br><br>
        <table mat-table [dataSource]="dataSource" class="data_grid mat-elevation-z8">
            <ng-container matColumnDef="typeOfAgreement">
                <th mat-header-cell *matHeaderCellDef>{{"Type Of Agreement"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.typeOfAgreement" [(ngModel)]="element.typeOfAgreement" matInput
                            placeholder="Enter Type Of Agreement" type="text" required [disabled]="IsReadOnly">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="annualValue">
                <th mat-header-cell *matHeaderCellDef>{{"Annual Value"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.annualValueFormatted" [(ngModel)]="element.annualValueFormatted" matInput matInputSeparatorsDirective
                            placeholder="Enter Annual Value" type="text" required [disabled]="IsReadOnly">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>{{"Details"|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="form_field">
                        <input [value]="element.details" [(ngModel)]="element.details" matInput
                            placeholder="Enter Details" type="text" required [disabled]="IsReadOnly">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button (click)="CreateUpdate(row)" [disabled]="IsReadOnly">
                        <span class="material-icons">save</span>
                    </button>
                    <button mat-button (click)="Delete(row)" [disabled]="IsReadOnly">
                        <span class="material-icons">remove_circle</span>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
        </mat-paginator>

    </div>
</div>