<div class="backdrop"></div>
<div class="container center spinner">
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="spinner-grow"></div>
    <div class="loading">      
    </div>
</div>
