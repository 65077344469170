import { BrandVolumeTargetModel } from "./brand-volume-target.model";

export class VolumeTargetModel {
  public id: number;
  public ctsId: number;
  public brandId: number;
  public brandName: string;
  public segmentId: number;
  public segmentName: string;
  public primerTarget: number;
  public clearcoatTarget: number;
  public year: number;
  public primerTargetFormatted: string;
  public clearcoatTargetFormatted: string;
}
