<div class="container">
  <button *ngIf="!showAddCancel" mat-button  (click)="ShowAddCancel()" [disabled]="IsReadOnly">
    <span class="material-icons">add_box</span> 
  </button>
  <div *ngIf="showAddCancel">
    <mat-table #table [dataSource]="addRow"  class="mat-elevation-z8" >
      <ng-container matColumnDef="segment">
        <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row">      
            <mat-select [(value)]="lineId" (valueChange)="onSegmentChange($event)">
              <mat-option *ngFor="let brand of lines" [value]="brand.brandId">
                {{ brand.brandName }}
              </mat-option>
            </mat-select>        
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef>{{"Segment"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row">       
            <mat-select [(value)]="segmentId">
              <mat-option *ngFor="let segment of brands" [value]="segment.segmentId">
                {{ segment.segmentName }}
              </mat-option>
            </mat-select>        
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="consecutionLevel">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Consecution"|translate}} %</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">          
            <input matInput [(ngModel)]="data.consecutionLevel">       
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="rebate">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Rebate"|translate}} %</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">         
            <input matInput [(ngModel)]="data.rebate">       
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Actions"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'"> 
          <button mat-button  (click)="create(data, segmentId, lineId)">
            <span class="material-icons">save</span>
          </button>
          <button *ngIf="showAddCancel" mat-button (click)="ShowAddCancel()"><span
              class="material-icons">cancel</span>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row>
        <mat-header-row *matHeaderRowDef="addDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: addDisplayedColumns;"></mat-row>
      </mat-header-row>
    </mat-table>
  </div>

  <br>
  <br>
  <div>
    <mat-table #table  [dataSource]="premiumGridData" class="mat-elevation-z8">
      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Brand"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.brand }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="segment">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Segment"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.segment }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="consecutionLevel">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Consecution"|translate}} %</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.consecutionLevel | percent}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="rebate">
        <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Rebate"|translate}} %</mat-header-cell>
        <mat-cell *matCellDef="let data" [ngClass]="'w-75'">
          <!-- <mat-form-field floatLable="never"> -->
            <input matInput type="number" [value]="data.rebate" (change)="onChange(data, $event)"
              [(ngModel)]="data.rebate">         
          <!-- </mat-form-field> -->
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="car">
        <mat-header-cell *matHeaderCellDef>{{"CAR"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.rebate * .65 | number: '1.2-2':languageCultureCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="volume">
        <mat-header-cell *matHeaderCellDef>{{"Volume"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.rebate * .35 | number: '1.2-2':languageCultureCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let data">          
          <button mat-button (click)="Delete(data)" [disabled]="IsReadOnly && !IsPPGAdmin">
            <span class="material-icons">remove_circle</span>
          </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="premiumDisplayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: premiumDisplayedColumns;"></mat-row>
      <ng-container matColumnDef="groupHeader">
        <mat-cell *matCellDef="let groupBy" class="checkboxHeader">{{
            groupBy.brand }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="groupHeaderSelect">
        <mat-cell class="checkboxHeader" *matCellDef="let groupBy">
          <mat-checkbox [checked]="!groupBy.excludeFromRebate" (change)="onExcludeClick(groupBy, $event)"
            style="color: white !important;margin-left:70%" [disabled]="IsReadOnly && !IsPPGAdmin">{{"Valid for Rebate"|translate}}</mat-checkbox>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: ['groupHeader', 'groupHeaderSelect']; when: isGroup"> </mat-row>>
    </mat-table>
    
    <br>
    <br>
    <div>
      <mat-table #table [dataSource]="gridData" class="mat-elevation-z8">
        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Brand"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.brand }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="segment">
          <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Segment"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.segment }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="consecutionLevel">
          <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Consecution"|translate}} %</mat-header-cell>
          <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.consecutionLevel | percent}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rebate">
          <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Rebate"|translate}} %</mat-header-cell>
          <mat-cell *matCellDef="let data" [ngClass]="'w-75'">  
              <input matInput type="number" [value]="data.rebate" (change)="onChange(data, $event)"
                [(ngModel)]="data.rebate" >          
          </mat-cell>
        </ng-container>       


        <ng-container matColumnDef="car">
          <mat-header-cell *matHeaderCellDef>{{"CAR"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let data">{{ data.rebate }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="volume">
          <mat-header-cell *matHeaderCell>{{"Volume"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let data">{{ data.rebate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" >
          <mat-header-cell *matHeaderCellDef >{{"Actions"|translate}}</mat-header-cell>
          <mat-cell *matCellDef="let data" >          
            <button mat-button  (click)="Delete(data)" [disabled]="IsReadOnly && !IsPPGAdmin" >
              <span class="material-icons">remove_circle</span>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <ng-container matColumnDef="groupHeader">

          <mat-cell *matCellDef="let groupBy" class="checkboxHeader"><strong>{{
              groupBy.brand }}</strong></mat-cell>>

        </ng-container>
        <ng-container matColumnDef="groupHeaderSelect">
          <mat-cell class="checkboxHeader" *matCellDef="let groupBy">
            <mat-checkbox [checked]="!groupBy.excludeFromRebate" (change)="onExcludeClick(groupBy, $event)"
              style="color:white !important;margin-left:70%" [disabled]="IsReadOnly && !IsPPGAdmin" >{{"Valid for Rebate"|translate}}</mat-checkbox>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['groupHeader', 'groupHeaderSelect']; when: isGroup"> </mat-row>>
      </mat-table>
    </div>
  </div>