<mat-table #table [ngClass]="'w-500'" [dataSource]="premiumGridData" class="mat-elevation-z8">
  <ng-container matColumnDef="brand">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-150'">{{"Brand"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data" [ngClass]="'w-150'">{{ data.brand }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="segment">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-100'">{{"Segment"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data" [ngClass]="'w-100'">{{ data.segment }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="consecutionLevel">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Consecution"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.consecutionLevel | percent}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="rebate">
    <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Rebate"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.rebate | percent:'1.2-2':languageCultureCode}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="car">
    <mat-header-cell *matHeaderCellDef>{{"CAR"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data">{{ data.rebate * .65 | percent:'1.2-2':languageCultureCode}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="volume">
    <mat-header-cell *matHeaderCellDef>{{"Volume"|translate}}</mat-header-cell>
    <mat-cell *matCellDef="let data">{{ data.rebate * .35 | percent:'1.2-2':languageCultureCode }}</mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="premiumDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: premiumDisplayedColumns;"></mat-row>
  <ng-container matColumnDef="groupHeader">
    <mat-cell *matCellDef="let groupBy" style="color:white !important; background-color:#0078A9"><strong>{{
        groupBy.brand }}</strong></mat-cell>>
  </ng-container>
  <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>>
</mat-table>
<div>&nbsp;</div>
<div>
  <mat-table #table [ngClass]="'w-500'" [dataSource]="gridData" class="mat-elevation-z8">
    <ng-container matColumnDef="brand">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-150'">{{"Brand"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data" [ngClass]="'w-150'">{{ data.brand }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="segment">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-100'">{{"Segment"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data" [ngClass]="'w-100'">{{ data.segment }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="consecutionLevel">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Consecution"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.consecutionLevel | percent }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rebate">
      <mat-header-cell *matHeaderCellDef [ngClass]="'w-75'">{{"Rebate"|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data" [ngClass]="'w-75'">{{ data.rebate | percent:'1.2-2' :languageCultureCode}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <ng-container matColumnDef="groupHeader">
      <mat-cell *matCellDef="let groupBy" style="color:white !important; background-color:#0078A9"><strong>{{
          groupBy.brand }}</strong></mat-cell>>
    </ng-container>
    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </mat-row>>
  </mat-table>