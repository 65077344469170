import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { environment } from '../../../../environments/environment';
import { env } from 'process';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SalesTargetModel } from '../models/sales-target.model';
import { VolumeTargetModel } from '../models/volume-target.model';
import { BrandTargetModel } from '../models/brand-target.model';
import { NumberFormatService } from '../../../core/services/number-format-service';
import { BrandSalesTargetModel } from '../models/brand-sales-target.model';

@Injectable({
  providedIn: 'root'
})
export class TargetService {

  constructor(private http: HttpClient) { }

  public getVolumeTarget(ctsId: number, segmentId: number): Observable<VolumeTargetModel[]> {
    console.log("In Service");
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}target/get-volume-target/${ctsId}/${segmentId}`).
      pipe(
        map(response => response.result)
      );
  }
  public getSalesTarget(ctsId: number, segmentId: number): Observable<SalesTargetModel[]> {
    console.log("In Service");
    let result = this.http.get<ServiceCallResult>(`${environment.apiEndpoint}target/get-sales-target/${ctsId}/${segmentId}`).
      pipe(
        map(response => response.result)
      );
    result.forEach(x => {
      x.targetFormatted = NumberFormatService.numberWithCommas(x.target.toString());
    });
    console.log(result);
    return result;
  }
  public saveSalesTarget(salesModel: SalesTargetModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    salesModel.target = parseInt(NumberFormatService.removeSeparators(salesModel.targetFormatted));
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}target/save-sales-target`, salesModel, httpOptions).pipe(
      map(response => response.result)
    );

  }
  public saveVolumeTarget(volumeModel: VolumeTargetModel): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (volumeModel.clearcoatTargetFormatted != null) {
      volumeModel.clearcoatTarget = parseInt(NumberFormatService.removeSeparators(volumeModel.clearcoatTargetFormatted));
    }
    if (volumeModel.primerTargetFormatted != null) {
      volumeModel.primerTarget = parseInt(NumberFormatService.removeSeparators(volumeModel.primerTargetFormatted));
    }
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}target/save-volume-target`, volumeModel, httpOptions).pipe(
      map(response => response.result)
    );
  }
  public async getTargets(ctsId: number, year: number): Promise<Observable<BrandTargetModel>> {


    var brandTarget;
    brandTarget = await (await this.getTargetsResponse(ctsId, year)).toPromise();
    console.log(brandTarget);
    if (brandTarget != null && brandTarget.brandTargets != null) {
      for (let outerIndex = 0; outerIndex < brandTarget.brandTargets.length; outerIndex++) {
        console.log(brandTarget.brandTargets[outerIndex]);
        for (let index = 0; index < brandTarget.brandTargets[outerIndex].salesTargets.length; index++) {
          brandTarget.brandTargets[outerIndex].salesTargets[index].targetFormatted = NumberFormatService.numberWithCommas(brandTarget.brandTargets[outerIndex].salesTargets[index].target);
          console.log(brandTarget.brandTargets[outerIndex].salesTargets[index]);
        }
        if (brandTarget.brandTargets[outerIndex].volumeTargets != null) {
          for (let index = 0; index < brandTarget.brandTargets[outerIndex].volumeTargets.length; index++) {
            brandTarget.brandTargets[outerIndex].volumeTargets[index].clearcoatTargetFormatted = NumberFormatService.numberWithCommas(brandTarget.brandTargets[outerIndex].volumeTargets[index].clearcoatTarget);
            brandTarget.brandTargets[outerIndex].volumeTargets[index].primerTargetFormatted = NumberFormatService.numberWithCommas(brandTarget.brandTargets[outerIndex].volumeTargets[index].primerTarget);
          }
        }
      }
    }


    console.log(brandTarget);
    return of(brandTarget);
  }

  private async getTargetsResponse(ctsId: number, year: number): Promise<Observable<BrandTargetModel>> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}target/get-targets/${ctsId}/${year}`).
      pipe(
        map(response => response.result)
      );
  }

  public getTargetsForDisplay(ctsId: number, year: number): Observable<BrandTargetModel> {
    return this.http.get<ServiceCallResult>(`${environment.apiEndpoint}target/get-targets-for-display/${ctsId}/${year}`).
      pipe(
        map(response => response.result)
      )
  }
}

