import { ContractsModel } from '../models/contracts.model';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { FileAttachmentModel } from '../models/file-attachment.model';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  constructor(private http: HttpClient) { }

  public GetCustomerContracts(request: ContractsModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "Contracts/GetCustomerContracts", request).pipe(map(response => response));
  }

  public DeleteFile(request: ContractsModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "Contracts/DeleteFile", request).pipe(map(response => response));
  }

  public uploadFile(file: File, contractId: number,customerContractDataID:number, fileId: number, ctsId: number): Observable<ServiceCallResult> {
    let formData = new FormData();
    console.log(contractId + " contractId fileId" + fileId + "contractdata"+customerContractDataID );
    formData.append('file', file);
    formData.append('contractId', contractId.toString());
    formData.append('contractDataId',customerContractDataID.toString());
    formData.append('fileId', fileId.toString());
    formData.append('ctsId', ctsId.toString());
    const fileHeaders = new HttpHeaders();
    fileHeaders.set('Accept', 'application/json');
    fileHeaders.delete('Content-Type');
    return this.http.post<ServiceCallResult>(`${environment.apiEndpoint}contracts/uploadFile`, formData).pipe(map(response => response));
  }

  public download(dto: FileAttachmentModel): Observable<Blob> {
    return this.http.post<Blob>(`${environment.apiEndpoint}contracts/DownloadFile`, dto,
    { responseType: 'blob' as 'json' });
  }
}