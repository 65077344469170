<app-spinner *ngIf="spinner"></app-spinner>
<br />
<div class="container">
    <br>
    <h3 class="center">{{user.userId > 0 ? user.firstName + ' ' + user.lastName : 'New User'}}
    </h3>
    <br>
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field>
                <mat-select [(ngModel)]="user.userTypeId" (ngModelChane)="user.userTypeId"
                    placeholder="{{'Choose User Type'|translate}}">
                    <mat-option *ngFor="let item of userTypes" [value]="item.userTypeId">
                        {{item.userTypeDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field class="full_width">
                <input matInput placeholder="{{'Email'|translate}}" value="user.emailAddress"
                    [(ngModel)]="user.emailAddress" [disabled]="(user.userId > 0)">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field class="full_width">
                <input matInput placeholder="{{'First Name'|translate}}" value="user.firstName"
                    [(ngModel)]="user.firstName">
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field class="full_width">
                <input matInput placeholder="{{'Last Name'|translate}}" value="user.lastName"
                    [(ngModel)]="user.lastName">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field>
                <mat-select [(ngModel)]="user.languageId" (ngModelChane)="user.languageId"
                    placeholder="{{'Choose Language'|translate}}">
                    <mat-option *ngFor="let item of languages" [value]="item.languageId">
                        {{item.languageName + '(' + item.languageCultureCode + ')'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-checkbox class="example-margin" [(ngModel)]="user.active">{{"Active"|translate}}</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <button mat-button (click)="ResetPassword(user)" class="btnPPG1"
                [hidden]="!(user.userId > 0) || (user.awareId == null)">
                {{"Reset Password"|translate}}
            </button>
        </div>
        <div class="col-sm-6">
            <button mat-button  (click)="SaveUser()" class="btnPPG1">
                {{"Save Changes"|translate}}
            </button>
        </div>
    </div>
</div>