import { CustomerModel } from './../../../customer/models/customer-model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';

import { BrandTargetModel } from '../../models/brand-target.model';
import { TargetService } from '../../services/target.service';
import { CustomerService } from './../../../customer/services/customer.service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { ABPApprovalService } from '../../services/abp-approval.service';
import { ABPApprovalModel } from '../../models/abp-approval-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-annual-business-plan-display',
  templateUrl: './annual-business-plan-display.component.html',
  styleUrls: ['./annual-business-plan-display.component.scss']
})

export class AnnualBusinessPlanDisplayComponent implements OnInit {
  @ViewChild('abpPrintSection') abpPrintSection: ElementRef;
  public spinner = false;
  public ctsId: number;
  public year: number;
  public brandTarget: BrandTargetModel;
  public distributorName: string;
  public customer = new CustomerModel();
  public approvalModel: ABPApprovalModel;
  public visible: boolean;

  constructor(private targetService: TargetService, private customerService: CustomerService, private approvalService: ABPApprovalService) {
    console.log('Loading');
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.distributorName = GlobalVariablesService.getDistributorName();
    
  }

  async ngOnInit(): Promise<void> {
    
    await (await this.targetService.getTargets(this.ctsId, this.year)).subscribe(x => {
      this.brandTarget = x
    });
    let request = new CustomerModel()
    request.ctsId = this.ctsId;
    this.customerService.GetCustomerByCTSId(request).subscribe(response => {
      if (response.success) {
        this.customer = response.result
      }
    });
    this.visible = (await this.isVisible());
    console.log(this.visible);
   }

  generatePDF() {
    window.print();
    // this.spinner = true;
    // var data = document.getElementById('abpPrintSection');
    // html2canvas(data).then(canvas => {
    //   var imgWidth = 208;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdf.save('ABP.pdf');
    //   this.spinner = false;
    // });
  }
  async isVisible(): Promise<boolean> {
    
    if (this.ctsId !== 0) {

      let model = new ABPApprovalModel();
      model.ctsId = this.ctsId;
      model.year = this.year;
    
      this.approvalModel = await (await this.approvalService.GetCurrentStatusPromise(model)).toPromise();
      let userTypeId = sessionStorage.getItem('UserTypeId');
      console.log(this.approvalModel);
      console.log(userTypeId);
      if (this.approvalModel == null && (userTypeId === '1' || userTypeId === '7')) {
        return false;      
      }

      if (this.approvalModel != null && (this.approvalModel.stepId !== 7 && (userTypeId === '1' || userTypeId === '7'))) {
        return false;
      }
    }

    return true;
  }
}