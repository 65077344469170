<app-spinner *ngIf="spinner"></app-spinner>
<div class="container">
    <br>
    <!-- <h3 class="center">{{title}}</h3>
    <br> -->
    <div>
        <button *ngIf="!showAddCancel && !isPlatinumProgress" mat-button  (click)="ShowAddCancel()"
            [disabled]="(!(year > 0)) || IsReadOnly">
            <span class="material-icons">add_box</span>
        </button>
        <!-- Add Table-->
        <div *ngIf="showAddCancel">
            <table mat-table [dataSource]="addrow" class="add_grid">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{"Name"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.name" [(ngModel)]="item.name" matInput
                                placeholder="{{'Enter Name'|translate}}" [formControl]="name" type="text" required>
                            <mat-error *ngIf="name.invalid">{{getErrorMessage("Name")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="trainingId">
                    <th mat-header-cell *matHeaderCellDef>{{"Course"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.trainingId" [(ngModel)]="item.trainingId"
                                [formControl]="trainingId" required>
                                <mat-option *ngFor="let training of trainings" [value]="training.trainingId">
                                    {{training.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="trainingId.invalid">{{getErrorMessage("Course")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="targetDate">
                    <th mat-header-cell *matHeaderCellDef>{{"Target Date"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.targetDate" [(ngModel)]="item.targetDate"
                                [formControl]="targetDate" required>
                                <mat-option *ngFor="let date of targetDates" [value]="date">
                                    {{date}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="targetDate.invalid">{{getErrorMessage("Target Date")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)">
                            <span class="material-icons">save</span>
                        </button>
                        <button *ngIf="showAddCancel" mat-button  (click)="ShowAddCancel()"><span
                                class="material-icons">cancel</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
            </table>
        </div>
        <!-- Data Table-->
        <div>
            <mat-form-field>
                <mat-label>{{"Filter"|translate}}</mat-label>
                <input matInput (keyup)="Filter($event)" [disabled]="!(year > 0)">
            </mat-form-field>
            <table mat-table [dataSource]="trainingsFilter" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{"Name"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.name" [(ngModel)]="item.name" matInput placeholder="Enter Name"
                                type="text" required [disabled]="isPlatinumProgress || IsReadOnly">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="trainingId">
                    <th mat-header-cell *matHeaderCellDef>{{"Course"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.trainingId" [(ngModel)]="item.trainingId" required
                                [disabled]="true">
                                <mat-option *ngFor="let training of trainings" [value]="training.trainingId">
                                    {{training.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="targetDate">
                    <th mat-header-cell *matHeaderCellDef>{{"Target Date"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.targetDate" [(ngModel)]="item.targetDate" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                                <mat-option *ngFor="let date of targetDates" [value]="date">
                                    {{date}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button (click)="CreateUpdate(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">save</span>
                        </button>
                        <button mat-button (click)="Delete(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">remove_circle</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="trainingsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: trainingsColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [disabled]="!(year > 0)">
            </mat-paginator>
        </div>
    </div>
</div>