import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from './../../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessPlanClauseDetailModel } from '../../../models/business-plan-clause-detail.model';
import { BusinessPlanClauseModel } from '../../../models/business-plan-clause.model';
import { AnnualBusinessPlanService } from '../../../services/annual-business-plan.service';
import { BusinessPlanClauseModalComponent } from '../business-plan-clause-modal/business-plan-clause-modal.component';

@Component({
  selector: 'app-business-plan-clause-detail-modal',
  templateUrl: './business-plan-clause-detail-modal.component.html',
  styleUrls: ['./business-plan-clause-detail-modal.component.scss']
})
export class BusinessPlanClauseDetailModalComponent implements OnInit {

  public detailText: string;
  public form: FormGroup;
  constructor(public dialogRef: MatDialogRef<BusinessPlanClauseDetailModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,private translateService:TranslateService,
              private planService: AnnualBusinessPlanService
              ) { }

  ngOnInit(): void {
  }


  async onSaveClick($event: any): Promise<any> {
    let clause = new BusinessPlanClauseModel();
    let detail = new BusinessPlanClauseDetailModel();
    detail.description = this.detailText;
    // tslint:disable-next-line: radix
    if (detail.description!=undefined && detail.description.trim()!="")
    {
    clause.id = parseInt(this.data.id);
    clause.details.push(detail);
    let result = await this.planService.addDetail(clause).toPromise();
    NotificationService.success(null,this.translateService.instant("Saved"));
    }
    else
    {
      NotificationService.warning(null,this.translateService.instant("Error"));
    }

    this.dialogRef.close();
  }
  onCancelClick($event): void {
    this.dialogRef.close();
  }

}
