import { DevPlanModel } from '../models/dev-plan-request.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceCallResult } from '../../../core/models/service-call-result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DevPlanHeaderModel } from '../models/dev-plan-header.model';
import { CustomerDevPlanModel } from '../models/customer-dev-plan-request.model';

@Injectable({
  providedIn: 'root'
})
export class DevPlanService {

  constructor(private http: HttpClient) { }
  public GetYears(): Observable<number[]> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/get-years", null).pipe(map(response => response.result));
  }
  public GetCustomerPlans(request: CustomerDevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/get-by-ctsid",request).pipe(map(response => response));
  }
  public GetByYear(request: DevPlanModel): Observable<DevPlanModel[]> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/get-by-year", request).pipe(map(response => response.result));
  }
  public GetPlansByYear(request: DevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/get-devplans-byyear", request).pipe(map(response => response));
  }
  public GetPlansToAddforSelectedCustomer(request: CustomerDevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/GetPlansToAddforSelectedCustomer", request).pipe(map(response => response));
  }
  public GetPlanHeader(request: DevPlanHeaderModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/get-devplans-header", request).pipe(map(response => response));
  }
  public CreateUpdateShopPlans(plan: CustomerDevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/CreateUpdateShopPlans", plan).pipe(map(response => response));
  }
  public CreateUpdate(request: DevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/create-update", request).pipe(map(response => response.result));
  }
  public CreateUpdateHeader(request: DevPlanHeaderModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/create-update-header", request).pipe(map(response => response));
  }
  public Delete(request: DevPlanModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/delete", request).pipe(map(response => response.result));
  }
    public DeleteCustomerPlans(request: CustomerDevPlanModel): Observable<ServiceCallResult> {
      return this.http.post<ServiceCallResult>(environment.apiEndpoint + "DevPlan/deletecustomerplans", request).pipe(map(response => response));
  }
}