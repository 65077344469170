export class TargetAccountModel {
    year: number;
    ctsId: number;
    targetCtsId: number;
    shopGrossSales: number;
    potentialPurchases: number;
    targetDate: Date;
    competitor: string = "";
    numberPainters: number;
    planDetails: string = "";
    shopGrossSalesFormatted: string;
    potentialPurchasesFormatted: string;
}