<div class="container">
  <div>
    <span>{{"SEGMENT"|translate}}</span>
    <mat-table [dataSource]="gridData" class="mat-elevation-z8">
      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef>{{"Brand"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let gridData">{{gridData.brand}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="consecutionLevel">
        <mat-header-cell *matHeaderCellDef>{{"Consecution Level"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let gridData">&#62;= {{gridData.consecutionLevel | percent:'1.0-0'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="hvlColor">
        <mat-header-cell *matHeaderCellDef>{{"HVLS Color"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let gridData">{{gridData.hvlColor | percent:'1.2-2'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="ct">
        <mat-header-cell *matHeaderCellDef>{{"CT"|translate}}</mat-header-cell>
        <mat-cell *matCellDef="let gridData">{{gridData.ct | percent:'1.2-2'}}</mat-cell>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>>
      </ng-container>
    </mat-table>
  </div>
</div>