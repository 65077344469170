import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  public authenticated: boolean = false;

  ngOnInit(): void {
    GlobalVariablesService.setCtsIdVisibility(true);
    this.authenticated = GlobalVariablesService.IsAuthenticated();
  }

  public IsPlatinumProgress() {
    let isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();
    return isPlatinumProgress;
  }
}