export class TechnicalAssistanceModel {
    id: number;
    year: number;
    ctsId: number;
    startDate: Date;
    endDate: Date;
    technician: string = "";
    annualAmount: number;
    periodicity: string;
    detail: string = "";
    annualAmountFormatted: string;
}