<app-spinner *ngIf="spinner"></app-spinner>
<div>
    <br>
    <!-- <h3 class="center">{{title}}</h3> -->
    <div>
        <button *ngIf="!showAddCancel && !isPlatinumProgress" mat-button  (click)="ShowAddCancel()"
            [disabled]="!(year > 0) || IsReadOnly">
            <span class="material-icons">add_box</span>
        </button>
        <!-- Add Table-->
        <div *ngIf="showAddCancel">
            <table mat-table [dataSource]="addrow" class="add_grid mat-elevation-z8">
                <ng-container matColumnDef="col1">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose a start date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.startDate" required
                                [formControl]="startDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="startDate.invalid">{{getErrorMessage("Start Date")}}</mat-error>
                        </mat-form-field>
                        <br>
                        <mat-form-field class="form_field">
                            <mat-label>{{"Annual Amount"|translate}}</mat-label>
                            <input matInput matInputSeparatorsDirective placeholder="{{'Annual Amount'|translate}}" [value]="item.annualAmountFormatted"
                                [(ngModel)]="item.annualAmountFormatted" [formControl]="technician">
                            <mat-error *ngIf="annualAmount.invalid">{{getErrorMessage("Annual Amount")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="col2">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an end date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.endDate" required
                                [formControl]="endDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="endDate.invalid">{{getErrorMessage("End Date")}}</mat-error>
                        </mat-form-field>
                        <br>
                        <mat-form-field class="form_field">
                            <mat-label>{{"Technician"|translate}}</mat-label>
                            <input matInput placeholder="Technician" [value]="item.technician"
                                [(ngModel)]="item.technician" [formControl]="technician">
                            <mat-error *ngIf="technician.invalid">{{getErrorMessage("Technician")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="col3">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose a periodicity"|translate}}</mat-label>
                            <mat-select [(value)]="item.periodicity" [(ngModel)]="item.periodicity" required
                                [formControl]="periodicity">
                                <mat-option *ngFor="let p of periodicityOptions" [value]="p">
                                    {{p|translate}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="periodicity.invalid">{{getErrorMessage("Periodicity")}}</mat-error>
                        </mat-form-field>
                        <br>
                        <mat-form-field class="form_field">
                            <mat-label>{{"Detail"|translate}}</mat-label>
                            <input matInput placeholder="Detail" [value]="item.detail" [(ngModel)]="item.detail"
                                [formControl]="detail">
                            <mat-error *ngIf="detail.invalid">{{getErrorMessage("Detail")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)">
                            <span class="material-icons">save</span>
                        </button>
                        <button *ngIf="showAddCancel" mat-button  (click)="ShowAddCancel()"><span
                                class="material-icons">cancel</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
            </table>
        </div>
        <br>
        <!-- Data Table-->
        <div>
            <mat-form-field>
                <mat-label>{{"Filter"|translate}}</mat-label>
                <input matInput (keyup)="Filter($event)" [disabled]="!(year > 0)">
            </mat-form-field>
            <table mat-table [dataSource]="technicalAssistanceFilter" class="data_grid mat-elevation-z8">
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>{{"Start Date"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose a date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.startDate" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                            <mat-datepicker-toggle matSuffix [for]="picker"
                                [disabled]="isPlatinumProgress || IsReadOnly">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>{{"End Date"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose a date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.endDate" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                            <mat-datepicker-toggle matSuffix [for]="picker"
                                [disabled]="isPlatinumProgress || IsReadOnly">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="technician">
                    <th mat-header-cell *matHeaderCellDef>{{"Technician"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field class="form_field">
                            <input matInput placeholder="{{'Technician'|translate}}" [value]="item.technician"
                                [disabled]="isPlatinumProgress || IsReadOnly" [(ngModel)]="item.technician">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="annualAmount">
                    <th mat-header-cell *matHeaderCellDef>{{"Annual Amount"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field class="form_field">
                            <input matInput matInputSeparatorsDirective placeholder="{{'Annual Amount'|translate}}" [value]="item.annualAmountFormatted"
                                [disabled]="isPlatinumProgress || IsReadOnly" [(ngModel)]="item.annualAmountFormatted">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="periodicity">
                    <th mat-header-cell *matHeaderCellDef>{{"Periodicity"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.periodicity" [(ngModel)]="item.periodicity" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                                <mat-option *ngFor="let p of periodicityOptions" [value]="p">
                                    {{p|translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef>{{"Detail"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field class="form_field">
                            <input matInput placeholder="{{'Detail'|translate}}" [value]="item.detail" [(ngModel)]="item.detail"
                                [disabled]="isPlatinumProgress || IsReadOnly">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">save</span>
                        </button>
                        <button mat-button  (click)="Delete(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">remove_circle</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="technicalAssistanceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: technicalAssistanceColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [disabled]="!(year > 0)">
            </mat-paginator>
        </div>
    </div>
</div>