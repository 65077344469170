import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2'

import { NotificationService } from './../../../../core/services/notification-service';
import { UserService } from '../../services/user.service';

import { UserModel } from '../../models/user-model';
import { UserTypesModel } from '../../models/user-types-model';
import { LanguagesModel } from './../../models/languages-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.scss']
})

export class UserAddEditComponent implements OnInit {
  public spinner = false;
  // public userId: number;
  public user: UserModel = new UserModel();
  public userTypes: UserTypesModel[] = [];
  public languages: LanguagesModel[] = [];

  constructor(private userService: UserService, private route: ActivatedRoute, private translateService:TranslateService,
    private router: Router) {
    this.user.userId = Number(this.route.snapshot.paramMap.get('userId'));
    this.GetUserTypes();
  }

  ngOnInit() { }

  public GetUserTypes() {
    this.spinner = true;
    this.userService.GetUserTypes().subscribe(response => {
      if (response.success) {
        this.userTypes = response.result;
        this.userService.GetUserLanguages(this.user).subscribe(response => {
          if (response != null && response.length > 0) {
            this.languages = response;
            if (this.user.userId > 0) {
              this.GetUserById();
            } else {
              this.spinner = false;
            }
          } else {
            NotificationService.error(null, this.translateService.instant("Could not get languages"));
            this.spinner = false;
          }
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        NotificationService.error(null, this.translateService.instant("Could not get user types"));
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public GetUserById() {
    this.spinner = true;
    this.user.userId = this.user.userId;
    this.userService.GetUserById(this.user).subscribe(response => {
      if (response.success) {
        this.user = response.result;
        this.spinner = false;
      } else {
        NotificationService.error(null, this.translateService.instant("Could not get user information"));
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public ResetPassword(user: UserModel): void {
    Swal.fire({
      title: this.translateService.instant('Reset Password')+ '?',
      text: this.translateService.instant("You won't be able to revert this!"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#01304A',
      cancelButtonColor: 'red',
      confirmButtonText: this.translateService.instant('Yes, reset it')
    }).then((result) => {
      this.spinner = true;
      if (result.value) {
        this.userService.ResetPassword(user).subscribe(response => {
          if (response.success) {
            NotificationService.success(null, response.result);
          } else {
            NotificationService.error(null, response.message);
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        this.spinner = false;
      }
    });
  }

  public SaveUser() {
    this.spinner = true;
    this.user.createdBy = GlobalVariablesService.getUserId();
    this.user.modifiedBy = GlobalVariablesService.getUserId();
    this.userService.CreateUpdate(this.user).subscribe(response => {
      if (response.success) {
        NotificationService.success(null, this.translateService.instant("User Created"));
        this.spinner = false;
        this.user = response.result;
        this.router.navigate(['/user-add-edit/' + this.user.userId]);
      } else {
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }
}