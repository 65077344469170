export class CustomerDevPlanModel {
    public id: number;
    public devPlanId: number;
    public year: number;
    public ctsId: number;
    public shopCtsId: number;
    public shopName: string;
    public active: number;
    public createdBy: number;
    public comments:string;
}
