<app-spinner *ngIf="spinner"></app-spinner>
<div style="overflow-x: scroll;">
    <br>
    <!-- <h3 class="center">{{title}}</h3> -->
    <!-- <br> -->
    <div>
        <button *ngIf="!showAddCancel && !isPlatinumProgress" mat-button (click)="ShowAddCancel()"
            [disabled]="(!(year > 0)) || IsReadOnly">
            <span class="material-icons">add_box</span>
        </button>
        <!-- Add Table-->
        <div *ngIf="showAddCancel">
            <table mat-table [dataSource]="addrow" class="add_grid">
                <ng-container matColumnDef="col_1">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" valign="top">
                        <mat-form-field style="width:400px">
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.targetCtsId" [(ngModel)]="item.targetCtsId"
                                (selectionChange)="changeShopSelected(item)" (keydown)="$event.stopPropagation()"
                                [formControl]="targetCtsId" required>
                                <form>
                                    <mat-form-field class="w-100">
                                        <input type="text" (keyup)="FilterShop($event.target.value)" matInput
                                            [disabled]="isPlatinumProgress">
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                </form>
                                <mat-option *ngFor="let customer of customersFilter" [value]="customer.ctsId">
                                    {{customer.ctsId + '-' + customer.companyName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="targetCtsId.invalid">{{getErrorMessage("Company")}}</mat-error>

                        <br>
                        <mat-form-field style="width:400px">
                            <input [value]="item.shopGrossSalesFormatted" [(ngModel)]="item.shopGrossSalesFormatted" matInput matInputSeparatorsDirective
                                [formControl]="shopGrossSales" placeholder="{{'Enter Shop Gross Sales'|translate}}"
                                required>
                        </mat-form-field>
                        <mat-error *ngIf="shopGrossSales.invalid">{{getErrorMessage("Shop Gross Sales")}}
                        </mat-error>

                        <br>
                        <mat-form-field style="width:400px">
                            <input [value]="item.potentialPurchasesFomatted" [(ngModel)]="item.potentialPurchasesFomatted" matInput matInputSeparatorsDirective
                                [formControl]="potentialPurchases"
                                placeholder="{{'Enter Potential Purchases'|translate}}" required>
                        </mat-form-field>
                        <mat-error *ngIf="potentialPurchases.invalid">{{getErrorMessage("Potential Purchases")}}
                        </mat-error>

                        <br>
                        <mat-form-field appearance="fill" style="width:400px">
                            <mat-label>{{"Choose a date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.targetDate"
                                [formControl]="targetDate" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="targetDate.invalid">{{getErrorMessage("Target Date")}}</mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="col_2">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" valign="top">
                        <mat-form-field style="width:400px">
                            <mat-label>{{"Competitor"|translate}}</mat-label>
                            <input [value]="item.competitor" [(ngModel)]="item.competitor" matInput
                                placeholder="{{'Enter Competitor'|translate}}" type="text" [disabled]="true">
                        </mat-form-field>

                        <br>
                        <mat-form-field style="width:400px">
                            <mat-label>#{{"Painters"|translate}}</mat-label>
                            <input [value]="item.numberPainters" [(ngModel)]="item.numberPainters" matInput type="text"
                                [disabled]="true">
                        </mat-form-field>

                        <br>
                        <mat-form-field style="width:400px">
                            <input [value]="item.planDetails" [(ngModel)]="item.planDetails" matInput
                                [formControl]="planDetails" placeholder="{{'Enter Plan Details'|translate}}" type="text"
                                required>
                            <mat-error *ngIf="planDetails.invalid">{{getErrorMessage("Plan Details")}}
                            </mat-error>
                        </mat-form-field>

                        <br>   <br>   <br>
                        <div class="center" style="margin-left:20px">
                        <button mat-button  (click)="CreateUpdate(item)">
                            <span class="material-icons">save</span>
                        </button>
                        <button *ngIf="showAddCancel" mat-button (click)="ShowAddCancel()"><span
                                class="material-icons">cancel</span>
                        </button>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="addColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: addColumns;"></tr>
            </table>
        </div>
        <br>
        <!-- Data Table-->
        <div>
            <mat-form-field style="width:400px">
                <mat-label>{{"Filter"|translate}}</mat-label>
                <input matInput (keyup)="Filter($event)" [disabled]="!(year > 0)">
            </mat-form-field>
            <table mat-table [dataSource]="targetAccountFilter" class="data_grid">
                <ng-container matColumnDef="targetCtsId">
                    <th mat-header-cell *matHeaderCellDef>{{"Company"|translate}}</th>
                    <td mat-cell *matCellDef="let item" >
                        <mat-form-field>
                            <mat-label>{{"Choose an option"|translate}}</mat-label>
                            <mat-select [(value)]="item.targetCtsId" [(ngModel)]="item.targetCtsId"
                                (selectionChange)="getNumberPainters(item)" required [disabled]="true">
                                <mat-option *ngFor="let customer of customers" [value]="customer.ctsId">
                                    {{customer.companyName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="shopGrossSales">
                    <th mat-header-cell *matHeaderCellDef>{{"Shop Gross Sales"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.shopGrossSalesFormatted" [(ngModel)]="item.shopGrossSalesFormatted" matInput matInputSeparatorsDirective
                                placeholder="{{'Enter Shop Gross Sales'|translate}}"  required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="potentialPurchases">
                    <th mat-header-cell *matHeaderCellDef>{{"Potential Purchases"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.potentialPurchasesFormatted" [(ngModel)]="item.potentialPurchasesFormatted" matInput matInputSeparatorsDirective
                                placeholder="{{'Enter Potential Purchases'|translate}}"  required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="targetDate">
                    <th mat-header-cell *matHeaderCellDef>{{"Target Date"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field appearance="fill">
                            <mat-label>{{"Choose a date"|translate}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.targetDate" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="competitor">
                    <th mat-header-cell *matHeaderCellDef>{{"Competitor"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>Competitor</mat-label>
                            <input [value]="getCompetitorBrand(item)" [(ngModel)]="item.competitor" matInput
                                placeholder="{{'Enter Competitor'|translate}}" type="text" [disabled]="true">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="numberPainters">
                    <th mat-header-cell *matHeaderCellDef># {{"Painters"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <mat-label>#Painters</mat-label>
                            <input [value]="getNumberPainters(item)" [(ngModel)]="item.numberPainters" matInput
                                type="text" [disabled]="true">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="planDetails">
                    <th mat-header-cell *matHeaderCellDef>{{"Plan Details"|translate}}</th>
                    <td mat-cell *matCellDef="let item">
                        <mat-form-field>
                            <input [value]="item.planDetails" [(ngModel)]="item.planDetails" matInput
                                placeholder="{{'Enter Plan Details'|translate}}" type="text" required
                                [disabled]="isPlatinumProgress || IsReadOnly">
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{"Actions"|translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button  (click)="CreateUpdate(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">save</span>
                        </button>
                        <button mat-button  (click)="Delete(row)"
                            [disabled]="isPlatinumProgress || IsReadOnly">
                            <span class="material-icons">remove_circle</span>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="targetAccountsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: targetAccountsColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [disabled]="!(year > 0)">
            </mat-paginator>
        </div>
    </div>
</div>