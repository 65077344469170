<div class="container">
    <mat-table #table [dataSource]="technicalAssistanceFilter" class="data_grid mat-elevation-z8">
        <ng-container matColumnDef="startDate">
            <mat-header-cell *matHeaderCellDef>{{"Start Date"|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">
                {{ item.startDate | DateFormatPipe }}
            </mat-cell>>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <mat-header-cell *matHeaderCellDef>{{"End Date"|translate}}</mat-header-cell>>
            <mat-cell *matCellDef="let item">
                {{ item.endDate | DateFormatPipe }}
            </mat-cell>>
        </ng-container>
        <ng-container matColumnDef="technician">
            <mat-header-cell *matHeaderCellDef>{{"Technician"|translate}}</mat-header-cell>>
            <mat-cell *matCellDef="let item">
                {{ item.technician }}
            </mat-cell>>
        </ng-container>
        <ng-container matColumnDef="annualAmount">
            <mat-header-cell *matHeaderCellDef>{{"Annual Amount"|translate}}</mat-header-cell>>
            <mat-cell *matCellDef="let item">
                {{ item.annualAmount | number:'0.1-2' : languageCultureCode }}
            </mat-cell>>
        </ng-container>
        <ng-container matColumnDef="periodicity">
            <mat-header-cell *matHeaderCellDef>{{"Periodicity"|translate}}</mat-header-cell>>
            <mat-cell *matCellDef="let item">
                {{ item.periodicity | translate }}
            </mat-cell>>
        </ng-container>
        <ng-container matColumnDef="detail">
            <mat-header-cell *matHeaderCellDef>{{"Detail"|translate}}</mat-header-cell>>
            <mat-cell *matCellDef="let item">
                {{ item.detail }}
            </mat-cell>>
        </ng-container>
        <mat-header-row *matHeaderRowDef="technicalAssistanceColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: technicalAssistanceColumns;"></mat-row>
    </mat-table>
</div>