export class CustomerRebateModel {
    year: number;
    ctsId: number;
    rebateTypeId: number;
    rebateTypeName: string;
    exclusiveDistributor: boolean;
    baseValue: number = 0;
    qualitativeActions: boolean;
    rebateTypeCode: string;
    percentage: number = 0;
    minConsecutionAbp: number;
}