import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { LineSegmentsDataSource } from '../../datasources/line-segments-data-source';
import { LineSegmentModel } from '../../models/line-segment.model';
import { LineSegmentService } from '../../services/line-segment.service';
import 'rxjs/add/observable/of';
import { CustomerLineSegmentModel } from '../../models/customer-line-segment.model';
import { MatCheckbox } from '@angular/material/checkbox';
import { CustomerValueLinkModel } from '../../models/customer-value-link.model';
import { NotificationService } from '../../../../core/services/notification-service';
import { TranslateService } from '@ngx-translate/core';
import { RebateLevelService } from '../../../rebate/services/rebate-level.service';
import { RebateLevelModel } from '../../../rebate/models/rebate-level.model';
import { MatTableDataSource } from '@angular/material/table';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'app-customer-lines',
  templateUrl: './customer-lines.component.html',
  styleUrls: ['./customer-lines.component.scss']
})

export class CustomerLinesComponent implements OnInit {
  public spinner = false;
  public ctsId: number;
  public year: number;
  public premium = new FormControl();
  public lines: LineSegmentModel[] = [];
  public premiumLines: LineSegmentModel[] = [];
  public valueLines: LineSegmentModel[] = [];
  public colorLines: LineSegmentModel[] = [];
  public IsReadOnly: boolean = false;
  public lineId: number;
  public premiumSelection: SelectionModel<LineSegmentModel> = new SelectionModel<LineSegmentModel>();
  public valueSelection: SelectionModel<LineSegmentModel> = new SelectionModel<LineSegmentModel>();
  public colorSelection: SelectionModel<LineSegmentModel> = new SelectionModel<LineSegmentModel>();
  public displayedColumns: string[] = ['select', 'segment'];
  public message: number;
  public valueLineSelected: Observable<boolean>;
  public premiumLineSelected: Observable<boolean>;
  public colorValueLineSelected: Observable<boolean>;
  public ctLineSelected: Observable<boolean>;
  public licLines: LineSegmentModel[] = [];
  public licSelected: boolean;
  public selectedCt: LineSegmentModel;
  public selectedValueLink: CustomerValueLinkModel;
  public selectValueSegmentId: number;
  public ctSelection: SelectionModel<LineSegmentModel> = new SelectionModel<LineSegmentModel>();
  public ctLines: MatTableDataSource<LineSegmentModel> = new MatTableDataSource<LineSegmentModel>();
  public ppgSelected: boolean = false;
  public nexaSelected: boolean = false;
  public IsPPGAdmin = GlobalVariablesService.IsPPGAdmin();
  public IsPPGGuest = GlobalVariablesService.IsPPGGuest();
  public IsDistAdmin = GlobalVariablesService.IsDistributorAdmin();
  public IsDistUser = GlobalVariablesService.IsDistributorUser();
  public IsTM = GlobalVariablesService.IsTM();
  public IsRM = GlobalVariablesService.IsRM();
  public IsZD = GlobalVariablesService.IsZD();

  constructor(private lineSegmentService: LineSegmentService, private translateService: TranslateService, private rebateLevelService: RebateLevelService
  ) { }

  ngOnInit() {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    const allowMultiSelect = true;
    this.setSelectionModel();
    if (this.IsPPGGuest || this.IsZD || this.IsDistAdmin || this.IsDistUser) {
      this.IsReadOnly = true;
    }
  }

  async getOtherLines(): Promise<LineSegmentModel> {
    this.licLines = await this.lineSegmentService.GetByLineId(5, this.ctsId, this.year).toPromise();
    if (this.licLines[0].selected) {
      this.licSelected = true;
    }
    var response = await (await this.lineSegmentService.getCustomerValueLineLink(this.ctsId, this.year).toPromise());
    if (response != null) {
      this.selectValueSegmentId = response.brandId;
    }
    return null;
  }

  public loadDropDowns(): Promise<any[]> {
    this.spinner = true;
    this.lineSegmentService.GetByLineId(1, this.ctsId, this.year).subscribe(x => {
      if (x != null) {
        this.lines = x;
        x.filter(y => {
          if (y.selected) {
            this.selectedCt = y;
            return true;
          }
        });
      }
    }, error => {
      console.log(error);
      this.spinner = false;
    });
    return null;
  }

  async getPremiumLines(): Promise<LineSegmentModel[]> {
    this.premiumLines = await this.lineSegmentService.GetByLineId(3, this.ctsId, this.year).toPromise();
    return null;
  }

  async getValueLines(): Promise<LineSegmentModel[]> {
    this.valueLines = await this.lineSegmentService.GetByLineId(4, this.ctsId, this.year).toPromise();
    return null;
  }
  async getColorLines(): Promise<LineSegmentModel[]> {
    this.colorLines = await this.lineSegmentService.GetByLineId(2, this.ctsId, this.year).toPromise();
    return null;
  }

  async getCtLinesSelected(): Promise<LineSegmentModel[]> {
    this.ctLines.data = await (await this.lineSegmentService.GetByLineId(1, this.ctsId, this.year).toPromise()).filter(x => {
      if ((x.brandId === 8) || (x.brandId === 9)) {
        return x;
      }
    });
    this.applyFilter();
    return null;
  }

  async setSelectionModel() {
    this.spinner = true;
    try {
      await this.loadDropDowns();
      await this.getPremiumLines();
      await this.getValueLines();
      await this.getColorLines();
      this.premiumSelection = new SelectionModel<LineSegmentModel>(true, this.premiumLines.filter(x => {
        if (x.selected) {
          this.premiumLineSelected = Observable.of(true);
          if (x.brandId === 1) {
            this.ppgSelected = true;
          }
          if (x.brandId === 2) {
            this.nexaSelected = true;
          }
          return x;
        }
      }));
      await this.getCtLinesSelected();
      this.valueSelection = new SelectionModel<LineSegmentModel>(true, this.valueLines.filter(x => {
        if (x.selected && x.isUserSelected) {
          this.valueLineSelected = Observable.of(true);
          return x;
        }
      }));
      this.colorSelection = new SelectionModel<LineSegmentModel>(true, this.colorLines.filter(x => {
        if (x.selected && x.isUserSelected) {
          this.colorValueLineSelected = Observable.of(true);
          return x;
        }
      }));
      this.ctSelection = new SelectionModel<LineSegmentModel>(true, this.ctLines.data.filter(x => {
        if (x.selected) {
          this.ctLineSelected = Observable.of(true);
          return x;
        }
      }));
      this.getOtherLines();
    } catch (e) {
      console.log(e);
    } finally {
      this.spinner = false;
    }
  }

  public onLicClick(checkbox: MatCheckbox) {
    if (checkbox.checked) {
      this.licSelected = true;
    } else {
      this.licSelected = false;
    }
  }

  public async onClick($event) {
    this.spinner = true;
    let customerLines: CustomerLineSegmentModel[] = new Array();
    this.premiumSelection.selected.forEach(x => {
      let customerLine = new CustomerLineSegmentModel();
      customerLine.id = x.id;
      customerLine.brandId = x.brandId;
      customerLine.segmentId = x.segmentId;
      customerLine.ctsId = this.ctsId;
      customerLine.selected = true;
      customerLine.year = this.year;
      customerLines.push(customerLine);
    });
    this.valueSelection.selected.forEach(x => {
      let customerLine = new CustomerLineSegmentModel();
      customerLine.id = x.id;
      customerLine.brandId = x.brandId;
      customerLine.segmentId = x.segmentId;
      customerLine.ctsId = this.ctsId;
      customerLine.selected = true;
      customerLine.year = this.year;
      customerLines.push(customerLine);
    });
    this.colorSelection.selected.forEach(x => {
      let customerLine = new CustomerLineSegmentModel();
      customerLine.id = x.id;
      customerLine.brandId = x.brandId;
      customerLine.segmentId = x.segmentId;
      customerLine.ctsId = this.ctsId;
      customerLine.selected = true;
      customerLine.year = this.year;
      customerLines.push(customerLine);
    });
    if (this.licSelected) {
      let customerLine = new CustomerLineSegmentModel();
      customerLine.id = 13;
      customerLine.ctsId = this.ctsId;
      customerLine.brandId = 7;
      customerLine.segmentId = 5;
      customerLine.selected = true;
      customerLine.year = this.year;
      customerLines.push(customerLine);
    }
    this.ctSelection.selected.forEach(x => {
      let customerLine = new CustomerLineSegmentModel();
      customerLine.id = x.id;
      customerLine.brandId = x.brandId;
      customerLine.segmentId = x.segmentId;
      customerLine.ctsId = this.ctsId;
      customerLine.selected = true;
      customerLine.year = this.year;
      customerLines.push(customerLine);
    });
    if (this.selectedValueLink !== null && this.selectedValueLink !== undefined) {
      this.selectedValueLink.year = this.year;
      this.lineSegmentService.saveValueLineLink(this.selectedValueLink).subscribe(result => result.valueOf);
    }
    let success = (await this.lineSegmentService.saveLineSegment(customerLines).toPromise()).valueOf();
    let obj = new RebateLevelModel();
    customerLines.forEach(async custLine => {
      obj.ctsId = this.ctsId;
      obj.year = this.year;
      obj.brandId = custLine.brandId;
      obj.segmentId = custLine.segmentId;
      obj.saveLinesFlag = true;
      (await this.rebateLevelService.saveRebateLevel(obj).toPromise()).valueOf();
    });
    if (success) {
      NotificationService.success(null, this.translateService.instant("Save Complete"));
      this.spinner = false;
      await new Promise(f => setTimeout(f, 2000));
    } else {
      this.spinner = false;
    }
  }

  public isLineSelected(line: LineSegmentModel[]): boolean {
    line.forEach(element => {
      if (element.selected) {
        return true;
      }
    });
    return false;
  }

  public applyFilter() {
    var ctLine: LineSegmentModel;
    if (!this.ppgSelected && !this.nexaSelected) {
      this.ctLines.filter = 'xx';
      this.ctSelection.clear();
      return;
    }
    if (this.ppgSelected && this.nexaSelected) {
      this.ctLines.filter = '';
      return;
    }
    if (this.ppgSelected) {
      this.ctLines.filter = 'delfleet';
      this.ctSelection.deselect(this.ctLines.data[1]);
      return;
    }
    if (this.nexaSelected) {
      this.ctLines.filter = 'turbo';
      this.ctSelection.deselect(this.ctLines.data[0]);
      this.ctSelection.deselect()
      return;
    }
  }

  public premiumLineSelect(premiumLine: LineSegmentModel) {
    this.premiumSelection.toggle(premiumLine);
    this.ppgSelected = false;
    this.nexaSelected = false;
    this.premiumSelection.selected.forEach(x => {
      if (x.brandId === 1) {
        this.ppgSelected = true;
      }
      if (x.brandId === 2) {
        this.nexaSelected = true;
      }
    });
    this.applyFilter();
  }

  public onCtChange(obj) {
    // todo: getting the index of the item from the table in car premium, need to look into that
    // todo: change this to a real lookup instead of hardcoding
    if (obj === 1) {
      this.selectedCt = new LineSegmentModel();
      this.selectedCt.id = 1;
      this.selectedCt.brandId = 1;
      this.selectedCt.segmentId = 1;
      this.selectedCt.selected = true;
    }
    if (obj === 2) {
      this.selectedCt = new LineSegmentModel();
      this.selectedCt.id = 11;
      this.selectedCt.brandId = 2;
      this.selectedCt.segmentId = 1;
      this.selectedCt.selected = true;
    }
    if (obj === 3) {
      this.selectedCt = new LineSegmentModel();
      this.selectedCt.id = 12;
      this.selectedCt.brandId = 3;
      this.selectedCt.segmentId = 1;
      this.selectedCt.selected = true;
    }
  }

  public onValueLineLinkChange(obj) {
    if (obj !== 0) {
      this.selectedValueLink = new CustomerValueLinkModel();
      this.selectedValueLink.ctsId = this.ctsId;
      this.selectedValueLink.brandId = obj;
    }
  }

  public displayCt(): boolean {
    this.premiumSelection.selected.forEach(x => {
      if (x.brandId === 1 || x.brandId === 2) {
        return true;
      }
    });
    return false;
  }
}