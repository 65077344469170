import { Component, OnInit, ViewChild } from '@angular/core';
import { MarketingService } from './../../services/marketing.service';
import { MarketingModel } from '../../models/marketing.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from './../../../../core/services/notification-service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerModel } from './../../../customer/models/customer-model';
import { CustomerService } from './../../../customer/services/customer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketing-customer',
  templateUrl: './marketing-customer.component.html',
  styleUrls: ['./marketing-customer.component.scss']
})

export class MarketingCustomerComponent implements OnInit {
  public title: string = "Marketing Actions and Promotions";
  public displayedColumns = ["typeOfAgreement", "annualValue", "details"];
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public marketingOptions: MarketingModel[];
  public dataSource = new MatTableDataSource<MarketingModel>();
  public marketingOption = new MarketingModel();
  public spinner = false;
  public addMarketingOptions = false;
  public addRow: MarketingModel[];
  public year: number = 0;
  public ctsId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private marketingService: MarketingService, public customerService: CustomerService, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.addRow = [new MarketingModel()];
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId > 0) {
      this.GetCustomerById();
    }
    else {
      NotificationService.error(this.translateService.instant("Error"), this.translateService.instant("Select a CTS Id to continue!"));
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName =
              (this.customer.ctsId == null ? '' : this.customer.ctsId + ' - ') +
              (this.customer.companyName == null ? '' : this.customer.companyName);
            this.GetMarketingOptions();
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }


  public async GetMarketingOptions() {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.addMarketingOptions = false;
      this.marketingOption.year = this.year;
      this.marketingOption.ctsId = this.ctsId;
      (await this.marketingService.GetMarketingOptions(this.marketingOption)).subscribe(response => {
        this.marketingOptions = response;

        this.dataSource.data = this.marketingOptions;
        this.GetMarketingOptions();

        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }

  }
  public SearchGrid(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}