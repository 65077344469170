import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DevPlanService } from '../../services/dev-plan.service';
import { DevPlanModel } from '../../models/dev-plan-request.model';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '../../../../core/services/notification-service';
import { GlobalVariablesService } from '../../../../core/services/global-variables.service';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ShopDataModel } from '../../models/shop-data.model';
import { CustomerModel } from './../../../customer/models/customer-model';
import { CustomerService } from './../../../customer/services/customer.service';
import { CustomerDevPlanModel } from '../../models/customer-dev-plan-request.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-devplan-user',
  templateUrl: './devplan-user.component.html',
  styleUrls: ['./devplan-user.component.scss']
})

export class DevPlanUserComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() IsReadOnly: Boolean = false;

  public title: string = "Development Plan Maintenance";
  public ctsId: number = 0;
  public customer: CustomerModel = new CustomerModel();
  public distributorName: string;
  public dataSource = new MatTableDataSource<CustomerDevPlanModel>();
  public displayedColumns = ["shopId", "description", "comments", "actions"];
  public addColumns = ["shopCtsId", "devPlanId", "comments", "actions"]
  public addRow: CustomerDevPlanModel[];
  public spinner = false;
  public devplans: DevPlanModel[];
  public custdevplans: DevPlanModel[];
  public year: number;
  public AddOrDeleteFlag = false;
  public shopData: ShopDataModel[];
  public shopSearch: ShopDataModel[];
  public shopCtsId = new FormControl('', [Validators.required]);
  public devPlanId = new FormControl('', [Validators.required]);
  public comments = new FormControl('', [Validators.required]);

  constructor(private devPlanService: DevPlanService, private customerService: CustomerService, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    if (this.ctsId != 0) {
      this.GetCustomerById();
      this.GetShopAndDevPlansData();
      this.GetDevPlansForAllShops();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public GetCustomerById() {
    this.spinner = true;
    try {
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetCustomerByCTSId(customer)
        .subscribe(response => {
          if (response.success) {
            this.customer = response.result;
            this.distributorName =
              (this.customer.ctsId == null ? '' : this.customer.ctsId + ' - ') +
              (this.customer.companyName == null ? '' : this.customer.companyName);

          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }

  public AddOrDeleteDevPlans() {
    if (this.ctsId > 0) {
      this.addRow = [new CustomerDevPlanModel()];
      this.AddOrDeleteFlag = !this.AddOrDeleteFlag;
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public GetShopAndDevPlansData() {
    this.spinner = true;
    try {
      let shoprequest = new ShopDataModel();
      shoprequest.ctsId = this.ctsId;

      let request = new DevPlanModel();
      if (this.year > 0) {
        request.year = this.year;
        this.customerService.GetShopByCTSId(shoprequest).subscribe(shopData => {
          if (shopData.success) {
            this.shopData = shopData.result;
            this.shopSearch = this.shopData;
            this.devPlanService.GetPlansByYear(request).subscribe(devplanData => {
              if (devplanData.success) {
                this.devplans = devplanData.result;
              }
            })
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }
      else {
        NotificationService.warning(null, this.translateService.instant("Choose a year to add/edit a Development Plan!!"));
      }
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }
  public getErrorMessage(element: string) {
    return element + ' is not selected!';
  }
  public GetDevPlansForAllShops(): void {
    this.spinner = true;
    try {
      let planrequest = new CustomerDevPlanModel();
      planrequest.ctsId = this.ctsId;
      if (this.year > 0) {
        planrequest.year = this.year;
        this.devPlanService.GetCustomerPlans(planrequest).subscribe(planresponse => {
          if (planresponse.success) {
            this.dataSource.data = planresponse.result;
          } else {
            NotificationService.error(null, this.translateService.instant("Internal Error"));
          }
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      }
      else {
        NotificationService.warning(null, this.translateService.instant("Choose a year to add/edit a Development Plan!!"));
      }
    } catch (e) {
      this.spinner = false;
      NotificationService.error(null, this.translateService.instant("Internal Error"));
    }
  }
  public CreateUpdate(custplans: CustomerDevPlanModel) {
    if (!this.IsReadOnly) {
      this.spinner = true;
      custplans.year = this.year;
      custplans.ctsId = this.ctsId;
      if (custplans.shopCtsId > 0 && custplans.devPlanId > 0) {
        this.devPlanService.CreateUpdateShopPlans(custplans).subscribe(response => {
          if (!response.success) {
            NotificationService.error(null, response.message);
            this.spinner = false;
          }
          this.GetDevPlansForAllShops();
          NotificationService.success(null, this.translateService.instant("Save Complete"));
          this.spinner = false;
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
        if (this.AddOrDeleteFlag) {
          this.AddOrDeleteFlag = !this.AddOrDeleteFlag;
        }
      }
      else {
        NotificationService.error("Error", this.translateService.instant("Select a Location and Plan to add!!"));
        this.spinner = false;
      }
    }
  }

  public Delete(custplans: CustomerDevPlanModel) {
    if (!this.IsReadOnly) {
      this.spinner = true;
      this.devPlanService.DeleteCustomerPlans(custplans).subscribe(response => {
        if (response.success) {
          NotificationService.success(null, "Deleted");
          this.GetDevPlansForAllShops();
        } else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  FilterShop(value) {
    this.shopSearch = this.shopData;
    this.shopSearch = this.Search(value);
  }

  Search(value: string) {
    let filter = value.toLowerCase();
    return this.shopSearch.filter(option => option.strCompanyName.toLowerCase().includes(filter) || option.shopId.toString().includes(filter));
  }

  public SearchGrid(event: Event) {
    const searchkey = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchkey.trim().toLowerCase();
  }
}