import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';

import { ExcelSalesModel } from './../../models/excel-sales.model';
import { ExcelVolumeModel } from './../../models/excel-volume.model';

import { UploadService } from './../../../../core/services/upload.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.css']
})

export class ExcelUploadComponent implements OnInit {
  @ViewChild('salesPaginator', { read: MatPaginator }) excelSalesPaginator: MatPaginator;
  @ViewChild('volumePaginator', { read: MatPaginator }) excelVolumePaginator: MatPaginator;

  public spinner = false;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  public languageCultureCode: string = GlobalVariablesService.getLanguageCultureCode();

  public fileUploadForm: FormGroup;
  public fileInputLabel: string;

  public excelSales: ExcelSalesModel[];
  public excelVolume: ExcelVolumeModel[];

  public excelSalesFilter = new MatTableDataSource<ExcelSalesModel>();
  public excelVolumeFilter = new MatTableDataSource<ExcelVolumeModel>();

  public excelSalesColumns = ["year", "stRegion", "stName", "stNumber", "ctsId", "stBrand", "espbud", "cyYtd", "oem"];
  public excelVolumeColumns = ["year", "stRegion", "stName", "stNumber", "ctsId", "stBrand", "espbud", "cyYtd"];

  constructor(
    private http: HttpClient, public translateService: TranslateService,
    private formBuilder: FormBuilder,
    private uploadService: UploadService
  ) { }

  ngOnInit(): void {
    this.fileUploadForm = this.formBuilder.group({
      myfile: ['']
    });
    this.LoadExcelSalesData();
  }

  ngAfterViewInit() {
    this.excelSalesFilter.paginator = this.excelSalesPaginator;
    this.excelVolumeFilter.paginator = this.excelVolumePaginator;
  }

  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!_.includes(af, file.type)) {
        NotificationService.warning("", this.translateService.instant('Only EXCEL Docs Allowed!'));
      } else {
        this.fileInputLabel = file.name;
        this.fileUploadForm.get('myfile').setValue(file);
      }
    }
  }

  onFormSubmit() {
    if (GlobalVariablesService.getYear() > 0) {
      if (!this.fileUploadForm.get('myfile').value) {
        NotificationService.warning("", this.translateService.instant('Please fill valid details!'));
        return false;
      }
      this.spinner = true;
      this.uploadService.SaveExcel(this.fileUploadForm.get('myfile').value, GlobalVariablesService.getYear().toString()).subscribe(response => {
        if (response.success) {
          NotificationService.success("", this.translateService.instant("Uploaded"));
          this.LoadExcelSalesData();
        }
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    } else {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
  }

  public ExcelSalesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.excelSalesFilter.filter = filterValue.trim().toLowerCase();
  }

  public ExcelVolumeFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.excelVolumeFilter.filter = filterValue.trim().toLowerCase();
  }

  public LoadExcelSalesData() {
    this.spinner = true;
    this.uploadService.GetExcelSales(GlobalVariablesService.getYear().toString()).subscribe(response => {
      if (response.success) {
        this.excelSales = response.result;
        this.excelSalesFilter.data = this.excelSales;
        this.LoadExcelVolumeData();
      } else {
        this.spinner = false;
      }
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }

  public LoadExcelVolumeData() {
    this.spinner = true;
    this.uploadService.GetExcelVolume(GlobalVariablesService.getYear().toString()).subscribe(response => {
      if (response.success) {
        this.excelVolume = response.result;
        this.excelVolumeFilter.data = this.excelVolume;
      }
      this.spinner = false;
    }, error => {
      console.log(error);
      NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
      this.spinner = false;
    });
  }
}