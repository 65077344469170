import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariablesService } from './../../../core/services/global-variables.service';
import { ServiceCallResult } from './../../../core/models/service-call-result';

import { RequirementModel } from '../models/requirement.model';
import { RequirementMetModel } from '../models/requirement-met.model';
import { RequirementHeaderModel } from './../models/requirement-header.model';

@Injectable({
  providedIn: 'root'
})
export class RequirementService {

  constructor(private http: HttpClient) { }

  public GetRequirementByYear(requirement: RequirementModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/GetRequirementByYear", requirement).pipe(map(response => response));
  }

  public GetRequirementsMet(requirement: RequirementMetModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/GetRequirementsMet", requirement).pipe(map(response => response));
  }

  public GetRequirementHeader(requirementHeader: RequirementHeaderModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/GetRequirementHeader", requirementHeader).pipe(map(response => response));
  }

  public CreateUpdate(requirements: RequirementModel[]): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/CreateUpdate", requirements).pipe(map(response => response));
  }

  public CreateUpdateMet(requirements: RequirementMetModel[]): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/CreateUpdateMet", requirements).pipe(map(response => response));
  }

  public CreateUpdateHeader(requirementHeader: RequirementHeaderModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/CreateUpdateHeader", requirementHeader).pipe(map(response => response));
  }

  public Delete(requirement: RequirementModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Requirement/Delete", requirement).pipe(map(response => response));
  }
}