<h1 class="customer"><u>{{customer?.companyName}}</u></h1>
<hr class="lineSpacing">
<br />
<div class="row address">
  <div class="col">
    <label>{{this.customer?.addressLine1}}</label><br>
    <label>{{this.customer?.addressLine2}}</label><br>
    <label>{{this.customer?.city}}</label><br>
    <label>{{this.customer?.state}} - {{this.customer?.postalCode}}</label><br>
  </div>
  <div class="col">
    <label>{{"Platinum Administrator:"|translate}}&nbsp;</label>
    <label>{{customer?.distributorAdmin}}</label><br>
    <label>{{"Regional Manager:"|translate}}&nbsp;</label>
    <label *ngIf="customer.zoneDirector != null">{{customer?.zoneDirector}}</label>
    <label *ngIf="customer.zoneDirector == null">{{ "No Primary Assigned" | translate }}</label><br>
    <label>{{"Territory Manager:"|translate}}&nbsp;</label>
    <label *ngIf="customer.territoryManager != null">{{customer?.territoryManager}}</label>
    <label *ngIf="customer.territoryManager == null">{{ "No Primary Assigned" | translate }}</label>
  </div>
</div>
<hr class="lineSpacing">
<div class="row">
  <div class="col">
    <button class="btn btnHome" type="submit" routerLink="/locations">{{"Locations"|translate}}</button>
  </div>
  <div class="col">
    <button class="btn btnHome" type="submit" routerLink="/users-list">{{"Users"|translate}}</button>
  </div>
  <div class="col">
    <button class="btn btnHome" type="submit" routerLink="/contracts">{{"Contracts"|translate}}</button>
  </div>
</div>
<app-spinner *ngIf="spinner"></app-spinner>