export class ContractsModel {
    public ctsId:number;
    public userLanguageId:number;
    public customerContractDataID: number;
    public status: string;
    public type: string;
    public contractType:number;
    public contractSubType:number;
    public subType: string;
    public brand: string;
    public autorenewal: boolean;
    public platinumUploadFlag:number;
    public startDate: string;
    public endDate: string;
    public fileID:number;
    public fileName:string;
    public fileContent:Uint8Array;
}
