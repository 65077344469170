<app-spinner *ngIf="spinner"></app-spinner>

<div class="container">
  <br>
  <h3 class="center">{{title|translate}}</h3>
  <div class="row">
    <button mat-raised-button (click)="onClauseClick()" [disabled]="IsLocked()">{{"Add Clause"|translate}}</button>
  </div>
  <br> <br>
  <div class="row">

    <div cdkDropList #clausesList="cdkDropList" [cdkDropListData]="groups" (cdkDropListDropped)="dropGroup($event)">
      <div cdkDropListGroup class="example-container" *ngFor="let group of groups"
        (cdkDragDropped)="dragDropped($event)" cdkDrag [cdkDragData]="group">
        <div>
          <input [value]="group.name" class="clause-header" [(ngModel)]="group.name" matInputtype="text"
            [disabled]="IsLocked()" (change)="onHeaderChangeEvent($event)" required>
          <button mat-raised-button (click)="onClick($event, group)" value="{{group.id}}" [disabled]="IsLocked()">{{"Add
            Detail"|translate}}</button><input type="hidden" value="{{group.id}}" [disabled]="IsLocked()">
        </div>
        <br>
        <div cdkDropList class="example-list" id={{group.id}} [cdkDropListData]="group.details"
          (cdkDropListDropped)="dropItem($event)" [cdkDropListConnectedTo]="getConnectedList()">
          <div class="example-box" *ngFor="let item of group.details" (cdkDragDropped)="dragDropped($event)" cdkDrag
            [cdkDragData]="item">
            <textarea matInput (change)="onChangeEvent($event)" [disabled]="IsLocked()">{{item.description}}</textarea>
            <input type="hidden" value="{{item.id}}" [disabled]="IsLocked()">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button mat-button  class="btnPPG1" (click)="Lock()" *ngIf="ShowLockButton()"
      [disabled]="(IsLocked() || !(year > 0))">{{"Lock"|translate}}</button>
  </div>
</div>