import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceCallResult } from '../../../core/models/service-call-result';

import { GlobalVariablesService } from '../../../core/services/global-variables.service';
import { ErrorService } from '../../error/services/error.service';

import { CustomerModel } from '../models/customer-model';
import { UserModel } from './../../users/models/user-model';
import { ShopDataModel } from './../../rebate/models/shop-data.model';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  public GetCustomersByUserType(user: UserModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetCustomersByUserType", user).pipe(map(response => response));
  }

  public GetCustomerByCTSId(customer: CustomerModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetCustomerByCTSId", customer).pipe(map(response => response));
  }
  public GetShopByCTSId(shop: ShopDataModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetShopByCTSId", shop).pipe(map(response => response));
  }

  public GetDistributorPaintShops(data: CustomerModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetDistributorPaintShops", data).pipe(map(response => response));
  }

  public GetLocationsByCTSId(data: CustomerModel): Observable<ServiceCallResult> {
    return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetLocationsByCTSId", data).pipe(map(response => response));
  }

  // public GetCustomersByCountry(): Observable<CustomerModel[]> {
  //   return this.http.get<ServiceCallResult>(`${GlobalVariablesService.getEnvironment().apiEndpoint}Customer/GetCustomersByCountry/country/75`)
  //     .pipe(
  //       map(response => response.result)
  //     );
  // }

  // public GetCustomerDetail(request: string): Observable<CustomerModel> {
  //   return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + "Customer/GetCustomerDetail/", request, {
  //     headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  //   }).pipe(map(response => response.result));
  // }

  // public GetCustomersAssignedByUserId(request: number): Observable<CustomerModel[]> {
  //   return this.http.post<ServiceCallResult>(GlobalVariablesService.getEnvironment().apiEndpoint + 'Customer/GetCustomersAssignedByUserId/' + request, request)
  //     .pipe(
  //       map(response => response.result));
  // }
}
