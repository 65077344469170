import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RebateService } from '../../services/rebate.service';
import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { CustomerRebateModel } from '../../models/customer-rebate.model';
import { RebateModel } from '../../models/rebate.model';
import { TranslateService } from '@ngx-translate/core';
import { GoalMessageService } from '../../services/goal-message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-rebate',
  templateUrl: './customer-rebate.component.html',
  styleUrls: ['./customer-rebate.component.scss']
})

export class CustomerRebateComponent implements OnInit, OnDestroy {
  @Input() IsReadOnly: Boolean = false;
  @Input() rebateTypeId: number;

  public spinner = false;
  public title: string;
  public year: number;
  public ctsId: number;
  public minConsecutionAbp:number=0;
  public percentage:number=0;
  public languageCultureCode:string=GlobalVariablesService.getLanguageCultureCode();
  public customerBaseValue: number = 0;

  public rebate = new RebateModel();
  public customerRebate = new CustomerRebateModel();
  messages: any[] = [];
  subscription: Subscription;

  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();

  constructor(private route: ActivatedRoute, private rebateService: RebateService, private translateService: TranslateService, private goalMessageService: GoalMessageService) {

    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();

    this.customerRebate.ctsId = this.ctsId;
    this.customerRebate.year = this.year;
    if (this.year > 0) {
      this.rebateService.GetCustomerBaseValue(this.customerRebate).subscribe(response => {
        if (response.success) {
          this.customerBaseValue = response.result;
        }
        this.loadData();
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
    else {
      NotificationService.warning(null, this.translateService.instant("Please pick a year"));
    }
    this.subscription = this.goalMessageService.getMessage().subscribe(message => {
      if (message) {
        console.log(message);
        this.rebateService.GetCustomerBaseValue(this.customerRebate).subscribe(response => {
          if (response.success) {
            this.customerBaseValue = response.result;
            console.log(response.result);
          }
          this.loadData();
        }, error => {
          console.log(error);
          NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
          this.spinner = false;
        });
      } else {
        console.log(message);
      }
    });

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }

  private loadData() {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.rebate.rebateTypeId = this.rebateTypeId;
      this.rebate.year = this.year;
      this.rebateService.GetRebateByTypeId(this.rebate).subscribe(responseRebate => {
        if (responseRebate.success) {
          this.rebate = responseRebate.result;
          this.customerRebate.rebateTypeId = this.rebateTypeId;
          this.customerRebate.year = this.year;
          this.customerRebate.ctsId = this.ctsId;
          this.rebateService.GetCustomerRebate(this.customerRebate).subscribe(responseCustomerRebate => {
            if (responseCustomerRebate.success) {
              if (responseCustomerRebate.result != null) {
                this.customerRebate = responseCustomerRebate.result;
              }           
              if(this.rebate == null)
              {
              this.minConsecutionAbp =0;
              this.percentage=0;
              }
              else
              {
                this.minConsecutionAbp=this.rebate.minConsecutionAbp;
                this.percentage=this.rebate.percentage;
              }
              if (this.rebate !=null && this.rebate.rebateTypeId == 3) {
                if (this.customerBaseValue *  this.minConsecutionAbp > 0)
                {
                this.customerRebate.baseValue = (this.customerBaseValue * this.minConsecutionAbp)/100 ;
                }
                else
                {
                  this.customerRebate.baseValue=0;
                }
              }
              else {
                this.customerRebate.baseValue = this.customerBaseValue;
              }
            }
            this.spinner = false;
          }, error => {
            console.log(error);
            NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
            this.spinner = false;
          });
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public CreateUpdate() {
    if (!this.isPlatinumProgress) {
      this.spinner = true;
      this.rebateService.CreateUpdateCustomerRebate(this.customerRebate).subscribe(responseCustomerRebate => {
        if (responseCustomerRebate.success) {
          this.customerRebate = responseCustomerRebate.result;
          this.customerRebate.baseValue = this.customerBaseValue;
          NotificationService.success(null, this.translateService.instant("Saved"));
        }
        this.spinner = false;
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public HideQualitativeActions() {
    if (this.rebate.rebateTypeId == 2 || this.rebate.rebateTypeId == 3) {
      return true;
    }
    return false;
  }

  public HideExclusiveDistributor() {
    if (this.rebate.rebateTypeId == 1 || this.rebate.rebateTypeId == 3) {
      return true;
    }
    return false;
  }

  public HideBaseValue() {
    // JP - showing the base value for Platinum Plus rebate per BUG 168332
    // if (this.rebate.rebateTypeId == 3) {
    //   return true;
    // }
    return false;
  }
}