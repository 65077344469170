<app-spinner *ngIf="spinner"></app-spinner>
<br>
<h4 class="center">{{"Locations"|translate}}</h4>
<br>
<table mat-table [dataSource]="locations" class="data_grid mat-elevation-z8">
    <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>{{"Customer Name"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            {{item.companyName}}
        </td>
    </ng-container>
    <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{"Address"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            <label>{{item.address + " " + item.city + " " + item.state + " " + item.zip}}</label>
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{"Customer Type"|translate}}</th>
        <td mat-cell *matCellDef="let item">
            <label>{{item.type | translate}}</label>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="locationsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: locationsColumns;"></tr>
</table>
