<app-spinner *ngIf="spinner"></app-spinner>
<mat-dialog-content>
  <button class="dialog-close" mat-button (click)="close()">
    <span class="material-icons">close</span>
  </button>
  <h4 class="modal-title">{{"Upload File"|translate}}</h4>
  <hr>
  <br />
  <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()">
    <div class="row">
      <div class="col-sm-6 text-center">
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile" name="myfile" (change)="onFileSelected($event)"
            #UploadFileInput>
          <label class="custom-file-label" style="width: 275px; text-align:left" for="customFile">{{fileName ||
            this.translateService.instant('No file uploaded yet.')}}</label>
        </div>
      </div>
      <br />
    </div>
    <div style="padding-top: 60px; margin-left:100px">
      <button class="btn btn-primary" type="submit"> {{"Upload"|translate}}</button>
    </div>
    <br />
    <div class="alert alert-light" style="color: green; font-weight: bold; text-align: center;">{{ msg }}</div>
  </form>