import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { TargetAccountModel } from './../../models/target-account.model';
import { CustomerModel } from './../../../customer/models/customer-model';

import { GlobalVariablesService } from './../../../../core/services/global-variables.service';
import { NotificationService } from './../../../../core/services/notification-service';
import { TargetAccountService } from './../../services/target-account.service';
import { CustomerService } from './../../../customer/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { NumberFormatService } from './../../../../core/services/number-format-service';

@Component({
  selector: 'app-target-account',
  templateUrl: './target-account.component.html',
  styleUrls: ['./target-account.component.scss']
})
export class TargetAccountComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() IsReadOnly: Boolean = false;

  public spinner = false;
  public title: string = "Target Account";

  public year: number = 0;
  public ctsId: number = null;

  public showAddCancel = false;

  public customers: CustomerModel[] = [];
  public customersFilter: CustomerModel[] = [];
  public targetAccounts: TargetAccountModel[];
  public targetAccountFilter = new MatTableDataSource<TargetAccountModel>();
  public addrow: TargetAccountModel[];

  public targetCtsId = new FormControl('', [Validators.required]);
  public shopGrossSales = new FormControl('', [Validators.required]);
  public potentialPurchases = new FormControl('', [Validators.required]);
  public targetDate = new FormControl('', [Validators.required]);
  public planDetails = new FormControl('', [Validators.required]);

  public addColumns = ['col_1', 'col_2'];
  public targetAccountsColumns: string[] = ['targetCtsId', 'shopGrossSales', 'potentialPurchases', 'targetDate', 'competitor', 'numberPainters', 'planDetails', 'actions'];

  public isPlatinumProgress = GlobalVariablesService.IsPlatinumProgress();

  constructor(private targetAccount_service: TargetAccountService, private customerService: CustomerService, private route: ActivatedRoute, private translateService: TranslateService) {
    this.ctsId = GlobalVariablesService.getCtsId();
    this.year = GlobalVariablesService.getYear();
    this.LoadTargetAccounts();
  }

  ngOnInit(): void {
    this.addrow = [new TargetAccountModel()];
  }

  ngAfterViewInit() {
    this.targetAccountFilter.paginator = this.paginator;
  }

  public async LoadTargetAccounts() {
    if (this.ctsId > 0) {
      this.spinner = true;
      this.showAddCancel = false;
      let customer = new CustomerModel();
      customer.ctsId = this.ctsId;
      this.customerService.GetDistributorPaintShops(customer).subscribe(async response => {
        if (response.success) {
          this.customers = response.result;
          this.customersFilter = this.customers;
        } else {
          NotificationService.error(null, this.translateService.instant("Error Loading Customers List"));
        }
        let targetAccount = new TargetAccountModel();
        targetAccount.year = this.year;
        targetAccount.ctsId = this.ctsId;
        this.targetAccounts = await (await this.targetAccount_service.GetAllTargetAccounts(targetAccount)).toPromise();
        this.targetAccountFilter.data = this.targetAccounts;
        this.spinner = false;
      });
    }
    else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public Filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.targetAccountFilter.filter = filterValue.trim().toLowerCase();
  }

  public ShowAddCancel() {
    if (this.ctsId > 0) {
      this.addrow = [new TargetAccountModel()];
      this.showAddCancel = !this.showAddCancel;
    } else {
      NotificationService.warning(null, this.translateService.instant("Please select a CTSID"));
    }
  }

  public getErrorMessage(element: string) {
    return this.translateService.instant('Missing') + ' ' + this.translateService.instant(element);
  }

  public CreateUpdate(targetAccount: TargetAccountModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      targetAccount.year = this.year;
      targetAccount.ctsId = this.ctsId;
      targetAccount.shopGrossSales = parseInt(NumberFormatService.removeSeparators(targetAccount.shopGrossSalesFormatted));
      targetAccount.potentialPurchases = parseInt(NumberFormatService.removeSeparators(targetAccount.potentialPurchasesFormatted));
      this.targetAccount_service.CreateUpdate(targetAccount).subscribe(response => {
        if (!response.success) {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
        NotificationService.success(null, this.translateService.instant("Saved"));
        this.LoadTargetAccounts();
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public Delete(targetAccount: TargetAccountModel) {
    if (!this.isPlatinumProgress && !this.IsReadOnly) {
      this.spinner = true;
      this.targetAccount_service.Delete(targetAccount).subscribe(response => {
        if (response.success) {
          NotificationService.success(null, this.translateService.instant("Deleted"));
          this.LoadTargetAccounts();
        } else {
          NotificationService.error(null, response.message);
          this.spinner = false;
        }
      }, error => {
        console.log(error);
        NotificationService.error(null, this.translateService.instant("An Error Ocurred"));
        this.spinner = false;
      });
    }
  }

  public changeShopSelected(item: TargetAccountModel) {
    this.getNumberPainters(item);
    this.getCompetitorBrand(item);
  }

  public getNumberPainters(item: TargetAccountModel) {
    if (this.customers.filter(x => x.ctsId == item.targetCtsId) != null && this.customers.filter(x => x.ctsId == item.targetCtsId).length > 0) {
      return item.numberPainters = this.customers.filter(x => x.ctsId == item.targetCtsId)[0].intNumTechnicians;
    }
    return 0;
  }

  public getCompetitorBrand(item: TargetAccountModel) {
    if (this.customers.filter(x => x.ctsId == item.targetCtsId) != null && this.customers.filter(x => x.ctsId == item.targetCtsId).length > 0) {

      item.competitor = this.customers.filter(x => x.ctsId == item.targetCtsId)[0].competitorBrand;
    }
    if (item.competitor == null) {
      item.competitor = 'N/A';
    }
    return item.competitor;
  }

  FilterShop(value) {
    this.customersFilter = this.customers;
    this.customersFilter = this.Search(value);
  }

  Search(value: string) {
    let filter = value.toLowerCase();
    return this.customersFilter.filter(option => option.companyName.toLowerCase().includes(filter) || option.ctsId.toString().includes(filter));
  }
}